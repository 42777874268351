import { Roles } from 'app/utils/auth';

export const requiredRoles = [
  { url: '/upBidding', systemRole: Roles.Trader },
  { url: '/profitAndLoss', systemRole: Roles.Trader },
  { url: '/monthlyCheck', systemRole: Roles.Trader },
  { url: '/uvamVDT/', systemRole: Roles.Trader },
  { url: '/uvamVDT/handle', systemRole: Roles.Trader },
  { url: '/uvamVDT/dailyplan', systemRole: Roles.Trader },
  { url: '/uvamBidding', systemRole: Roles.Trader },
  { url: '/baseline', systemRole: Roles.Trader },
  { url: '/bindingProgram', systemRole: Roles.Trader },
  { url: '/bde', systemRole: Roles.Trader },
  { url: '/varComunication', systemRole: Roles.Trader },
  { url: '/ternaAuctions', systemRole: Roles.Trader },
];

export const getHiddenItems = (userSystemRoles, userCurrentRoles) => {
  if (!requiredRoles || !userSystemRoles || !userCurrentRoles) {
    return [];
  }
  const hiddenItems = requiredRoles.reduce((hidden, requirement) => {
    const { url, systemRole, currentRole } = requirement;
    if (systemRole && !userSystemRoles.includes(systemRole)) {
      return hidden.concat(url);
    }
    if (currentRole && !userCurrentRoles.includes(currentRole)) {
      return hidden.concat(url);
    }
    return hidden;
  }, []);
  return hiddenItems;
};
