import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getUrl } from 'app/utils/navigation';
import PropTypes from 'prop-types';
import Switch from 'app/common/Switch';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, CardHeader, CardBody, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { Button, DataTable, IconButton } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getEditVdt, getRUP, getsendVDT } from 'ducks/actions/trading';
import { toast } from 'react-toastify';
import Picker from '../tradingCommon/Picker';
import { optionHour, optionVDT } from './constant';
import { customApi } from '../../../../api';
import { getErrorMessages } from '../../../utils/validation';
import DynamicForm from '../../../common/DynamicForm';

const StyledButtonLeft = styled(Button)`
  && {
    margin-bottom: 5px;
  }
`;
const SwitchContainer = styled.div`
  && {
    float: right;
  }
`;

const StyledButtonNew = styled(Button)`
  && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;
const PickerContainer = styled.div`
  && {
    margin-top: 5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
  && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const ColoredDiv = styled.div`
  color: ${(props) => props.color};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Dailyplan = ({
  startLoader,
  stopLoader,
  selectedPdo,
  selectedOdm,
  type,
  id,
  rupdata,
  getRUP,
  history,
  norupdata,
  language,
  location,
}) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [isDeleted, setisDeleted] = useState(null);
  const [rupButton, setrupButton] = useState(false);
  const [viewVDT, setViewVDT] = useState(false);
  const rupUrl = getUrl('/uvamVDT/compileRUP', type, id);
  const datiUrl = getUrl('/uvamVDT/datiRUP', type, id);
  const newVDTUrl = getUrl('/uvamVDT/NewVDT', type, id);
  /*
  useEffect(() => {
    if (norupdata) {
      history.push(datiUrl);
    }
  }, [norupdata]);
*/
  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };
  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const editVdtUrl = getUrl('/uvamVDT/editVdt', type, id);
  const viewVdtUrl = getUrl('/uvamVDT/viewVdt', type, id);

  const getRangeParams = () => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    return {
      pdoID: selectedPdo.pdoID,
      startDate: start,
      endDate: end,
      odmCompanyIdentifier: selectedOdm && selectedOdm.odmCompanyIdentifier,
    };
  };

  const getLogs = async () => {
    const params = getRangeParams();
    await customApi
      .post('/optz/getSendTernaVdtLogs', params)
      .then((res) => {
        /* normalizza in MOMENT */
        const tempLogs = [];
        res.data.forEach((val) => {
          val.startDate = moment(val.startDate).format('DD-MM-YYYY HH:mm');
          val.endDate = moment(val.endDate).format('DD-MM-YYYY HH:mm');
          val.sendDate = moment(val.sendDate).format('DD-MM-YYYY HH:mm');
          tempLogs.push(val);
        });
        console.log('getLogs ', tempLogs);
        setLogs(tempLogs);
      })
      .catch((err) => {
        const { errorMessage } = getErrorMessages(err);
        toast.error(errorMessage, { autoClose: 5000 });
      });
  };
  const hideDelete = () => {
    setisDeleted(null);
  };
  const handleDelete = (el) => {
    setisDeleted(true);
    setSendData(el);
  };
  const deleteVDT = async () => {
    startLoader();
    const params = {
      vdt: sendData,
      rupdata,
      action: 'ELIMINA',
      odmCompanyIdentifier: selectedOdm.odmCompanyIdentifier,
      id: sendData.callid,
    };
    try {
      const res = await customApi.post('/optz/sendTernaVdt', params);
      if (res.data) {
        stopLoader();
        setisDeleted(null);
        getLogs();
      }
    } catch (err) {
      toast.error(err, { autoClose: 5000 });
    }
  };

  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
    }
  }, [selectedPdo]);
  useEffect(() => {
    if (selectedPdo !== undefined) getLogs();
  }, [startDate, selectedPdo]);
  const handlevdt = (selectedPdo, logs, startDate, endDate) => {
    const toDisplay = [];
    if (logs && selectedPdo) {
      logs.forEach((f) => {
        const newf = { ...f };
        const string = optionVDT.filter((item) => item.value === f.reason);
        if (string !== undefined && string.length > 0) {
          newf.reason = string[0].label;
        }
        const now = moment();
        if (now.isAfter(newf.startDate) && now.isBefore(newf.endDate)) {
          newf.startDate = <Bold>{newf.startDate}</Bold>;
          newf.endDate = <Bold>{newf.endDate}</Bold>;
          newf.reason = <Bold>{newf.reason}</Bold>;
          newf.power = <Bold>{newf.power}</Bold>;
        } else if (now.isAfter(newf.endDate)) {
          newf.startDate = <ColoredDiv color="grey">{newf.startDate}</ColoredDiv>;
          newf.endDate = <ColoredDiv color="grey">{newf.endDate}</ColoredDiv>;
          newf.reason = <ColoredDiv color="grey">{newf.reason}</ColoredDiv>;
          newf.power = <ColoredDiv color="grey">{newf.power}</ColoredDiv>;
        }
        if (newf.status === 'OK') {
          newf.status = <ColoredDiv color="green">OK</ColoredDiv>;
        } else if (newf.status === 'PNOK') {
          newf.status = <ColoredDiv color="red">KO (VDT errata)</ColoredDiv>;
        } else if (newf.status === 'NOK') {
          newf.status = <ColoredDiv color="red">KO</ColoredDiv>;
        } else {
          newf.status = <ColoredDiv color="red">{newf.status}</ColoredDiv>;
        }
        toDisplay.push(newf);
      });
    }
    setData(toDisplay);
  };
  useEffect(() => {
    handlevdt(selectedPdo, logs, startDate, endDate);
  }, [selectedPdo, logs, startDate, endDate]);
  const showRUP = () => {
    setrupButton(!rupButton);
  };
  const previousDay = () => {
    setStartDate(startDate.clone().subtract(1, 'days'));
    setEndDate(endDate.clone().subtract(1, 'days'));
  };
  const nextDay = () => {
    setStartDate(startDate.clone().add(1, 'days'));
    setEndDate(endDate.clone().add(1, 'days'));
  };
  // eslint-disable-next-line consistent-return
  const conRender = (rupButton) => {
    if (rupButton) {
      return (
        <div>
          <DataTable
            keyField="id"
            sortby="fascia"
            width="100%"
            headers={[
              {
                index: 1,
                title: <Trans>Fascia</Trans>,
                property: 'fascia',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 2,
                title: <Trans>P.min</Trans>,
                property: 'pmin',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 3,
                title: <Trans>P.max</Trans>,
                property: 'pmax',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 4,
                title: <Trans>Assetto</Trans>,
                property: 'assetto',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 5,
                title: <Trans>P.tel.min</Trans>,
                property: 'ptelmin',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 6,
                title: <Trans>P.tel.max</Trans>,
                property: 'ptelmax',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 7,
                title: <Trans>T.ram</Trans>,
                property: 'tram',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 8,
                title: <Trans>T.der</Trans>,
                property: 'tder',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 9,
                title: <Trans>T.avv</Trans>,
                property: 'tavv',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 10,
                title: <Trans>T.arr</Trans>,
                property: 'tarr',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 11,
                title: <Trans>T.ris</Trans>,
                property: 'tris',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 12,
                title: <Trans>Grad +</Trans>,
                property: 'gradp',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 13,
                title: <Trans>Grad -</Trans>,
                property: 'gradm',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 14,
                title: <Trans>BRS</Trans>,
                property: 'brs',
                dataType: 'string',
                canSearch: true,
              },
            ]}
            data={rupdata}
            noData={<Trans>Nessuna fascia inserita</Trans>}
          />
          <div>
            <Link to={{ pathname: rupUrl }}>
              <StyledButtonNew color="secondary">Modifica dati statici</StyledButtonNew>
            </Link>
          </div>
          <br />
        </div>
      );
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <Trans>Gestione VDT</Trans>
        </CardHeader>
        <CardBody>
          <div>
            <SwitchContainer>
              <Switch checked={rupButton} label="Dati Statici RUP" onChange={() => showRUP()} />
            </SwitchContainer>
            {conRender(rupButton)}
          </div>
          <div>
            <StyledButtonLeft color="primary" onClick={() => previousDay()}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </StyledButtonLeft>
            <PickerContainer>
              <Picker value={startDate} onChange={(value) => onChange('startDate', value)} />
            </PickerContainer>
            <PickerContainer>
              <Picker
                disabledDate={disabledEndDate}
                value={endDate}
                onChange={(value) => onChange('endDate', value)}
              />
            </PickerContainer>
            <StyledButtonRight color="primary" onClick={() => nextDay()}>
              <FontAwesomeIcon icon={faCaretRight} />
            </StyledButtonRight>
            <Link to={{ pathname: newVDTUrl, state: { time: startDate.format('DD-MM-YYYY') } }}>
              <StyledButtonNew color="primary">NUOVA VDT</StyledButtonNew>
            </Link>
          </div>
          <DataTable
            keyField="id"
            search
            sortby="startDate"
            width="100%"
            headers={[
              {
                index: 1,
                title: <Trans>Inizio</Trans>,
                property: 'startDate',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 2,
                title: <Trans>Fine</Trans>,
                property: 'endDate',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 3,
                title: <Trans>Motivazione</Trans>,
                property: 'reason',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 4,
                title: <Trans>Disponibilità Dichiarata</Trans>,
                property: 'power',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 5,
                title: <Trans>Data e ora invio</Trans>,
                property: 'sendDate',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 6,
                title: <Trans>Eseguito da</Trans>,
                property: 'agent',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 7,
                title: <Trans>Stato</Trans>,
                property: 'status',
                dataType: 'boolean',
                canSearch: true,
              },
            ]}
            data={data}
            noData={<Trans>Nessuna VDT inviata per la data odierna</Trans>}
            /* eslint-disable-next-line consistent-return */
            rowButtons={(element) => {
              if (element.status !== 'OK') {
                return (
                  <>
                    <Link
                      to={{
                        pathname: viewVdtUrl,
                        state: { selected: logs.filter((el) => el.id === element.id) },
                      }}
                    >
                      <IconButton icon="eye" color="#c00" onClick={() => console.log(logs)} />
                    </Link>
                    <Link
                      to={{
                        pathname: editVdtUrl,
                        state: { selected: logs.filter((el) => el.id === element.id) },
                      }}
                    >
                      <IconButton
                        icon="pencil-alt"
                        color="#c00"
                        onClick={() => console.log(logs)}
                      />
                    </Link>
                    <IconButton icon="trash" color="#c00" onClick={() => handleDelete(element)} />
                  </>
                );
              }
            }}
          />
        </CardBody>
        <Modal isOpen={isDeleted != null} toggle={hideDelete}>
          <ModalHeader toggle={hideDelete}>
            <Trans>Conferma cancellazione VDT</Trans>
          </ModalHeader>
          <ModalBody>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Trans>Procedere all'eliminazione della VDT selezionata?</Trans>
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => deleteVDT()}>
              <Trans>Elimina</Trans>
            </Button>
            <Button color="secondary" onClick={hideDelete}>
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    </div>
  );
};

Dailyplan.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  rupdata: PropTypes.object.isRequired,
  norupdata: PropTypes.bool.isRequired,
  getRUP: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata, vdtsend, norupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return {
    user,
    language,
    path,
    type,
    id,
    selectedPdo,
    selectedOdm,
    rupdata: rupdata && rupdata.filter((d) => !d.isDeleted),
    vdtsend,
    norupdata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: (code) => dispatch(getRUP(code)),
  getsendVDT: () => dispatch(getsendVDT()),
  getEditVdt: (el) => dispatch(getEditVdt(el)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dailyplan);
