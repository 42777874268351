/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import DynamicForm from '../../../common/DynamicForm';

const EditRUPZoneModal = ({ isOpen, toggle, rupdata, zoneId, onSubmit, pdo }) => {
  const previousZone = rupdata && zoneId && rupdata.find((data) => data.fascia === zoneId - 1);
  const currentZoneInd = rupdata && rupdata.findIndex((rdata) => rdata.fascia === zoneId);
  const currentZone = rupdata && rupdata.length > 0 && zoneId && rupdata[currentZoneInd];

  console.log('EditRUPZoneModal rupdata, zoneId,pdo', rupdata, zoneId, pdo);
  console.log('EditRUPZoneModal previousZone ', previousZone);
  console.log('EditRUPZoneModal currentZone ', currentZone);

  function handleSubmit(zoneData) {
    const newRupData = [...rupdata];
    if (currentZone) {
      // edit zone
      newRupData[currentZoneInd] = { ...currentZone, ...zoneData };
    } else {
      // new zone
      const maxZoneId =
        rupdata && rupdata.length > 0
          ? Math.max(...rupdata.filter((d) => d.fascia).map((d) => d.fascia))
          : 0;
      const newZoneData = { ...zoneData, fascia: maxZoneId + 1, isDeleted: false, pdo };
      newRupData.push(newZoneData);
    }
    onSubmit(newRupData);
    toggle();
    console.log('EditRUPZoneModal newRupData ', newRupData);
  }

  /*
  function handleSubmit(data) {
    console.log('handleSubmit ', data);    
    toggle();    
    return onSubmit({ ...data, id: currentZone && currentZone.id, fascia: zoneId });    
  }
*/
  const submitPmin = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    } else if (previousZone && value < previousZone.pmax) {
      return new Error('Potenza inferiore alla Potenza max della fascia precedente');
    } else if (value > values.pmax) {
      return new Error('Potenza superiore alla Potenza max');
    } else if (value > values.ptelmax) {
      return new Error('Potenza superiore alla Potenza Tel. max');
    }
    return true;
  };
  // eslint-disable-next-line consistent-return
  const submitPmax = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (value < values.pmax) {
      return new Error('Valore inferirore alla pmax');
    }
  };
  const submitAssetto = (value) => {
    if (value.length > 40) {
      return new Error('Numero caratteri superiore a 40');
    }
    return true;
  };
  const submitPtelmin = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (previousZone && value < previousZone.ptelmax) {
      return new Error('Potenza inferiore rispetto alla fascia precedente');
    }
    if (value > values.ptelmax) {
      return new Error('La potenza deve essere inferiore a P.tel.max.');
    }

    return true;
  };
  // eslint-disable-next-line consistent-return
  const submitPtelmax = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitTram = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitDera = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradp = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradm = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitBRS = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere non negativo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  const submitTavv = (value, values) => {
    const lastassetto = previousZone ? previousZone.assetto : 0;

    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    if (parseFloat(value) < parseFloat(values.tram))
      return new Error('T avviamento minore di T Rampa');

    if (lastassetto === values.assetto) {
      if (value == 0) {
        return true;
      }
      return new Error('T avviamento deve essere nulla');
    }
    return true;
  };
  const submitTarr = (value, values) => {
    const lastassetto = previousZone ? previousZone.assetto : 0;
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    if (lastassetto === values.assetto) {
      if (value == 0) {
        return true;
      }
      return new Error('T arresto deve essere nulla');
    }
    return true;
  };
  const submitTris = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    return true;
  };

  const fields = [
    {
      name: 'pmin',
      type: 'text',
      label: <Trans>P.min</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitPmin,
      },
      md: 3,
    },
    {
      name: 'pmax',
      type: 'text',
      label: <Trans>P.max</Trans>,
      validation: {
        required: true,
        number: true,
        minStrictVal: 0,
        func: submitPmax,
      },
      md: 3,
    },
    {
      name: 'assetto',
      type: 'text',
      label: <Trans>Assetto</Trans>,
      validation: {
        required: true,
        func: submitAssetto,
      },
      md: 3,
    },
    {
      name: 'ptelmin',
      type: 'text',
      label: <Trans>P.tel.min.</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitPtelmin,
      },
      md: 3,
    },
    {
      name: 'ptelmax',
      type: 'text',
      label: <Trans>P.tel.max</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitPtelmax,
      },
      md: 3,
    },
    {
      name: 'tram',
      type: 'text',
      label: <Trans>T.ram</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitTram,
      },
      md: 3,
    },
    {
      name: 'tder',
      type: 'text',
      label: <Trans>T.der</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitDera,
      },
      md: 3,
    },
    {
      name: 'tavv',
      type: 'text',
      label: <Trans>T.avv</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitTavv,
      },
      md: 3,
    },
    {
      name: 'tarr',
      type: 'text',
      label: <Trans>T.arr</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitTarr,
      },
      md: 3,
    },
    {
      name: 'tris',
      type: 'text',
      label: <Trans>T.ris</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitTris,
      },
      md: 3,
    },
    {
      name: 'gradp',
      type: 'text',
      label: <Trans>Grad +</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitGradp,
      },
      md: 3,
    },
    {
      name: 'gradm',
      type: 'text',
      label: <Trans>Grad -</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitGradm,
      },
      md: 3,
    },
    {
      name: 'brs',
      type: 'text',
      label: <Trans>BRS</Trans>,
      validation: {
        required: true,
        number: true,
        func: submitBRS,
      },
      md: 3,
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {zoneId ? <Trans>Modifica fascia</Trans> : <Trans>Inserisci nuova fascia</Trans>}
      </ModalHeader>
      <ModalBody>
        <DynamicForm
          card
          title={''}
          initialValues={currentZone}
          fields={fields}
          onSubmit={handleSubmit}
          onCancel={toggle}
        />{' '}
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

EditRUPZoneModal.propTypes = {
  isOpen: PropTypes.bool,
  rupdata: PropTypes.bool,
  zoneId: PropTypes.number,
  toggle: PropTypes.func.isRequired,
};

EditRUPZoneModal.defaultProps = {
  isOpen: false,
  rupdata: [],
  zoneId: 0,
};
export default EditRUPZoneModal;
