import store from 'ducks/store';
import axios from 'axios';
import qs from 'qs';
import { t } from '@lingui/macro';

export const Roles = {
  Trader: 'Trader',
  TechOffice: 'TechOffice',
};

const domainRanks = {
};

export const setUserCurrentRoles = (auth) => {
  const roles = [];
  Object.entries(domainRanks).forEach(([role, rank]) => {
    if (domainRanks[auth.role] >= rank) {
      roles.push(role);
    }
  });
  return roles;
};

export const checkPassword = async (password) => {
  try {
    const { keycloak, user } = store.getState().auth;
    const { username } = user;
    const { authServerUrl, realm, clientId: client_id } = keycloak;
    const body = qs.stringify({ grant_type: 'password', client_id, username, password });
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const res = await axios.post(
      `${authServerUrl}/realms/${realm}/protocol/openid-connect/token`,
      body,
      config,
    );
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return false;
    }
    throw error;
  }
};
