import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { getUrl, getUrlFromId } from 'app/utils/navigation';
import AssetSelectorSection from './AssetSelectorSection';
import UserRole from '../UserRole';
import Selector from './Selector';


const ODMS = 'odms';
const PDOS = 'pdos';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
`;


const AssetsBar = (props) => {
  const { odms, pdos, type, id, selectedOdm, selectedPdo, path } = props;

  const [lastSelectedPdoItem, setLastSelectedPdoItem] = useState(null);

  const getODMUrl = (item) => {
    if (type && type === ODMS) {
      return getUrlFromId(ODMS, item.odmID);
    }

    return getUrl('/imbalancePrices', ODMS, item.odmID);
  };

  const getPDOUrl = (item) => {
    let pdoUrl = null;

    if (type && type === PDOS && (lastSelectedPdoItem === null || lastSelectedPdoItem.isUvam !== item.isUvam)) {
      pdoUrl = getUrlFromId(PDOS, item.pdoID);
    }

    pdoUrl = getUrl(item.isUvam ? '/uvamRegistry' : '/upRegistry', PDOS, item.pdoID);

    setLastSelectedPdoItem(item);
    return pdoUrl;
  };

  const pdoSections = [];
  if (pdos && pdos.length > 0) {
    const groupedPdos = pdos.reduce((accumulator, currentValue) => {
      accumulator[currentValue.isUvam ? 'UVAM' : 'UP'] = [...accumulator[currentValue.isUvam ? 'UVAM' : 'UP'] || [], currentValue];
      return accumulator;
    }, {});
    if (groupedPdos && groupedPdos.UP) pdoSections.push(<AssetSelectorSection key="upPdos" header={<Trans>UP e UC</Trans>} idProp='pdoID' nameProp='denomination' items={groupedPdos.UP} getUrl={item => getPDOUrl(item)} noItemsText={<Trans>Nessun punto di offerta</Trans>} />);
    if (groupedPdos && groupedPdos.UVAM) pdoSections.push(<AssetSelectorSection key="uvamPdos" header={<Trans>UVAM</Trans>} idProp='pdoID' nameProp='denomination' items={groupedPdos.UVAM} getUrl={item => getPDOUrl(item)} noItemsText={<Trans>Nessun punto di offerta</Trans>} />);
  }

  return (
    <Wrapper>
      <Bar>
        <Selector
          label={<Trans>Operatori di mercato</Trans>}
          selectedItem={selectedOdm}
          selectedItemLink={selectedOdm ? getUrl('/imbalancePrices', ODMS, selectedOdm.odmID) : null}
          placeholder={selectedOdm ? selectedOdm.odmName : <Trans>Operatori di mercato</Trans>}
          showDropdown={odms.length > 1}
          active={type === ODMS && selectedOdm && id === selectedOdm.odmID}
          nameProp="odmName"
        >
          <AssetSelectorSection header={<Trans>Operatori di mercato</Trans>} items={odms} idProp='odmID' nameProp='odmName' getUrl={item => getODMUrl(item)} noItemsText={<Trans>Nessun operatore di mercato</Trans>} />
        </Selector>
        <Selector
          label={<Trans>Punti di offerta</Trans>}
          selectedItem={selectedPdo}
          selectedItemLink={selectedPdo ? getUrl(selectedPdo.isUvam ? '/uvamRegistry' : '/upRegistry', PDOS, selectedPdo.pdoID) : null}
          placeholder={selectedPdo ? selectedPdo.denomination : <Trans>Non selezionato</Trans>}
          path={path}
          // showDropdown={pdos.length > 1}
          showDropdown={true}
          active={type === PDOS && selectedPdo && id === selectedPdo.pdoID}
          nameProp="denomination"
        >
          {pdoSections}
        </Selector>
      </Bar>

      <UserRole />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const {
    odms, pdos,
    selectedOdm,
    path, type, id,
  } = state.navigation;
  let { selectedPdo } = state.navigation;
  if (pdos.length === 1) {
    // [selectedPdo] = pdos;
  }

  return {
    numberOfOdms: odms.length,
    numberOfPdos: pdos.length,
    selectedOdm,
    selectedPdo,
    path,
    type,
    id,
    odms,
    pdos,
  };
};

export default connect(mapStateToProps)(AssetsBar);
