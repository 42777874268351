/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import { Card, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { DataTable, Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { getErrorMessages } from 'app/utils/validation';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import Picker from '../tradingCommon/Picker';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const BackButton = styled(Button)`
 && {
   width: 150px;
  }
`;


const Logs = (props) => {
  const { startLoader, stopLoader, selectedPdo, language, user, type, id, location } = props;

  let { logDate } = location;
  if (!logDate) logDate = moment().format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(moment(logDate, 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(logDate, 'YYYY-MM-DD'));
  const [data, setData] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 59 }).format('YYYY-MM-DD HH:mm');
      const params = {
        pdoID: selectedPdo.pdoID,
        startDate: start,
        endDate: end,
      };

      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        await customApi.post('/optz/getSendTernaLogs', params).then((res) => {
          if (res.data) {
            setData(res.data);
          } else {
            const { errorMessage } = getErrorMessages(res);
            toast.error(errorMessage, { autoClose: 5000 });
          }
        }).catch((err) => {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const statusMessage = (code, msg) => {
    switch (code) {
      case true:
        return i18n._(t`OK`);
      default:
        return msg;
    }
  };

  const cancel = () => {
    const { history } = props;
    history.goBack();
  };

  return (
    <CardStyled>
      <div>
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
      </div>
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <DataTable
          keyField="id"
          width="100%"
          headers={[
            {
              index: 1,
              title: <Trans>Giorno di dispacciamento</Trans>,
              property: 'date',
              dataType: 'string',
              canSearch: true,
              cell: (row) => {
                return  moment(row.date).format('DD/MM/YYYY');
              },
            },
            {
              index: 2, title: <Trans>Versione baseline</Trans>, property: 'baselineVersion', dataType: 'string', canSearch: true,
            },
            {
              index: 3,
              title: <Trans>Data e orario di invio</Trans>,
              property: 'sendDate',
              dataType: 'string',
              canSearch: true,
              cell: (row) => {
                return moment(row.sendDate).format('DD/MM/YYYY HH:mm:ss');
              },
            },
            { index: 4, title: <Trans>Eseguito da</Trans>, property: 'agent', dataType: 'string', canSearch: true },
            { index: 5, title: <Trans>Esito</Trans>, property: 'statusMsg', dataType: 'string', canSearch: true },
          ]}
          data={data}
          noData={<Trans>Nessun log disponibile</Trans>}
        />
      </div>
      <BackButton color="link" onClick={cancel}>
        {<Trans>Torna alla baseline</Trans>}
      </BackButton>
    </CardStyled>
  );
};

Logs.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // from redux
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  return { user, language, path, type, id, selectedPdo };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
