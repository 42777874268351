import React, { useMemo } from 'react';
import styled from 'styled-components';
import TreeNode from './TreeNode';
// import { getNewKey } from './utils';
import TreeContext from './TreeContext';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const TreeView = ({ structure, leaves, options, showAssets, toggle, filter, nLeaves, checkedNodes, openNodes, open, selectedAsset }) => {
  options = options || {};
  const keyProperty = options.keyProperty || 'name';

  const getCheckedChildrenCount = (node) => {
    if (!node.children) {
      return node.checked ? 1 : 0;
    }

    return node.children.reduce((sum, child) => sum + getCheckedChildrenCount(child), 0);
  };

  const nodes = useMemo(() => {
    return structure && Array.isArray(structure) ? structure.map(x => ({ ...x, key: x[keyProperty] })) : [{ ...structure, key: structure[keyProperty] }];
  }, [structure]);


  const contextValue = { options, filter, leaves, toggle, nLeaves, openNodes, openNode: open, checkedNodes, showAssets, selectedAsset };

  return (
    <TreeContext.Provider value={contextValue}>
      <Ul>
        {nodes.map(node => <TreeNode key={node.key} node={node} toggle={toggle} />)}
      </Ul>
    </TreeContext.Provider>
  );
};

export default TreeView;
