import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputAutosize from 'react-input-autosize';
import { isEqual } from 'lodash';
import IconButton from '../IconButton';
import { isObject } from 'app/utils/object';

const Wrapper = styled.div`
  && {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const Control = styled.div`
  && {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
  }
`;

const TextInput = styled(InputAutosize)`
  & input {
    border: none;
    outline: none;
  }

  div[style] {
    overflow: hidden !important;
  }
`;

const Placeholder = styled.span`
  color: ${props => props.theme.common.text.primaryColor};
`;

const TagShape = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;
  padding: 0.1rem 0.3rem 0.1rem 0.7rem;
  margin-right: 0.3rem;
  background-color: ${props => props.backgroundColor};
  border-radius: 15px;
  white-space: nowrap;
  ${props => props.color && ({ color: props.color })};
`;

TagShape.defaultProps = {
  backgroundColor: '#EBEEF0',
};

const Dropdown = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const DropdownItem = styled.button`
  && {
    border: none;
  }
`;

const Tag = (props) => {
  const { children, removeTag, backgroundColor, color, iconColor } = props;
  return (
    <TagShape backgroundColor={backgroundColor} color={color}>
      {children}
      <IconButton size="18px" color={ iconColor || '#c00' } icon="times" onClick={() => removeTag(children)} />
    </TagShape>
  );
};

Tag.propTypes = {
  children: PropTypes.string.isRequired,
  removeTag: PropTypes.func.isRequired,
};


class MultiSelectTagReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      text: '',
      value: props.value,
      mustSetDefaultValue: props.mustSetDefaultValue,
      disabled: props.disabled,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { defaultValue, mustSetDefaultValue } = this.props;
    if (mustSetDefaultValue) {
      this.setState({ dropdownOpen: false, value: (mustSetDefaultValue) ? defaultValue : [], text: '' });
    }
  }

  componentDidUpdate(prevProps) {
    // Check on options change
    if (this.props.emailTextTags === true && !isEqual(prevProps.options, this.props.options) ) {
      this.clearAll(prevProps.mustSetDefaultValue);
    }
  }

  isEmail = (str) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test( String(str).toLowerCase() );
  }

  changeText = (e) => {
    const { emailTextTags, canChangeText } = this.props;
    if (!canChangeText) {
      return;
    }

    const currentTextWithoutLastChar = e.target.value.substring(0, e.target.value.length - 1);
    const isLastCharSpace = (e.target.value.slice(-1) === ' ');
    if (isLastCharSpace && this.isEmail(currentTextWithoutLastChar) && emailTextTags) {
      this.addTag({
        name: currentTextWithoutLastChar,
        _label: currentTextWithoutLastChar,
        email: currentTextWithoutLastChar,
      });
      this.setState({ dropdownOpen: false, text: '' });
    } else if (isLastCharSpace && !this.isEmail(currentTextWithoutLastChar) && emailTextTags) {
      this.setState({ dropdownOpen: false, text: '' });
    } else {
      this.setState({ text: e.target.value, dropdownOpen: true });
    }
  }

  onBlurText = (e) => {
    const { emailTextTags, canChangeText } = this.props;
    if (!canChangeText) {
      return;
    }

    // Add tag if the text is an email
    if (this.isEmail(e.target.value) && emailTextTags) {
      this.addTag({ name: e.target.value, _label: e.target.value, email: e.target.value });
    }

    this.setState({ dropdownOpen: false, text: '' });
  }

  isArrayOrObject(value) {
    return typeof value === 'object' || Array.isArray(value);
  }

  addTag = (tag) => {
    const { name, onChange } = this.props;
    const { value } = this.state;
    const newValue = (value && this.isArrayOrObject(value)) ? [...value, tag] : [tag];
    this.setState({ dropdownOpen: false, value: newValue, text: '' });
    onChange({ target: { name, value: newValue } });
  }

  createTag = (tag) => {
    const { onCreate } = this.props;
    this.setState({ dropdownOpen: false, text: '' });
    onCreate(tag);
  }

  removeTag = (tag) => {
    const { name, onChange, labelProperty } = this.props;
    const { value } = this.state;
    const newValue = value.filter(x => (labelProperty ? x[labelProperty] : x) !== tag);
    this.setState({ dropdownOpen: false, value: newValue, text: '' });
    onChange({ target: { name, value: newValue } });
  }

  clearAll = (mustSetDefaultValue) => {
    // Remove all tags and text, add default value if needed
    const { name, onChange, defaultValue } = this.props;
    const newValue = (mustSetDefaultValue) ? defaultValue : [];
    this.setState({ dropdownOpen: false, value: newValue, text: '' });
    onChange({ target: { name: name, value: newValue } });
  }

  render() {
    const { options, showCreate, minCharToShowCreate, keyProperty, labelProperty, className, placeholder } = this.props;
    const { text, value, dropdownOpen, disabled } = this.state;
    // show create option in dropdown if showCreate is true and input text is not a given option
    const showCreateOption = showCreate && text.length >= minCharToShowCreate
      && !options.map(option => (labelProperty ? option[labelProperty].toLowerCase() : option.toLowerCase())).includes(text.toLowerCase());
    // show options that are not already in value and filter them by text
    const filteredOptions = options.filter((option) => {
      if (isObject(option) && labelProperty) {
        const label = option[labelProperty];
        if ( value && Array.isArray(value) ) {
          const values = value.map(x => x[labelProperty]);
          return !values.includes(label) && label.toLowerCase().includes(text.toLowerCase());
        } else {
          return [];
        }
      }
      return !value.includes(option) && option.toLowerCase().includes(text.toLowerCase());
    });
    const showDropdown = dropdownOpen && (filteredOptions.length > 0 || showCreateOption);

    const self = this;
    const tags = value && value.map((x, index) => {
      const label = (labelProperty) ? x[labelProperty] : '';
      const isLabelEmail = self.isEmail(label);
      const key = (keyProperty && !isLabelEmail) ? x[keyProperty] : 'tag_'+index;
      const isTagDifferentColor = !(isLabelEmail && self.props.emailTextTags);
      if (isTagDifferentColor) {
        return <Tag backgroundColor="#2381D1" color="#ffffff" iconColor="#ffffff" key={key} removeTag={this.removeTag}>{label}</Tag>;
      }
      return <Tag key={key} removeTag={this.removeTag}>{label}</Tag>;
    });

    return (
      <Wrapper className={className} tabIndex="0" onFocus={() => this.inputRef.current.focus()} onBlur={() => this.setState({ dropdownOpen: false })}>
        <Control className="form-control">
          {tags}
          <TextInput
            ref={this.inputRef}
            value={text}
            onChange={this.changeText}
            onBlur={this.onBlurText}
            onFocus={() => this.setState({ dropdownOpen: true })}
            disabled={disabled}
          />
          {placeholder && tags.length === 0 && <Placeholder>{placeholder}</Placeholder>}
        </Control>
        {showDropdown && (
          <Dropdown className="dropdown-menu show">
            {filteredOptions.map((option) => {
              const key = keyProperty ? option[keyProperty] : option;
              const label = labelProperty ? option[labelProperty] : option;
              return <DropdownItem type="button" className="dropdown-item" key={key} onMouseDown={() => this.addTag(option)}>{label}</DropdownItem>;
            })}

            {showCreateOption && filteredOptions.length > 0 && <div className="dropdown-divider" />}
            {showCreateOption && <DropdownItem className="dropdown-item" onMouseDown={() => this.createTag(text)}>{`${showCreate} ${text}`}</DropdownItem>}
          </Dropdown>
        )}
      </Wrapper>
    );
  }
}

MultiSelectTagReport.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array.isRequired,
  defaultValue: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  showCreate: PropTypes.bool,
  onCreate: PropTypes.func,
  minCharToShowCreate: PropTypes.number,
  canChangeText: PropTypes.bool,
  mustSetDefaultValue: PropTypes.bool,
};

MultiSelectTagReport.defaultProps = {
  name: null,
  showCreate: false,
  onCreate: () => {},
  options: [],
  minCharToShowCreate: 0,
  canChangeText: true,
  mustSetDefaultValue: false,
  defaultValue: []
};

export default MultiSelectTagReport;
