/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'app/utils/navigation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { formatNumber } from 'app/utils/commonUtils';
import { getErrorMessages } from 'app/utils/validation';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLogs = styled(Button)`
 && {
   padding:0px;
  }
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const StyledSendButton = styled(Button)`
 && {
    margin-left: 5px;
    padding:1px 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width: 100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    /* margin-top: 2px;*/
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const LastLog = styled.span`
  && {
    margin-left: 15px;
    margin-right: 15px;
    padding:0px;
  }
`;

const Baseline = ({ startLoader, stopLoader, selectedPdo, selectedOdm, language, user, type, id }) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);
  const [dateTerna, setDateTerna] = useState(null);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const hideSendTerna = () => {
    setDateTerna(null);
  };

  const getRangeParams = () => {
    const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
    const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');
    return {
      pdoID: selectedPdo.pdoID,
      startDate: start,
      endDate: end,
    };
  };

  const getLogs = async () => {
    const params = getRangeParams();
    await customApi.post('/optz/getSendTernaLogs', params).then((res) => {
      /* normalizza in MOMENT */
      const tempLogs = {};
      res.data.forEach((val) => {
        val.date = moment(val.date);
        val.sendDate = moment(val.sendDate);
        if (!(val.date.format('YYYY-MM-DD') in tempLogs)) tempLogs[val.date.format('YYYY-MM-DD')] = [];
        tempLogs[val.date.format('YYYY-MM-DD')].push(val);
      });
      setLogs(tempLogs);
    }).catch((err) => {
      const { errorMessage } = getErrorMessages(err);
      toast.error(errorMessage, { autoClose: 5000 });
    });
  };

  const sendTerna = async (date) => {
    startLoader();

    const params = {
      code: selectedPdo.code,
      pdoId: selectedPdo.pdoID,
      odmCompanyIdentifier: selectedOdm.odmCompanyIdentifier,
      date,
      user,
    };

    try {
      const res = await customApi.post('/optz/sendTerna', params);
      if (!res.data.result) throw res.data.description;
      toast.success(i18n._(t`Invio avvenuto con successo`), { autoClose: 5000 });
    } catch (err) {
      toast.error(err, { autoClose: 5000 });
    }

    await getLogs();
    hideSendTerna();

    stopLoader();
  };

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      const params = getRangeParams();
      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        await customApi.post('/optz/getBaseline', params).then((res) => {
          if (res.data) {
            setData(res.data.baseline.baseline_json);

            /* normalizza in MOMENT */
            const tempLogs = {};
            res.data.logs.forEach((val) => {
              val.date = moment(val.date);
              val.sendDate = moment(val.sendDate);
              if (!(val.date.format('YYYY-MM-DD') in tempLogs)) tempLogs[val.date.format('YYYY-MM-DD')] = [];
              tempLogs[val.date.format('YYYY-MM-DD')].push(val);
            });
            setLogs(tempLogs);
          } else {
            const { errorMessage } = getErrorMessages(res);
            toast.error(errorMessage, { autoClose: 5000 });
          }
        }).catch((err) => {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  return (
    <CardStyled>
      <div>
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
      </div>
      <Modal isOpen={dateTerna != null} toggle={hideSendTerna}>
        <ModalHeader toggle={hideSendTerna}><Trans>Conferma invio baseline</Trans></ModalHeader>
        <ModalBody>
          <Trans>Desideri confermare l'invio a Terna della baseline selezionata?</Trans><br /><br />
          UVAM: <strong>{selectedPdo && selectedPdo.code}</strong><br />
          <Trans>Giorno di dispacciamento</Trans>: <strong>{dateTerna && dateTerna.format('dddd DD/MM/YYYY')}</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => sendTerna(dateTerna.format('YYYY-MM-DD'))}><Trans>Invia</Trans></Button>
          <Button color="secondary" onClick={hideSendTerna}>Annulla</Button>
        </ModalFooter>
      </Modal>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const dateMt = moment(date, 'DD-MM-YYYY');
                const isToday = moment().isSame(dateMt, 'day');
                const isTomorrow = moment().add(1, 'day').isSame(dateMt, 'day');
                const dataCur = data[dateMt.format('YYYY-MM-DD')];

                /* compongo grid */
                let headerCompile = false;
                const header = [];
                const dataGrid = [];
                for (let i = 0; i < 96; i += 1) {
                  const rowData = [];
                  if (dataCur) {
                    rowData.push(dataCur.totalBaseline ? dataCur.totalBaseline[i] : null);
                    if (!headerCompile) header.push(i18n._(t`Baseline totale`));
                    dataCur.sites.forEach((site) => {
                      rowData.push(site.values[i]);
                      if (!headerCompile) {
                        header.push(<span>{site.siteName} <Bold>{site.registryCode}</Bold></span>);
                      }
                    });
                    headerCompile = true;
                  }
                  dataGrid.push(rowData);
                }

                /* l'inivio a terna è permesso solamente per le giornate di DOPODOMANI in poi o domani solamente se l'orario corrente è minore delle 16:00 */
                const difDays = dateMt.startOf('day').diff(moment().startOf('day'), 'days');
                const enableSendTerna = difDays >= 2 || (difDays === 1 && parseInt(moment().format('HHmmss'), 0) < 180000);

                /* ultimo log */
                const lastLog = logs[dateMt.format('YYYY-MM-DD')] ? logs[dateMt.format('YYYY-MM-DD')][0] : null;
                const sendLogUrl = getUrl('/baseline/logs', type, id);

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                        {lastLog && lastLog.status && <LastLog> <StyledButtonLogs tag={Link} color="link" to={{ pathname: sendLogUrl, logDate: dateMt.format('YYYY-MM-DD') }}>{i18n._(t`Ultimo invio`)}</StyledButtonLogs> {lastLog.sendDate.format('DD-MM-YYYY HH:mm:ss')} {lastLog.agent}</LastLog>}
                        {lastLog && !lastLog.status && <LastLog> <StyledButtonLogs tag={Link} color="link" to={{ pathname: sendLogUrl, logDate: dateMt.format('YYYY-MM-DD') }}>{i18n._(t`Errore invio baseline:`)}</StyledButtonLogs> {lastLog.sendDate.format('DD-MM-YYYY HH:mm:ss')} {lastLog.agent}</LastLog>}
                        {!lastLog && <LastLog>{i18n._(t`Baseline non ancora inviata`)}</LastLog>}
                        <StyledSendButton color="primary" onClick={() => setDateTerna(dateMt)} disabled={!enableSendTerna}>
                          {i18n._(t`Invia a terna`)}
                        </StyledSendButton>
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataGrid}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      normalizeValue={(x, y, value) => (value != null ? formatNumber(value, 3) : null)}
                      readyOnly={(col, row, value) => true} // passo le colonne read only
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        const className = ' read-only';
                        return className;
                      }}
                      timeRate="quart"
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
    </CardStyled>
  );
};

Baseline.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // from redux
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return { user, language, path, type, id, selectedPdo, selectedOdm };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Baseline);
