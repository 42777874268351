import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeContext from './TreeContext';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const Li = styled.li`
  margin-left: 1.2rem;

  ${props => !props.visible && 'display: none;'};
`;

const EmptyItem = styled.div`
  padding: .1rem;
  cursor: default;
`;

const ItemWithArrow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .1rem;
  ${props => props.checked && `color: ${props.theme.common.brand.primaryColor}`};
  cursor: pointer;

  ${props => props.isParent && 'margin: .2rem 0'};

  &:hover {
    .label {
      color: ${props => (props.disabled ? '#999' : props.theme.common.brand.secondaryColor)};
    }
    .checkbox {
      color: ${props => (props.disabled ? '#999' : props.theme.common.brand.secondaryColor)};
    }
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  min-width: 1.2rem;
  color: #999;
  transform: rotate(-90deg);
  transition: all .1s;

  ${props => props.open && 'transform: rotate(0)'};

  &:hover {
    color: #aaa;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  min-width: 1.2rem;
  margin-left: 1.5rem;
  cursor: default;
  color: #666;
`;

const CheckBox = styled(FontAwesomeIcon)`
  margin-left: .3rem;
  min-width: 1.2rem;
  color: ${props => (props.checked ? `${props.theme.common.brand.primaryColor}` : '#aaa')};

  ${props => props.disabled && 'color: #999'};
`;

const Label = styled.div`
  display: inline-block;
  margin-left: .5rem;

  ${props => props.disabled && 'color: #999'};
`;

const LoadingLabel = styled.span`
  margin-left: .5rem;
  cursor: default;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingItem = () => {
  return (
    <EmptyItem>
      <SpinnerIcon icon="spinner" spin />
      <LoadingLabel>Caricamento...</LoadingLabel>
    </EmptyItem>
  );
};


const TreeNode = ({ node, parent, onFilter, openParentNode }) => {
  const { options, filter, filtered, visibleNodes, selectedItems, disabledItems, updateVisible, toggleItem, compareFunction, render } = useContext(TreeContext);
  const { keyProperty } = options;

  const { key, name, onToggle } = node;

  const [open, setOpen] = useState(node.open || selectedItems.map(x => x.key).some(x => x !== undefined && x !== key && x.startsWith(key)));
  const [children, setChildren] = useState(node.children ? node.children.map(x => ({ ...x, key: node.key + '/' + x[keyProperty] })) : node.children);
  const [isLoading, setIsLoading] = useState(false);

  const disabled = disabledItems.includes(key);

  const expand = async () => {
    if (node.loadChildren && (!children || children.length === 0)) {
      setIsLoading(true);
      const children = await node.loadChildren(node);
      setChildren(children ? children.map(x => ({ ...x, key: node.key + '/' + x[keyProperty] })) : children);
      setIsLoading(false);
      if (visibleNodes[key]) {
        updateVisible(key, undefined);
      }
    }
  };

  useEffect(() => {
    if (open) {
      expand();
    }
  }, [open]);


  const isParent = children !== undefined || node.loadChildren !== undefined;
  const isParentNotLoaded = isParent && children === undefined;

  // visibility of node: if it's a leaf, check filter and, if visible, add the key to visibleNodes in TreeView
  // if it's a parent, set visibile to true if at least a child node (a node containing the parent key) is visible
  // If a parent is not loaded yet, treat it as a leaf
  let visible;
  if (!filter) {
    // se non esiste una funzione di filtro mostro sempre tutto
    visible = true;
  } else if (isLoading || isParentNotLoaded) {
    // se è un nodo con i figli non ancora caricati o li sta caricando mostra il nodo (e aggiorno visibleNodes)
    visible = true;
    if (visible !== visibleNodes[key]) {
      updateVisible(key, visible);
    }
  } else if (isParent) {
    // se è un parent lo mostro se ha almeno un discendente visibile -> vedo se c'è in visibleNodes almeno un elemento che inizia con key e il cui valore è true;
    visible = filter(node) || Object.entries(visibleNodes).some(([nodeKey, nodeVisibility]) => nodeKey.startsWith(key) && nodeKey !== key && nodeVisibility === true);
    if (visible !== visibleNodes[key]) {
      updateVisible(key, visible);
    }
  } else {
    // se è un nodo foglia chiamo la funzione di filtro
    visible = filter(node);
    if (visible !== visibleNodes[key]) {
      updateVisible(key, visible);
    }
  }

  // apre il nodo e chiama la funzione di apertura del nodo del parent
  const openNodeAndParent = () => {
    setOpen(true);
    if (openParentNode) {
      openParentNode();
    }
  };

  // Se inserisco un filtro (cioè esiste almeno un nodo foglia filtrato) apri il nodo e chiama la funzione di apertura per il nodo parent
  useEffect(() => {
    if (filtered) {
      openNodeAndParent();
    }
  }, [filtered]);


  useEffect(() => {
    if (onFilter) {
      onFilter(key, visible);
    }
  }, [visible]);


  const renderChildren = (children) => {
    if (isLoading) return <LoadingItem />;

    if (!Array.isArray(children)) {
      children = children ? [children] : [];
    }
    return (
      <Ul hidden={!open}>
        {children.map((child, index) => <TreeNode key={child.key || index} node={child} parent={node} openParentNode={openNodeAndParent} />)}
      </Ul>
    );
  };

  const checked = selectedItems.some(compareFunction(node));

  const toggle = () => {
    if (disabled) {
      return;
    }
    // function defined for single item
    if (onToggle) {
      onToggle(node, !checked, parent);
    }
    // function defined for the whole tree
    toggleItem(node, !checked, parent);
  };

  let checkBoxIcon = null;
  if (options.checked) {
    if (node.icon) {
      checkBoxIcon = node.icon;
    } else {
      checkBoxIcon = checked ? 'check-square' : ['far', 'square'];
    }
  }

  return (
    <Li visible={visible}>
      <ItemWithArrow isParent={isParent} checked={checked} disabled={disabled}>
        {isParent && <ArrowIcon open={open} icon="chevron-circle-down" onClick={() => setOpen(!open)} />}
        <Item>
          {checkBoxIcon && <CheckBox className="checkbox" disabled={disabled} icon={checkBoxIcon} checked={checked} onClick={toggle} />}
          <Label disabled={disabled}>{render ? render(node, checked) : <div className="label" onClick={toggle}>{name}</div>}</Label>
        </Item>
      </ItemWithArrow>
      {isParent && renderChildren(children)}
    </Li>
  );
};

export default TreeNode;
