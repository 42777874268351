import { api } from 'api';

export const LOAD_CATALOG_START = 'catalogs/LOAD_CATALOG_START';
export const LOAD_CATALOG_SUCCESS = 'catalogs/LOAD_CATALOG_SUCCESS';
export const LOAD_PROVINCE_CATALOG_SUCCESS = 'catalogs/LOAD_PROVINCE_CATALOG_SUCCESS';
export const LOAD_CATALOG_FAILURE = 'catalogs/LOAD_CATALOG_FAILURE';


export const loadCatalogStart = name => ({
  type: LOAD_CATALOG_START,
  payload: name,
});

export const loadCatalogSuccess = (name, items, language) => ({
  type: LOAD_CATALOG_SUCCESS,
  payload: { name, items, language },
});

export const loadCatalogFailure = error => ({
  type: LOAD_CATALOG_FAILURE,
  payload: error,
});

export const loadProvinceCatalogSuccess = (country, items) => ({
  type: LOAD_PROVINCE_CATALOG_SUCCESS,
  payload: { country, items },
});

export const loadCatalog = (name, filter) => async (dispatch, getState) => {
  const { language } = getState().preferences;
  try {
    dispatch(loadCatalogStart(name));
    // const res = await api.get(`/Catalogs/${name}`);
    // let items = res.data;
    // if (name === 'physicalQuantities') {
    //   items = items.map(x => ({ ...x, _label: x.alternativeNames ? `${x.name} (${x.alternativeNames.join(', ')})` : x.name })).sort((a, b) => (a._label.toLowerCase() < b._label.toLowerCase() ? -1 : 1));
    // }
    // dispatch(loadCatalogSuccess(name, items, props));
  } catch (err) {
    dispatch(loadCatalogFailure(err.message));
  }
};

const currentFilter = '{"where":{"current":true}}';

export const loadAllCatalogs = () => async (dispatch) => {
  // dispatch(loadCatalog('modbus/registerkinds'));
  // dispatch(loadCatalog('modbus/datakinds'));
  // dispatch(loadCatalog('physicalQuantities', ['units', 'exactoconversionfactor']));
  // dispatch(loadCatalog('units'));
  // dispatch(loadCatalog('valueTypes'));
  // dispatch(loadCatalog('aggregationRules'));
  // dispatch(loadCatalog('countries'));
};

export const loadProvinceCatalog = country => async (dispatch, getState) => {
  try {
    // let items = getState().catalogs.provinces[country];
    // if (!items) {
    //   dispatch(loadCatalogStart(`Provinces ${country}`));
    //   const res = await api.get(`/Catalogs/${country}/Provinces`);
    //   items = res.data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    //   dispatch(loadProvinceCatalogSuccess(country, items));
    // }
    // return items;
  } catch (err) {
    dispatch(loadCatalogFailure(err.message));
  }
};
