import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TimePicker from './TimePicker';
import { addMinutesToTimeString } from './common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTimePicker = styled(TimePicker)`
  flex-grow: 1;
`;

const Separator = styled.div`
  width: 1rem;
`;

const TimeRangePicker = (props) => {
  const { start, end, onChange, separator, gap, minTime, maxTime, invalid, className, ...rest } = props;

  const [maxTime1, setMaxTime1] = useState();
  const [minTime2, setMinTime2] = useState();


  useEffect(() => {
    const valuePlusGap = addMinutesToTimeString(start || minTime, gap, separator);
    setMinTime2(valuePlusGap);
  }, [start]);

  useEffect(() => {
    const valueMinusGap = addMinutesToTimeString(end || maxTime, -gap, separator);
    setMaxTime1(valueMinusGap);
  }, [end]);

  const setStartTime = (value) => {
    onChange({ start: value, end });
  };

  const setEndTime = (value) => {
    onChange({ start, end: value });
  };

  return (
    <Wrapper className={className}>
      <StyledTimePicker gap={gap} separator={separator} value={start} minTime={minTime} maxTime={maxTime1} onChange={setStartTime} invalid={invalid} {...rest} />
      <Separator />
      <StyledTimePicker gap={gap} separator={separator} value={end} minTime={minTime2} maxTime={maxTime} onChange={setEndTime} invalid={invalid} {...rest} />
    </Wrapper>
  );
};

TimeRangePicker.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  gap: PropTypes.number,
  invalid: PropTypes.bool,
  separator: PropTypes.string,
  className: PropTypes.string,
};

TimeRangePicker.defaultProps = {
  minTime: '00:00',
  maxTime: '24:00',
  gap: 1,
  invalid: false,
  separator: ':',
  className: undefined,
};

export default TimeRangePicker;
