import * as actions from '../actions/catalogs';
import { CHANGE_LANGUAGE } from '../actions/preferences';

const INITIAL_STATE = {
  'modbus/datakinds': [],
  provinces: {},
  towns: {},
};

const addTranslationsToCatalog = (name, items, language) => {
  if (!Array.isArray(items)) {
    return items;
  }

  let translatedItems;
  if (name === 'physicalQuantities') {
    translatedItems = items.map((x) => {
      let _label;
      if (x.translations && x.translations[language]) {
        const { name, alternativeNames } = x.translations[language];
        if (name && alternativeNames) {
          _label = `${name} (${alternativeNames.join(', ')})`;
        } else if (name) {
          _label = name;
        } else {
          _label = x.translations[language];
        }
      } else {
        _label = x.alternativeNames ? `${x.name} (${x.alternativeNames.join(', ')})` : x.name;
      }
      if (!_label) {
        _label = '';
      }
      return { ...x, _label };
    }).sort((a, b) => (a._label.toLowerCase() < b._label.toLowerCase() ? -1 : 1));
  } else {
    translatedItems = items.map(x => ({ ...x, _label: x.translations && x.translations[language] ? x.translations[language] : x.name }))
      .sort((a, b) => (a._label.toLowerCase() < b._label.toLowerCase() ? -1 : 1));
  }

  return translatedItems;
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOAD_CATALOG_SUCCESS: {
      const { name, items, language } = action.payload;
      return { ...state, [name]: addTranslationsToCatalog(name, items, language) };
    }
    case actions.LOAD_PROVINCE_CATALOG_SUCCESS: {
      const { country, items } = action.payload;
      return { ...state, provinces: { ...state.provinces, [country]: items } };
    }
    case CHANGE_LANGUAGE: {
      const language = action.payload;
      const translatedCatalogs = {};
      Object.entries(state).forEach(([name, items]) => { translatedCatalogs[name] = addTranslationsToCatalog(name, items, language); });
      return { ...state, ...translatedCatalogs };
    }
    default:
      return state;
  }
};
