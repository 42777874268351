import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'app/utils/navigation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Switch from 'app/common/Switch';
import { connect } from 'react-redux';
import { api, customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, CardHeader, CardBody } from 'reactstrap';
import { Button, DataTable } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { formatNumber } from 'app/utils/commonUtils';
import { getErrorMessages } from 'app/utils/validation';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getRUP } from 'ducks/actions/trading';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';
import { getActiveHourVDT } from '../tradingCommon/tradingUtils';
import { optionHour, optionVDT } from './constant';
import DynamicForm from '../../../common/DynamicForm';


const StyledButtonLogs = styled(Button)`
 && {
   padding:0px;
  }
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;
const SwitchContainer = styled.div`
 && {
   float: right;
  }
`;

const StyledSendButton = styled(Button)`
 && {
    margin-left: 5px;
    padding:1px 5px;
  }
`;
const StyledButtonNew = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;
const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width: 100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    /* margin-top: 2px;*/
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const LastLog = styled.span`
  && {
    margin-left: 15px;
    margin-right: 15px;
    padding:0px;
  }
`;

const TernaVDT = ({ startLoader, stopLoader, selectedPdo, selectedOdm, language, user, type, id, rupdata, history, norupdata, getRUP }) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [userData, setUserData] = useState({});
  const [sendData, setSendData] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);
  const [dateTerna, setDateTerna] = useState(null);
  const [rupButton, setrupButton] = useState(false);
  const [contractual, setContractual] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [contractualTime, setContractualTime] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [oldData, setOldData] = useState({});
  const rupUrl = getUrl('/uvamVDT/compileRUP', type, id);
  const datiUrl = getUrl('/uvamVDT/datiRUP', type, id);

  useEffect(() => {
    if (norupdata) {
      history.push(datiUrl);
    }
  }, [norupdata]);

  const loadTernaAuctions = async () => {
    // Filtro per pdo e flag active
    const res = await api.get(`/TernaAuctions?filter={"where": {"pdoId": ${selectedPdo.pdoID}, "active": true}}`);
    const ternaAuctions = res.data;
    let quantity = 0;
    let contractualTime = 0;
    let startIndex = 0;
    ternaAuctions.forEach((el) => {
      const startDate = moment(el.datePeriod.from, 'MM/YYYY');
      const endDate = moment(el.datePeriod.to, 'MM/YYYY');
      const endOfMonth = endDate.clone().endOf('month').format('YYYY-MM-DD hh:mm');
      const now = moment();

      if (now.isBetween(startDate, endOfMonth)) {
        quantity = (parseFloat(el.quantity));
        contractualTime = parseFloat(el.hoursRequired);
        const options = optionHour
        options.forEach((i, index) => {
          if (i.value == el.timePeriod.from) {
            startIndex = index - 1;
          }
        });
      }
    });
    setQuantity(quantity);
    setStartIndex(startIndex);
    setContractualTime(contractualTime);
    console.log('loadTernaAuctions ', quantity, startIndex, contractualTime);
  };

  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
      loadTernaAuctions();
    }
  }, [selectedPdo]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };
  const getRangeParams = (startDate,endDate) => {
    const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
    const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');
    return {
      pdoID: selectedPdo.pdoID,
      startDate: start,
      endDate: end,
    };
  };
  const getContractual = (startIndex,contractualTime,quantity) => {
    console.log('getContractual ', startIndex, contractualTime, quantity);
    const contractual = Array(96)
      .fill(0);
    contractual.forEach((value, index) => {
      if (index > startIndex && index <= startIndex + 4 * contractualTime) {
        contractual[index] = quantity;
      }
    });
    return contractual;
  };

  const init = async (selectedPdo, startDate, endDate,startIndex, contractualTime, quantity) => {
    console.log('init ', selectedPdo, startDate.toISOString(), endDate.toISOString(), startIndex, contractualTime, quantity);
    if (selectedPdo && startDate && endDate) {
      const params = getRangeParams(startDate, endDate);
      const contractualData = getContractual(startIndex, contractualTime, quantity);
      setContractual(contractualData);

      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        await customApi.post('/optz/getMarginUp', params).then((res) => {
          if (res.data) {
            setData(res.data.marginUP);
          } else {
            toast.error('Impossibile ottenere la disponibilità tecnica', { autoClose: 5000 });
          }
        }).catch((err) => {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        });

        stopLoader();
      }
    }
  };
  useEffect(() => {
    init(selectedPdo, startDate, endDate,startIndex, contractualTime, quantity);
  }, [selectedPdo, startDate, endDate, startIndex, contractualTime, quantity]);

  useEffect( () => {
    console.log('useEffect contractual ');
    const start = startDate.format('DD-MM-YYYY');
    const end = endDate.format('DD-MM-YYYY');
    setUserData(data => ({ ...data, [start]: [...contractual], [end]: [...contractual] }));
    setOldData(data => ({ ...data, [start]: [...contractual], [end]: [...contractual] }));
  }, [startDate, endDate, contractual]);

  const getRangeParamsv2 = () => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
    return {
      pdoID: selectedPdo.pdoID,
      startDate: start,
      endDate: end,
      odmCompanyIdentifier: selectedOdm && selectedOdm.odmCompanyIdentifier,
    };
  };
  const getLogs = async () => {
    const params = getRangeParamsv2();
    await customApi.post('/optz/getSendTernaVdtLogs', params).then((res) => {
      /* normalizza in MOMENT */
      const tempLogs = [];
      res.data.forEach((val) => {
        val.startDate = moment(val.startDate).format('YYYY-MM-DD HH:mm');
        val.endDate = moment(val.endDate).format('YYYY-MM-DD HH:mm');
        val.sendDate = moment(val.sendDate).format('YYYY-MM-DD HH:mm');
        tempLogs.push(val);
      });
      setLogs(tempLogs);
    }).catch(() => {
      toast.error('Errore generico nella richiesta dei Logs', { autoClose: 5000 });
    });
  };
  const hideSendTerna = () => {
    setDateTerna(null);
  };
  const showRUP = () => {
    setrupButton(!rupButton);
  };
  const handleSend = (date) => {
    const dateString = date.format('DD-MM-YYYY');
    const toSend = userData[dateString];
    let loopstart = 0;
    const listVdt = [];
    let idxStart = 0;
    let value = 0;
    let counter = 0;
    toSend.forEach((f, i) => {
      if (f !== loopstart) {
        if (counter > 0) {
          listVdt.push({ startDate: dateString + ' ' + optionHour[idxStart].value, endDate: dateString + ' ' + optionHour[i].value, power: value });
        }
        idxStart = i;
        value = f;
        loopstart = f;
        counter += 1;
      }
    });
    setDateTerna(date);
    setSendData(listVdt);
  };
  const handleapiSend = async (data) => {
    startLoader();
    const params = {
      vdt: data,
      rupdata,
      odmCompanyIdentifier: selectedOdm.odmCompanyIdentifier,
      action: 'INSERISCI',
    };
    try {
      const res = await customApi.post('/optz/sendTernaVdt', params);
      if (res.data) {
        stopLoader();
        setDateTerna(null);
        toast.success(i18n._(t`Invio avvenuto con successo`), { autoClose: 5000 });
        getLogs();
      }
    } catch (err) {
      toast.error('Errore nell\'invio della VDT', { autoClose: 5000 });
    }
  };

  const handleSubmit = (data) => {
    const date = new Date();
    const dateM = moment(date);
    const actualH = date.getHours();
    const actualM = date.getMinutes();
    const quarter = Math.floor(actualM / 15) + 3;
    const index = quarter + actualH * 4;
    const overwrite = [];
    sendData.forEach((f) => {
      f.agent = user.name;
      f.reason = data.reason;
      f.note = data.note;
      f.pdoId = selectedPdo.pdoID;
      f.userId = user.id;
      f.sendDate = moment()
        .toString();
    });
    sendData.forEach((el) => {
      const start = moment(el.startDate, 'DD-MM-YYYY HH:mm');
      const end = moment(el.endDate, 'DD-MM-YYYY HH:mm');
      if (dateM.isBefore(start, 'hour')) {
        overwrite.push(el);
      } else if (dateM.isBefore(end, 'hour')) {
        const hour = optionHour[index].label.substr(0,2);
        const minute = optionHour[index].label.substr(3,2);
        el.startDate = dateM.set({ hour: hour, minute: minute, second: '00' });
        overwrite.push(el);
      }
      el.startDate = moment(el.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss').toString();
      el.endDate = moment(el.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss').toString();
    });
    overwrite.forEach(f => handleapiSend(f));
  };

  const toQuarter = (data) => {
    const newArray = Array(96).fill(0);
    if (data) {
      newArray.forEach((value, index) => {
        newArray[index] = data[Math.floor(index / 4)];
      });
    }
    return newArray;
  };
  const fields = [
    {
      name: 'reason',
      type: 'select',
      label: <Trans>Motivazione</Trans>,
      validation: { required: true },
      md: 12,
      options: optionVDT,
    },
    {
      name: 'Note',
      type: 'text',
      label: <Trans>Note</Trans>,
      validation: { required: false },
      md: 12,
    }];
  // eslint-disable-next-line consistent-return
  const conRender = (rupButton) => {
    if (rupButton) {
      return (
        <div>
          <DataTable
            keyField="id"
            sortby="fascia"
            width="100%"
            headers={[
              { index: 1, title: <Trans>Fascia</Trans>, property: 'fascia', dataType: 'string', canSearch: true },
              { index: 2, title: <Trans>P.min</Trans>, property: 'pmin', dataType: 'string', canSearch: true },
              { index: 3, title: <Trans>P.max</Trans>, property: 'pmax', dataType: 'string', canSearch: true },
              { index: 4, title: <Trans>Assetto</Trans>, property: 'assetto', dataType: 'string', canSearch: true },
              { index: 5, title: <Trans>P.tel.min</Trans>, property: 'ptelmin', dataType: 'string', canSearch: true },
              { index: 6, title: <Trans>P.tel.max</Trans>, property: 'ptelmax', dataType: 'string', canSearch: true },
              { index: 7, title: <Trans>T.ram</Trans>, property: 'tram', dataType: 'string', canSearch: true },
              { index: 8, title: <Trans>T.der</Trans>, property: 'tder', dataType: 'string', canSearch: true },
              { index: 9, title: <Trans>T.avv</Trans>, property: 'tavv', dataType: 'string', canSearch: true },
              { index: 10, title: <Trans>T.arr</Trans>, property: 'tarr', dataType: 'string', canSearch: true },
              { index: 11, title: <Trans>T.ris</Trans>, property: 'tris', dataType: 'string', canSearch: true },
              { index: 12, title: <Trans>Grad +</Trans>, property: 'gradp', dataType: 'string', canSearch: true },
              { index: 13, title: <Trans>Grad -</Trans>, property: 'gradm', dataType: 'string', canSearch: true },
              { index: 14, title: <Trans>BRS</Trans>, property: 'brs', dataType: 'string', canSearch: true }]}
            data={rupdata || []}
            noData={<Trans>Nessuna fascia inserita</Trans>}
          />
          <div><Link to={{ pathname: rupUrl }}><StyledButtonNew color="secondary">Modifica dati statici</StyledButtonNew></Link></div>
          <br />
        </div>
      );
    }
  };

  useEffect(() => {
    if (selectedPdo && rupdata) getLogs();
  }, [startDate, selectedPdo]);

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }
  return (
    <div>
      <Card>
        <CardHeader>Piano giornaliero VDT</CardHeader>
        <CardBody>
          <div>
            <SwitchContainer><Switch checked={rupButton} label="Dati Statici RUP" onChange={() => showRUP()} /></SwitchContainer>
            { conRender(rupButton) }
          </div>
          <div>
            <StyledButtonLeft color="primary" onClick={() => previousDay()}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </StyledButtonLeft>
            <PickerContainer>
              <Picker
                value={startDate}
                onChange={value => onChange('startDate', value)}
              />
            </PickerContainer>
            <PickerContainer>
              <Picker
                disabledDate={disabledEndDate}
                value={endDate}
                onChange={value => onChange('endDate', value)}
              />
            </PickerContainer>
            <StyledButtonRight color="primary" onClick={() => nextDay()}>
              <FontAwesomeIcon icon={faCaretRight} />
            </StyledButtonRight>
          </div>
          <Modal isOpen={dateTerna != null} toggle={hideSendTerna}>
            <ModalHeader toggle={hideSendTerna}><Trans>Conferma invio piano giornaliero VDT</Trans></ModalHeader>
            <ModalBody>
              <Trans>Desideri confermare l'invio a Terna del piano giornaliero selezionato?</Trans><br /><br />
              UVAM: <strong>{selectedPdo && selectedPdo.code}</strong><br />
              <Trans>Giorno di dispacciamento</Trans>: <strong>{dateTerna && dateTerna.format('dddd DD/MM/YYYY')}</strong>
              <DynamicForm
                title={<Trans>Inserisci nuova fascia</Trans>}
                fields={fields}
                hideButtons={false}
                initialValues={{ reason: optionVDT[10].value }}
                submitButtonText="Invia"
                onSubmit={handleSubmit}
                onCancel={hideSendTerna}
              />
            </ModalBody>
          </Modal>
          <MainContainerStyled>
            <TableContainerDiv>
              {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const dateMt = moment(date, 'DD-MM-YYYY');
                const isToday = moment().isSame(dateMt, 'day');
                const isTomorrow = moment().add(1, 'day').isSame(dateMt, 'day');
                const activeHours = getActiveHourVDT(dateMt);
                const dataCur = data[dateMt.format('YYYY-MM-DD')];
                const enableSendTerna = !!(moment().isSame(date, 'day') || moment().isBefore(date, 'day'));
                /* ultimo log */
                const filteredLogs = logs.filter(f => date.isSame(moment(f.startDate), 'day'));
                const mostRecentDate = new Date(Math.max.apply(null, filteredLogs.map(e => new Date(e.sendDate))));
                const mostRecentObject = filteredLogs.filter((e) => {
                  const d = new Date(e.sendDate);
                  // eslint-disable-next-line eqeqeq
                  return d.getTime() == mostRecentDate.getTime();
                })[0];
                const lastLog = mostRecentObject && date.isSame(moment(mostRecentObject.startDate), 'day') ? mostRecentObject : null;
                /* compongo grid */
                const header = [];
                header.push(i18n._(t`Disponibilità tecnica`));
                header.push(i18n._(t`Disponibilità contrattuale`));
                header.push(i18n._(t`Disponibilità dichiarata`));
                const dataGrid = [];
                for (let i = 0; i < 96; i += 1) {
                  const rowData = [];
                  if (dataCur) {
                    rowData.push(toQuarter(dataCur.upMargin)[i])}
                  else rowData.push('n.a.');
                  if (date.day() <= 5) {
                    rowData.push(contractual[i]);
                  } else rowData.push(0);
                  if (!lastLog && userData[date.format('DD-MM-YYYY')] && userData !== undefined) {
                    rowData.push(userData[date.format('DD-MM-YYYY')][i]);
                  } else {
                    rowData.push(0);
                  }
                  dataGrid.push(rowData);
                }
                const handleUrl = getUrl('/uvamVDT/dailyplan', type, id);
                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                        {lastLog && lastLog.status === 'OK' && <LastLog> <StyledButtonLogs tag={Link} color="link" to={{ pathname: handleUrl, logDate: dateMt.format('YYYY-MM-DD') }}>{i18n._(t`Ultimo invio`)}</StyledButtonLogs> {lastLog.sendDate} {lastLog.agent}</LastLog>}
                        {lastLog && lastLog.status !== 'OK' && <LastLog> <StyledButtonLogs tag={Link} color="link" to={{ pathname: handleUrl, logDate: dateMt.format('YYYY-MM-DD') }}>{i18n._(t`Errore invio vdt:`)}</StyledButtonLogs> {lastLog.sendDate} {lastLog.agent}</LastLog>}
                        {!lastLog && <LastLog>{i18n._(t`VDT non ancora inviata`)}</LastLog>}
                        <StyledSendButton color="primary" onClick={() => handleSend(dateMt)} disabled={!enableSendTerna}>
                          {i18n._(t`Invia a terna`)}
                        </StyledSendButton>
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataGrid}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      saveCell={(cell, newValue) => {
                        if (cell.x === 2) {
                          let value = newValue ? parseFloat(newValue) : 0;
                          if (cell.x === 2 && value === '') value = undefined;
                          const newUserData = { ...userData };
                          const timeKey = date.format('DD-MM-YYYY');
                          if (!newUserData[timeKey]) newUserData[timeKey] = [];
                          if (!newUserData[timeKey][cell.y]) newUserData[timeKey][cell.y] = [];
                          newUserData[timeKey][cell.y] = value;
                          setUserData(newUserData);
                        }
                      }}
                      normalizeValue={(x, y, value) => (value != null ? formatNumber(value, 3) : null)}
                      readyOnly={(col, row) => {
                        if (col === 'C') {
                          const checkActiveHours = () => {
                            if (row !== 0) {
                              if (!activeHours[row - 1]) {
                                return true;
                              }
                              return false;
                            }
                          };

                          return (checkActiveHours());
                        }
                        return true;
                      }
                      } // passo le colonne read only
                      classMap={(col, row, value) => {
                        let className = '';
                        if (col === 'C') {
                          if (value > dataGrid[row - 1][0] || value < dataGrid[row - 1][1] * 0.7) {
                            className = className.concat(' red-value');
                          } else if (value >= dataGrid[row - 1][1] * 0.7 && value < dataGrid[row - 1][1]) {
                            className = className.concat(' orange-value');
                          } else {
                            className = className.concat(' green-value');
                          }
                          if (oldData[date.format('DD-MM-YYYY')] && oldData[date.format('DD-MM-YYYY')][row - 1] !== value) {
                            className = className.concat(' edituser');
                          }
                        }
                        return className;
                      }}
                      timeRate="quart"
                    />
                  </RowContainerDiv>
                );
              })
            }
            </TableContainerDiv>
          </MainContainerStyled>
        </CardBody>
      </Card>
    </div>
  );
};

TernaVDT.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getRUP: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  norupdata: PropTypes.bool.isRequired,
  rupdata: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata, norupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return { user, language, path, type, id, selectedPdo, selectedOdm, rupdata, norupdata };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: code => dispatch(getRUP(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TernaVDT);
