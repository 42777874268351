import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { opeOptions } from 'app/utils/auth';
import { transparentize } from 'polished';

const SystemBadge = styled(Badge)`
  && {
    margin-bottom: .4rem;
    background-color: #BF5298DD;
    color: #fff;
  }
`;

const CurrentBadge = styled(Badge)`
  && {
    background-color: ${props => transparentize(0.5, props.theme.brandSecondaryColor)};
    color: #fff;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UserRole = (props) => {
  const { userSystemRoles, loadedRoles, type, id } = props;

  const allUsersAuthsOnType = loadedRoles[type];
  const currentUserAuthOnType = allUsersAuthsOnType && allUsersAuthsOnType[id];
  const currentRole = allUsersAuthsOnType && currentUserAuthOnType && currentUserAuthOnType.role;

  /*
  const opeStringsArray = opeOptions
    .map(option => (currentUserAuthOnType && currentUserAuthOnType[option.key] ? option.label.id : null))
    .filter(opeString => opeString);

  let opePlus = opeStringsArray.length > 0;

  opePlus = opePlus && currentRole === 'OPE';
  const opeSingleString = opeStringsArray.reduce((previous, next) => `${next}\n${previous}`, '');
  */

  let bestSystemRole = null;
  // if (userSystemRoles.includes('SYS')) {
  //   bestSystemRole = 'SYS';
  // } else if (userSystemRoles.includes('CSU')) {
  //   bestSystemRole = 'CSU';
  // }
  if (userSystemRoles.includes('Trader')) {
    bestSystemRole = 'Trader';
  } else if (userSystemRoles.includes('TechOffice')) {
    bestSystemRole = 'TechOffice';
  }

  return (
    <Wrapper>
      <SystemBadge>{bestSystemRole}</SystemBadge>
      <CurrentBadge title={bestSystemRole}>
        {currentRole}
      </CurrentBadge>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const { type, id } = state.navigation;
  const { loadedRoles, userSystemRoles } = state.auth;

  return {
    loadedRoles,
    userSystemRoles,
    type,
    id,
  };
};

export default connect(mapStateToProps)(UserRole);
