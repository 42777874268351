import moment from 'moment';

export const marketSessions = [
  {
    value: 1,
    label: 'MGP',
    getStartDate: date => moment(date).subtract(9, 'days').set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).subtract(1, 'days').set({ hour: 12, minute: 0, second: 0, millisecond: 0 }),
  },
  {
    value: 2,
    label: 'MI1',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 12, minute: 55, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).subtract(1, 'days').set({ hour: 15, minute: 0, second: 0, millisecond: 0 }),
  },
  {
    value: 3,
    label: 'MI2',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 12, minute: 55, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).subtract(1, 'days').set({ hour: 16, minute: 30, second: 0, millisecond: 0 }),
  },
  {
    value: 4,
    label: 'MI3',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 17, minute: 30, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).subtract(1, 'days').set({ hour: 23, minute: 45, second: 0, millisecond: 0 }),
  },
  {
    value: 5,
    label: 'MI4',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 17, minute: 30, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).set({ hour: 3, minute: 45, second: 0, millisecond: 0 }),
  },
  {
    value: 6,
    label: 'MI5',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 17, minute: 30, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).set({ hour: 7, minute: 45, second: 0, millisecond: 0 }),
  },
  {
    value: 7,
    label: 'MI6',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 17, minute: 30, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).set({ hour: 11, minute: 15, second: 0, millisecond: 0 }),
  },
  {
    value: 8,
    label: 'MI7',
    getStartDate: date => moment(date).subtract(1, 'days').set({ hour: 17, minute: 30, second: 0, millisecond: 0 }),
    getEndDate: date => moment(date).set({ hour: 15, minute: 45, second: 0, millisecond: 0 }),
  },
];

const getStartEndDateSession = (session, date) => {
  const marketSession = marketSessions.find(marketSession => marketSession.label === session);
  const startDate = marketSession.getStartDate(date);
  const endDate = marketSession.getEndDate(date);
  return { startDate, endDate };
};

export const checkSession = (session, date) => {
  const result = getStartEndDateSession(session, date);
  const { startDate, endDate } = result;
  return moment().isBetween(startDate, endDate);
};

export const getNextSession = (date) => {
  const sessions = ['MGP', 'MI1', 'MI2', 'MI3', 'MI4', 'MI5', 'MI6', 'MI7'];

  let nextSession = null;
  let nextSessionStartDate = null;

  sessions.forEach((session) => {
    const result = getStartEndDateSession(session, date);
    const { startDate, endDate } = result;

    if (moment().isBetween(startDate, endDate)) {
      if (!nextSessionStartDate || moment(startDate).isBefore(moment(nextSessionStartDate))) {
        nextSession = session;
        nextSessionStartDate = moment(startDate);
      }
    }
  });

  return nextSession;
};

export const getActiveHoursBySession = (session, date) => {
  const activeHours = Array(24).fill(false);
  switch (session) {
    case 'MGP':
    case 'MI1':
    case 'MI2':
      // Ore 0-24 del giorno D
      activeHours.forEach((hour, index) => {
        activeHours[index] = true;
      });
      break;
    case 'MI3':
      // Ore 5-24 del giorno D
      if (checkSession('MI3', date)) {
        activeHours.forEach((hour, index) => {
          if (index >= 4) {
            activeHours[index] = true;
          }
        });
      }
      break;
    case 'MI4':
      // Ore 9-24 del giorno D
      if (checkSession('MI4', date)) {
        activeHours.forEach((hour, index) => {
          if (index >= 8) {
            activeHours[index] = true;
          }
        });
      }
      break;
    case 'MI5':
      // Ore 13-24 del giorno D
      if (checkSession('MI5', date)) {
        activeHours.forEach((hour, index) => {
          if (index >= 12) {
            activeHours[index] = true;
          }
        });
      }
      break;
    case 'MI6':
      // Ore 17-24 del giorno D
      if (checkSession('MI6', date)) {
        activeHours.forEach((hour, index) => {
          if (index >= 16) {
            activeHours[index] = true;
          }
        });
      }
      break;
    case 'MI7':
      // Ore 21-24 del giorno D
      if (checkSession('MI7', date)) {
        activeHours.forEach((hour, index) => {
          if (index >= 20) {
            activeHours[index] = true;
          }
        });
      }
      break;
    default: // Do nothing;
  }
  return activeHours;
};

export const getActiveHours = (date) => {
  const activeHours = Array(24).fill(false);
  // Ore 0-24 del giorno D
  if (checkSession('MGP', date) || checkSession('MI1', date) || checkSession('MI2', date)) {
    activeHours.forEach((hour, index) => {
      activeHours[index] = true;
    });
  }
  // Ore 5-24 del giorno D
  if (checkSession('MI3', date)) {
    activeHours.forEach((hour, index) => {
      if (index >= 4) {
        activeHours[index] = true;
      }
    });
  }
  // Ore 9-24 del giorno D
  if (checkSession('MI4', date)) {
    activeHours.forEach((hour, index) => {
      if (index >= 8) {
        activeHours[index] = true;
      }
    });
  }
  // Ore 13-24 del giorno D
  if (checkSession('MI5', date)) {
    activeHours.forEach((hour, index) => {
      if (index >= 12) {
        activeHours[index] = true;
      }
    });
  }
  // Ore 17-24 del giorno D
  if (checkSession('MI6', date)) {
    activeHours.forEach((hour, index) => {
      if (index >= 16) {
        activeHours[index] = true;
      }
    });
  }
  // Ore 21-24 del giorno D
  if (checkSession('MI7', date)) {
    activeHours.forEach((hour, index) => {
      if (index >= 20) {
        activeHours[index] = true;
      }
    });
  }
  return activeHours;
};

export const getActiveHourVDT = (dat) => {
  let activeHours = Array(96).fill(false);
  const date = new Date();
  const actualH = date.getHours();
  const actualM = date.getMinutes();
  const quarter = Math.floor(actualM / 15) + 3;
  const index = quarter + actualH * 4;


  if (moment(dat).isAfter(moment(date), "day")) {
    activeHours.fill(true);
  } else if (dat.isSame(date,'day')) {
    activeHours.forEach((hour, idx) => {
      if (idx >= index) {
        activeHours[idx] = true;
      }
    });
  }

  return activeHours;
};
