import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  &&& {
    position: relative;
    padding: 0;

    ${props => props.active && `
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    `};
  }
`;

export const Dropdown = styled.div`
  && {
    overflow-y: auto;
    max-height: ${props => props.maxHeight || '20rem'};
    position: absolute;
    ${props => props.directionUp && 'top: auto; bottom: 2.5rem;'};
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: ${props => props.theme.common.background.primaryColor};
    color: ${props => props.theme.common.text.primaryColor};
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.16);

    &::-webkit-scrollbar {
      width: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.navbar.borderColor};
    }
  }
`;

export const DropdownItem = styled.button`
  && {
    border: none;
    color: ${props => props.theme.common.text.primaryColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.5rem;

    ${props => props.active && `
      background-color: ${props.theme.common.background.secondaryColor};
    `};
  }
`;
