import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getUrl } from 'app/utils/navigation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardBody } from 'reactstrap';
import { Button, DataTable, IconButton } from 'app/common';
import { Trans } from '@lingui/macro';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getRUP, patchRUP, postRUP, updateRUP } from 'ducks/actions/trading';
import EditRUPZoneModal from './EditRUPZoneModal';

const CardStyled = styled(Card)`
  padding: 1rem;
`;
const StyledButtonNew = styled(Button)`
  && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const CompileRUP = ({
  selectedPdo,
  type,
  id,
  staticRupData,
  getRUP,
  patchRUP,
  postRUP,
  history,
}) => {
  const [isDeleted, setisDeleted] = useState(null);
  const [selectedrup, setSelectedrup] = useState([]);
  const [isCompiled, setisCompiled] = useState(true);
  const [showEditZone, setShowEditZone] = useState(false);
  const [selectedZoneId, setSelectedZoneId] = useState();
  const [rupdata, setRupData] = useState(
    staticRupData && staticRupData.filter((d) => !d.isDeleted)
  );

  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
    }
  }, [selectedPdo]);

  const handleClose = () => {
    console.log('going back: ', history);
    history.goBack();
  };

  const confirmSubmit = () => {
    console.log('confirmSubmit data ', rupdata);
    startLoader();
    Array.isArray(rupdata) &&
      rupdata.forEach((d) => {
        if (d.id) {
          console.log('patchRUP ', d);
          patchRUP(d);
        } else {
          console.log('postRUP ', d);
          postRUP(d);
        }
      });
    stopLoader();
    handleClose();
  };

  const hideCompiled = () => {
    setisCompiled(true);
  };

  const deleteRUP = (id) => {
    // eslint-disable-next-line eqeqeq
    const obj = rupdata.find((el) => el.id == id);
    obj.isDeleted = true;
    patchRUP(obj);
    setisDeleted(null);
  };

  const deleteZone = (id) => {
    console.log('delete ', id);
    let delIndex = rupdata && rupdata.findIndex((r) => r.fascia === id);
    if (delIndex < 0) return;
    let newRupData = [...rupdata];
    newRupData[delIndex].isDeleted = true;
  //  newRupData[delIndex].fascia = -1;
    const deletedZone = { ...newRupData[delIndex] };
    newRupData = newRupData
      .filter((d) => !d.isDeleted)
      .sort((a, b) => a.fascia - b.fascia)
      .map((d, index) => ({ ...d, fascia: index + 1 }));
    newRupData.push(deletedZone);
    console.log('newRupData ', newRupData);
    setRupData(newRupData);
  };

  const hideDelete = () => {
    setisDeleted(null);
  };

  const selectedRUP = (el) => {
    setSelectedrup([el]);
    setisDeleted(true);
  };

  const iSRupdataFull = () => {
    if (rupdata.length >= 2) return true;
    return false;
  };

  return (
    <div>
      <CardStyled>
        <CardHeader>
          <Trans>Compilazione dati statici RUP</Trans>
        </CardHeader>
        <CardBody>
          <DataTable
            keyField="id"
            sortby="fascia"
            width="100%"
            headers={[
              {
                index: 1,
                title: <Trans>Fascia</Trans>,
                property: 'fascia',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 2,
                title: <Trans>P.min</Trans>,
                property: 'pmin',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 3,
                title: <Trans>P.max</Trans>,
                property: 'pmax',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 4,
                title: <Trans>Assetto</Trans>,
                property: 'assetto',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 5,
                title: <Trans>P.tel.min</Trans>,
                property: 'ptelmin',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 6,
                title: <Trans>P.tel.max</Trans>,
                property: 'ptelmax',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 7,
                title: <Trans>T.ram</Trans>,
                property: 'tram',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 8,
                title: <Trans>T.der</Trans>,
                property: 'tder',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 9,
                title: <Trans>T.avv</Trans>,
                property: 'tavv',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 10,
                title: <Trans>T.arr</Trans>,
                property: 'tarr',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 11,
                title: <Trans>T.ris</Trans>,
                property: 'tris',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 12,
                title: <Trans>Grad +</Trans>,
                property: 'gradp',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 13,
                title: <Trans>Grad -</Trans>,
                property: 'gradm',
                dataType: 'string',
                canSearch: true,
              },
              {
                index: 14,
                title: <Trans>BRS</Trans>,
                property: 'brs',
                dataType: 'string',
                canSearch: true,
              },
            ]}
            data={(rupdata && rupdata.filter((r) => !r.isDeleted)) || []}
            noData={<Trans>Nessuna fascia inserita</Trans>}
            rowButtons={(el) => {
              return (
                <>
                  <IconButton
                    icon="pencil-alt"
                    color="#c00"
                    onClick={() => {
                      setShowEditZone(true);
                      setSelectedZoneId(el.fascia);
                    }}
                  />
                  <IconButton icon="trash" color="#c00" onClick={() => deleteZone(el.fascia)} />
                </>
              );
            }}
          />
        </CardBody>
        <EditRUPZoneModal
          pdo={selectedPdo && selectedPdo.code}
          onSubmit={setRupData}
          rupdata={rupdata}
          isOpen={showEditZone}
          zoneId={selectedZoneId}
          toggle={() => setShowEditZone(false)}
        />
        <Modal isOpen={isDeleted != null} toggle={hideDelete}>
          <ModalHeader toggle={hideDelete}>
            <Trans>Conferma rimozione fascia</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>Desideri confermare la rimozione della fascia selezionata?</Trans>
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => deleteRUP(selectedrup)}>
              <Trans>Elimina</Trans>
            </Button>
            <Button color="secondary" onClick={hideDelete}>
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={!isCompiled} toggle={hideCompiled}>
          <ModalHeader toggle={hideCompiled}>
            <Trans>Compilazione fascie</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>Compilare almeno una fascia!</Trans>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={hideCompiled}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        <div>
          <StyledButtonNew
            color="secondary"
            onClick={() => {
              setShowEditZone(true);
              setSelectedZoneId(0);
            }}
          >
            Aggiungi fascia
          </StyledButtonNew>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <StyledButtonNew color="primary" onClick={() => confirmSubmit()}>
            Salva
          </StyledButtonNew>
          <StyledButtonNew color="secondary" onClick={() => handleClose()}>
            Annulla
          </StyledButtonNew>
        </div>
      </CardStyled>
      <br />
    </div>
  );
};

CompileRUP.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  rupdata: PropTypes.object.isRequired,
  getRUP: PropTypes.object.isRequired,
  patchRUP: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return { user, language, path, type, id, selectedPdo, selectedOdm, staticRupData: rupdata };
};

const mapDispatchToProps = (dispatch) => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: (code) => dispatch(getRUP(code)),
  patchRUP: (obj) => dispatch(patchRUP(obj)),
  postRUP: (obj) => dispatch(postRUP(obj)),
  updateRUP: (obj) => dispatch(updateRUP(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompileRUP);
