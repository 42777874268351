/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { api, customApi } from 'api';
import styled from 'styled-components';
import { Card } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { formatNumber } from 'app/utils/commonUtils';
import { getErrorMessages } from 'app/utils/validation';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { clearUserData, saveUserUVAMReserve } from 'ducks/actions/trading';
import { Roles } from 'app/utils/auth';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StyledButtonSave = styled(Button)`
 && {
    left: 190px;
    height: 24px;
    padding: 0px 10px 0px 10px;
    position: sticky;
  }

  && span {
     margin-left: 7px;
   }
`;


const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        data: action.payload.data,
        oldData: action.payload.oldData,
        dataGrid: action.payload.dataGrid,
        ternaAuctions: action.payload.ternaAuctions,
        lastRequest: action.payload.lastRequest,
      };
    case 'setDataGrid':
      return {
        ...state,
        dataGrid: action.payload.dataGrid,
        oldData: action.payload.oldData,
      };
    default:
      throw new Error();
  }
};


const UVAMReserve = (props) => {
  const { startLoader, stopLoader, selectedPdo, language, trading, doClearUserData, doSaveUserUVAMReserve, userSystemRoles } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));

  const initialState = {
    data: [],
    oldData: [],
    dataGrid: [],
    ternaAuctions: [],
    lastRequest: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const userIsTrader = userSystemRoles.includes(Roles.Trader);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  const getData = async (forceRequest) => {
    if (selectedPdo) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      const params = {
        startDate: start,
        endDate: end,
        pdoID: selectedPdo.pdoID,
      };

      if (!_.isEqual(state.lastRequest, params) || forceRequest) {
        startLoader();

        const initStatePayload = {};

        initStatePayload.oldData = null;
        initStatePayload.dataGrid = null;
        initStatePayload.lastRequest = params;

        try {
          const arrayPromise = [customApi.post('/optz/getUVAMReserve', params)];

          if (selectedPdo.isUvam) {
            arrayPromise.push(api.get(`/TernaAuctions?filter={"where": {"pdoId": ${selectedPdo.pdoID}, "active": true}}`));
          }

          const res = await Promise.all(arrayPromise);

          if (res && res[0]) {
            if (res[0].data) {
              initStatePayload.data = res[0].data;
            } else {
              const { errorMessage } = getErrorMessages(res);
              toast.error(errorMessage, { autoClose: 5000 });
            }
          }

          if (res && res[1]) {
            if (res[1].data) {
              const ternaAuctions = res[1].data;
              initStatePayload.ternaAuctions = ternaAuctions;
            } else {
              const { errorMessage } = getErrorMessages(res);
              toast.error(errorMessage, { autoClose: 5000 });
            }
          }

        } catch (err) {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        }

        dispatch({ type: 'init', payload: initStatePayload });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [selectedPdo, startDate, endDate]);

  const getDataGrid = () => {
    const dataGridAll = {};
    dates.forEach((date) => {
      const dateRef = moment(date).startOf('day').format('YYYY-MM-DD');

      let userUVAMReserve;
      if (trading.uvamReserveTable && trading.uvamReserveTable[date.format('DD/MM/YYYY')] && trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID]) {
        userUVAMReserve = trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].userUvamReserve;
      }

      /* compongo grid */
      const dataGrid = [];
      for (let hour = 0; hour < 24; hour += 1) {
        const rowData = [];
        // const dataCur = state.data && state.data.find(item => item.date === dateRef && item.hour == hour + 1) || null;
        const dataCur = state.data && state.data.find(item => item.date === dateRef) || null;
        // Colonna Riserva UVAM Disponibile
        if (userUVAMReserve && userUVAMReserve[hour] && userUVAMReserve[hour][0] !== undefined && userUVAMReserve[hour][0] !== null) {
          rowData.push(userUVAMReserve[hour][0]);
        } else {
          rowData.push(dataCur && dataCur.uvamReserve ? dataCur.uvamReserve[hour] : 0);
        }
        // Colonne Riserva UVAM Sito j_esimo
        // Colonne non editabili dall'utente, quindi valori mai presenti sulla uvamReserveTable
        if (selectedPdo) {
          selectedPdo.pdoSites.forEach((site, index) => {
            const finded = dataCur && dataCur.sites[site.siteID] || null;
            rowData.push(finded && finded.uvamResidual && finded.uvamResidual[hour] !== undefined ? finded.uvamResidual[hour] : 0);
          });
        }
        dataGrid.push(rowData);
      }

      dataGridAll[date.format('DD/MM/YYYY')] = dataGrid;
    });

    const payload = { dataGrid: dataGridAll, oldData: state.data};
    dispatch({ type: 'setDataGrid', payload });
  };

  useEffect(() => {
    getDataGrid();
  }, [state.data, trading]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const disabledStartDate = (startDate) => {
    if (!startDate || !endDate) return false;
    // Viene lasciata la possibilità di muoversi senza limite sulla data di start,
    // ed in automatica viene aggioranta la data di end
    return endDate.diff(startDate, 'days') <= 0;
    // return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (endDate.diff(value, 'days') >= 9) {
        const newEndDate = moment(value).add(endDate.diff(startDate, 'days'), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const saveUVAMReserve = async (refDate) => {
    if (state.dataGrid) {
      startLoader();

      // Invio valori all'ottimizzatore
      const params = { pdoID: selectedPdo.pdoID, values: [] };
      params.values.push({ date: refDate.format('YYYY-MM-DD'), dataValues: state.dataGrid[refDate.format('DD/MM/YYYY')].map(item => Number(item[0])) });

      await customApi.post('/optz/saveUVAMReserve', params).then(async (res) => {
        if (res.status === 200) {
          await getData(true);
          doClearUserData(refDate.format('DD/MM/YYYY'), +selectedPdo.pdoID);
          toast.success(i18n._(t`Salvataggio eseguito con successo.`), { autoClose: 5000 });
        } else {
          const { errorMessage } = getErrorMessages(res);
          toast.error(errorMessage, { autoClose: 5000 });
        }
      }).catch((err) => {
        const { errorMessage } = getErrorMessages(err);
        toast.error(errorMessage, { autoClose: 5000 });
      });

      stopLoader();
    }
  };

  // Check enable/disable button Save
  const checkEnableButtonSave = (date) => {
    const dateRef = moment(date).format('YYYY-MM-DD');

    let isDisabled = true;
    if (trading.uvamReserveTable && trading.uvamReserveTable[date.format('DD/MM/YYYY')] && trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID]) {
      if (trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].userUvamReserve && trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].userUvamReserve.length > 0) {
        isDisabled = trading.uvamReserveTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].userUvamReserve.map((item, index) => {
          const dataCur = state.data && state.data.find(item => item.date === dateRef && item.hour == index + 1) || null;
          let objToCOmpare = null;
          if (dataCur) {
            objToCOmpare = { uvamReserve: dataCur.uvamReserve };
          }
          return item.map((innerItem, indexKey) => {
            if (objToCOmpare) {
              const dataValue = objToCOmpare[Object.keys(objToCOmpare)[indexKey]];
              return dataValue == innerItem;
            }
            return innerItem == '';
          }).indexOf(false) !== -1;
        }).indexOf(true) === -1;
      }
    }
    return isDisabled;
  };

  const handleCellsChanged = async (date, arrayOfChanges) => {
    let changes = arrayOfChanges;

    // eslint-disable-next-line no-restricted-syntax
    for (const { cell, row, col, value } of arrayOfChanges) {
      if (selectedPdo && selectedPdo.isUvam && col === 0 && state.ternaAuctions) {
        // 0 ≤ RUVAM ≤ Quantità_assegnata
        const filteredTernaAuction = state.ternaAuctions.find(ta => moment(date).isBetween(moment(ta.datePeriod.from, 'MM/YYYY'), moment(ta.datePeriod.to, 'MM/YYYY'), 'month', '[]'));

        if ((value && Number(value) < 0) || (filteredTernaAuction.quantity && value > Number(filteredTernaAuction.quantity))) {
          toast.error(i18n._(t`Errore nella colonna Riserva UVAM disponibile per il blocco orario n° ${row}: I valori inseriti devono essere compresi tra 0 e la quantità assegnata per l’asta Terna di pertinenza.`), { autoClose: 5000 });
          changes = [];
        }
      }
    }
    return changes;
  };

  /* Compongo header */
  const header = [];
  header.push(i18n._(t`Riserva UVAM disponibile`));

  if (selectedPdo) {
    selectedPdo.pdoSites.forEach((site) => {
      header.push(<span>{site.siteName} <Bold>{site.registryCode}</Bold></span>);
    });
  }

  return (
    <CardStyled>
      <div>
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            disabledDate={disabledStartDate}
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
            dates && dates.map((date, index) => {
              let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
              sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
              const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
              const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');
              const dataCur = state.data && state.data[index] || null;
              const dataOrigin = state.oldData && state.oldData[index] || null;

              const isDisabled = checkEnableButtonSave(date);

              return (
                <RowContainerDiv>
                  { index !== 0 && index < dates.length && <Spacer /> }
                  <HaderComponent>
                    <HaderDateComponent>
                      {sDate}
                    </HaderDateComponent>
                    {selectedPdo && selectedPdo.isUvam && userIsTrader && (
                      <StyledButtonSave color="primary" onClick={e => saveUVAMReserve(date)} disabled={isDisabled}>
                        <FontAwesomeIcon icon={faSave} />
                        <span><Trans>Salva</Trans></span>
                      </StyledButtonSave>
                    )}
                    <HaderDayComponent>
                      {isToday ? <Trans>OGGI</Trans> : ''}
                      {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                    </HaderDayComponent>
                  </HaderComponent>
                  <Datasheet
                    data={state.dataGrid && state.dataGrid[date.format('DD/MM/YYYY')] || null}
                    enableCheckBox={false}
                    date={date}
                    header={header}
                    handleCellsChanged={changes => handleCellsChanged(date, changes)}
                    saveCell={(cell, value) => {
                      doSaveUserUVAMReserve(date, selectedPdo.pdoID, cell.x, cell.y, value);
                    }}
                    readyOnly={(col, row, value) => {
                      if (selectedPdo && selectedPdo.isUvam && col === 'A' && state.ternaAuctions && userIsTrader) {
                        // Le colonne sono editabili solo in corrispondenza di blocchi orari futuri,
                        // compresi all'interno dei giorni e degli orari di validità specificati per l'asta di pertinenza
                        const filteredTernaAuction = state.ternaAuctions.find(ta => moment(date).isBetween(moment(ta.datePeriod.from, 'MM/YYYY'), moment(ta.datePeriod.to, 'MM/YYYY'), 'month', '[]'));
                        if (filteredTernaAuction && moment(`${row-1}:00`, 'HH:mm').isBetween(moment(filteredTernaAuction.timePeriod.from, 'HH:mm'), moment(filteredTernaAuction.timePeriod.to, 'HH:mm'), 'hour', '[)')) {
                          return false;
                        }
                      }
                      return true;
                    }}
                    normalizeValue={(x, y, value) => {
                      return (value ? formatNumber(value, 3) : Number(0).toFixed(3));
                    }}
                    classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                      let className = '';

                      const refDate = moment(dates[index].toObject()).hour(row - 1).startOf('hours');

                      if (selectedPdo && selectedPdo.isUvam && col === 'A' && trading.uvamReserveTable) {
                        const uvamReserveTable = trading.uvamReserveTable[refDate.format('DD/MM/YYYY')];
                        if (uvamReserveTable && uvamReserveTable[selectedPdo.pdoID] && uvamReserveTable[selectedPdo.pdoID].userUvamReserve) {
                          try {
                            const userUVAMReserve = uvamReserveTable[selectedPdo.pdoID].userUvamReserve;
                            if (userUVAMReserve[row - 1] && userUVAMReserve[row - 1][0] !== undefined) {
                              const valueToCompare = dataOrigin.uvamReserve && dataOrigin.uvamReserve[row - 1][0] ? Number(dataOrigin.uvamReserve[row - 1][0]) : Number(0);
                              if (Number(userUVAMReserve[row - 1][0]) !== valueToCompare || (userUVAMReserve[row - 1][0] === '' && valueToCompare)) {
                                className = className.concat(' edituser');
                              }
                            }
                          } catch (warn) {
                            // console.warn(warn);
                          }
                        }
                      }
                      return className;
                    }}
                  />
                </RowContainerDiv>
              );
            })
          }
        </TableContainerDiv>
      </MainContainerStyled>
    </CardStyled>
  );
};

UVAMReserve.propTypes = {
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.preferences;
  const { selectedPdo } = state.navigation;
  const { userSystemRoles } = state.auth;
  const { trading } = state;
  return { language, selectedPdo, trading, userSystemRoles };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  doClearUserData: (date, pdoID) => dispatch(clearUserData(date, pdoID, 'uvamReserveTable')),
  doSaveUserUVAMReserve: (date, pdoID, x, y, value) => dispatch(saveUserUVAMReserve(date, pdoID, x, y, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UVAMReserve);
