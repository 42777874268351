/* eslint-disable no-restricted-globals */
/* eslint-disable no-trailing-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { getOPTZLastRun, callOPTZ } from 'ducks/actions/trading';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getActiveHours } from '../tradingCommon/tradingUtils';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const StyledButtonOptimize = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;


const CostCurve = ({ user, startLoader, stopLoader, selectedPdo, language, getStatus, callOptz, optimizeLoading, optimizeError, runs }) => {
  const [startDateOptz, setStartDateOptz] = useState(moment());
  const [endDateOptz, setEndDateOptz] = useState(moment().add(1, 'days'));

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));
  const [userData, setUserData] = useState({});

  const [lastRequest, setLastRequest] = useState([]);
  const [openRefreshDataDialog, setRefreshDataDialog] = useState(false);
  const [toastId, setPoastId] = useState(null);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  useEffect(() => {
    setUserData({});// ogni volta che tornano i dati dell'attimizzatore bisogna dimenticarsi dei dati inseriti dall'utente
  }, [runs]);

  const toggleRefreshDataDialog = () => {
    setRefreshDataDialog(!openRefreshDataDialog);
  };

  const refreshData = () => {
    const today = startDateOptz.startOf('day');
    const rangeEnd = endDateOptz.endOf('day');

    /* prendi i dati */
    const deltaNetworkExchange = [];

    const curDate = moment(today.toObject());
    while (curDate.isSameOrBefore(rangeEnd, 'day')) {
      for (let i = 0; i < 24; i += 1) {
        const dataCur = runs.find(f => f.date === curDate.format('DD-MM-YYYY'));
        if (dataCur) {
          const key = curDate.format('DD-MM-YYYY');
          if (userData[key] && userData[key][i] && userData[key][i][0]) {
            deltaNetworkExchange.push(parseFloat(userData[key][i][0]));
          } else {
            deltaNetworkExchange.push(dataCur.assetQuantity[i] ? dataCur.assetQuantity[i] : 0);
          }
        } else {
          deltaNetworkExchange.push(0);
        }
      }
      curDate.add(1, 'days');
    }
    /* l'ottimizzatore accetta orari arrotondati per ora in difetto, es 23:00 */
    callOptz(user, selectedPdo.pdoID, selectedPdo.code, selectedPdo.odmID, selectedPdo.pdoType, selectedPdo.zone, today.startOf('hour').format('YYYY-MM-DD HH:mm'), rangeEnd.startOf('hour').format('YYYY-MM-DD HH:mm'), deltaNetworkExchange);

    toggleRefreshDataDialog();
  };

  useEffect(() => {
    if (optimizeLoading) {
      setPoastId(toast.warn(i18n._(t`Ottimizzazione in corso`), { autoClose: false }));
    } else if (toastId) {
      toast.dismiss();
      if (optimizeError != null) toast.error(optimizeError, { autoClose: 5000 });
      else toast.success(i18n._(t`Ottimizzazione completata`), { autoClose: 5000 });
      setPoastId(null);
    }
  }, [optimizeLoading]);

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      /* l'ottimizzatore accetta solo arrotamenti orari per difetto */
      const params = {
        pdoID: selectedPdo.pdoID,
        odmID: selectedPdo.odmID,
        startDate: startDate.startOf('day').startOf('hour').format('YYYY-MM-DD HH:mm'),
        endDate: endDate.endOf('day').startOf('hour').format('YYYY-MM-DD HH:mm'),
      };

      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        await getStatus(params.startDate, params.endDate, params.pdoID, params.odmID);
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'days').startOf('day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'days').endOf('day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'days').startOf('day'));
    setEndDate(moment(endDate.toObject()).add(1, 'days').endOf('day'));
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDateOptz') {
      if (endDateOptz.diff(value, 'days') < 0) {
        const newEndDate = moment(value.toObject());
        setEndDateOptz(newEndDate);
      }
      setStartDateOptz(value);
    } else if (field === 'endDateOptz') setEndDateOptz(value);
    else if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const normalizeValue = (col, row, value) => {
    if (['A', 'B', 'C', 'D', 'E', 'F', 'G'].indexOf(col) >= 0) {
      return value && !isNaN(value) ? Number(value).toFixed(3) : value; // Quantità MWh
    } if (['H', 'I', 'J'].indexOf(col) >= 0) {
      return value && !isNaN(value) ? Number(value).toFixed(2) : value; // Prezzi
    }
    return value;
  };

  /* l'ora corrisponde alla key della riga dell'array 0-24, nel caso venga salta inserire un record vuoto null */
  return (
    <CardStyled>
      <div>
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>

        <StyledButtonOptimize color="primary" onClick={toggleRefreshDataDialog}>Ottimizza</StyledButtonOptimize>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const isTomorrow = moment().add(1, 'days').isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const activeHours = getActiveHours(date);
                const dataCur = runs.find(f => f.date === date.format('DD-MM-YYYY') && f.pdoID == selectedPdo.pdoID);

                /* Compongo header */
                const header = [];
                header.push(i18n._(t`Quantità asset simulazione`));
                header.push(i18n._(t`Margine a salire`));
                header.push(i18n._(t`Margine a scendere`));
                header.push(i18n._(t`Riserva UVAM disponibile`));
                header.push(i18n._(t`Q1 ┐ senza spegn.`));
                header.push(i18n._(t`Q2 ┘ senza accens.`));
                header.push(i18n._(t`Q3 ┘ con accens`));
                header.push(i18n._(t`P1 ┐ senza spegn.`));
                header.push(i18n._(t`P2 ┘ senza accens.`));
                header.push(i18n._(t`P3 ┘ con accens.`));

                /* compongo grid */
                const dataGrid = [];
                for (let i = 0; i < 24; i += 1) {
                  const rowData = [];
                  if (dataCur) {
                    try {
                      if (userData[date.format('DD-MM-YYYY')][i][0] == normalizeValue('A', i, dataCur.assetQuantity[i])) {
                        userData[date.format('DD-MM-YYYY')][i][0] = undefined;
                        rowData.push(dataCur.assetQuantity[i]);
                      } else {
                        rowData.push(userData[date.format('DD-MM-YYYY')][i][0]);
                      }
                    } catch (err) {
                      rowData.push(dataCur.assetQuantity[i]);
                    }

                    try {
                      rowData.push(userData[date.format('DD-MM-YYYY')][i][1]);
                    } catch (err) {
                      rowData.push(dataCur.upMargin[i] || 0);
                    }

                    try {
                      rowData.push(userData[date.format('DD-MM-YYYY')][i][2]);
                    } catch (err) {
                      rowData.push(dataCur.downMargin[i] || 0);
                    }

                    rowData.push(dataCur.uvamReserve[i] ? dataCur.uvamReserve[i] : 0);

                    rowData.push(dataCur.Q1[i] ? dataCur.Q1[i] : 0);
                    rowData.push(dataCur.Q2[i] ? dataCur.Q2[i] : 0);
                    rowData.push(dataCur.Q3[i] ? dataCur.Q3[i] : 0);
                    rowData.push(dataCur.PZ1[i] ? dataCur.PZ1[i] : 0);
                    rowData.push(dataCur.PZ2[i] ? dataCur.PZ2[i] : 0);
                    rowData.push(dataCur.PZ3[i] ? dataCur.PZ3[i] : 0);
                  }
                  dataGrid.push(rowData);
                }

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataGrid}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      saveCell={(cell, newValue) => {
                        if (cell.x === 0) {
                          let value = newValue ? parseFloat(newValue) : 0;
                          if (cell.x === 0 && value === '') value = undefined;
                          const newUserData = { ...userData };

                          /* il delta dell'asset simulazione dovrà variare i margini a scendere e salire */
                          let delta = value;
                          try {
                            if (!isNaN(parseFloat(cell.value))) {
                              delta = parseFloat(value) - parseFloat(cell.value);
                            }
                          } catch (warn) {
                            console.warn(warn);
                          }

                          const findDateMaxAvaible = moment(date.toObject()).hour(cell.y).startOf('hour').toISOString();

                          if (!newUserData[date.format('DD-MM-YYYY')]) newUserData[date.format('DD-MM-YYYY')] = [];
                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y]) newUserData[date.format('DD-MM-YYYY')][cell.y] = [];
                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y][cell.x]) newUserData[date.format('DD-MM-YYYY')][cell.y][cell.x] = null;
                          newUserData[date.format('DD-MM-YYYY')][cell.y][cell.x] = value;

                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y]) newUserData[date.format('DD-MM-YYYY')][cell.y] = [];
                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y][1]) newUserData[date.format('DD-MM-YYYY')][cell.y][1] = null;
                          newUserData[date.format('DD-MM-YYYY')][cell.y][1] = parseFloat(dataGrid[cell.y][1] || 0) + delta;
                          if (newUserData[date.format('DD-MM-YYYY')][cell.y][1] < 0) newUserData[date.format('DD-MM-YYYY')][cell.y][1] = 0;
                          else if (newUserData[date.format('DD-MM-YYYY')][cell.y][1] > dataCur.vMaxAvaible[findDateMaxAvaible]) newUserData[date.format('DD-MM-YYYY')][cell.y][1] = dataCur.vMaxAvaible[findDateMaxAvaible];

                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y]) newUserData[date.format('DD-MM-YYYY')][cell.y] = [];
                          if (!newUserData[date.format('DD-MM-YYYY')][cell.y][2]) newUserData[date.format('DD-MM-YYYY')][cell.y][2] = null;
                          newUserData[date.format('DD-MM-YYYY')][cell.y][2] = parseFloat(dataGrid[cell.y][2] || 0) - delta;

                          if (newUserData[date.format('DD-MM-YYYY')][cell.y][2] < 0) newUserData[date.format('DD-MM-YYYY')][cell.y][2] = 0;
                          else if (newUserData[date.format('DD-MM-YYYY')][cell.y][2] > dataCur.vMaxAvaible[findDateMaxAvaible]) newUserData[date.format('DD-MM-YYYY')][cell.y][2] = dataCur.vMaxAvaible[findDateMaxAvaible];

                          setUserData(newUserData);
                        }
                      }}
                      normalizeValue={normalizeValue}
                      readyOnly={(col, row, value) => {
                        if (col === 'A') { // Colonna QA Simulazione
                          if (row !== 0) {
                            if (!activeHours[row - 1]) {
                              return true;
                            }
                            return false;
                          }
                        }
                        return true;
                      }
                      }
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        let className = '';

                        const refDate = moment(dates[index].toObject()).hour(row - 1).startOf('hours');

                        if (!activeHours[row - 1]) {
                          className = className.concat('cell-passed-hours');
                        } else if (col === 'A' && userData[refDate.format('DD-MM-YYYY')] && userData[refDate.format('DD-MM-YYYY')][row - 1]) {
                          const pos = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'].indexOf(col);
                          if (userData[refDate.format('DD-MM-YYYY')][row - 1][pos] !== undefined) className = className.concat('edituser');
                        }

                        return className;
                      }}
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
      <Modal size="sm" isOpen={openRefreshDataDialog} toggle={toggleRefreshDataDialog}>
        <ModalHeader toggle={toggleRefreshDataDialog}><Trans>Aggiorna programma</Trans></ModalHeader>
        <ModalBody>
          <PickerContainer>
            <Picker
              value={startDateOptz}
              disabledDate={curDate => !(curDate.diff(moment(), 'days') >= 0 && curDate.diff(moment(), 'days') < 7)}
              onChange={value => onChange('startDateOptz', value)}
            />
          </PickerContainer>
          <PickerContainer>
            <Picker
              disabledDate={curDate => !(curDate.diff(moment(), 'days') >= 0 && curDate.diff(startDateOptz, 'days') >= 0 && curDate.diff(moment(), 'days') < 7)}
              value={endDateOptz}
              onChange={value => onChange('endDateOptz', value)}
            />
          </PickerContainer>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={refreshData}><Trans>Aggiorna</Trans></Button>
          <Button color="secondary" onClick={toggleRefreshDataDialog}><Trans>Chiudi</Trans></Button>
        </ModalFooter>
      </Modal>
    </CardStyled>
  );
};

CostCurve.propTypes = {
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  getStatus: PropTypes.func.isRequired,
  callOptz: PropTypes.func.isRequired,
  optimizeLoading: PropTypes.bool.isRequired,
  runs: PropTypes.array.isRequired,
  optimizeError: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  const { optimizeLoading, optimizeError, runs } = state.trading;
  return { user, language, path, type, id, selectedPdo, optimizeLoading, optimizeError, runs };
};

const mapDispatchToProps = dispatch => ({
  getStatus: (startDate, endDate, pdoID, odmID) => dispatch(getOPTZLastRun(startDate, endDate, pdoID, odmID)),
  callOptz: (user, pdoID, pdoCode, odmID, pdoType, zone, startDate, endDate, deltaNetworkExchange) => dispatch(callOPTZ(user, pdoID, pdoCode, odmID, pdoType, zone, startDate, endDate, deltaNetworkExchange)),
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostCurve);
