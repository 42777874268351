export const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
};


export const getNestedObject = (object, dotSeparatedKeys) => {
  return dotSeparatedKeys.split('.').reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : undefined), object);
};


const isMomentObject = (obj) => {
  if (!obj || !Object.keys(obj)) {
    return false;
  }
  if (obj[Object.keys(obj)[0]] && obj[Object.keys(obj)[0]]._isAMomentObject !== undefined) {
    return true;
  }
  return false;
};


export const mergeDeep = (target, source) => {
  if (!isObject(target)) {
    target = {};
  }

  if (isObject(source) && !isMomentObject(source)) {
    /* eslint-disable-next-line no-restricted-syntax */
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  } else {
    return { ...target, ...source };
  }
  return target;
};
