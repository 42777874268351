import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  & .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${props => props.theme.brandPrimaryColor};
  }

  ${props => props.center && 'width: 2rem; margin: 0 auto;'}
`;

const Switch = ({ id = 'customSwitch', checked, onChange, disabled, label, labelOn, labelOff, center = false }) => {
  return (
    <Div className="custom-control custom-switch" center={center}>
      <input type="checkbox" className="custom-control-input" id={id} checked={checked} disabled={disabled} onChange={onChange} />
      {label && <label className="custom-control-label" htmlFor={id}>{label}</label>}
      {(labelOn || labelOff) && <label className="custom-control-label" htmlFor={id}>{checked ? labelOn : labelOff}</label>}
    </Div>
  );
};

export default Switch;
