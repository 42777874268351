
export const itemSelection = [
  { url: '/prices', selection: 'pdo' },
  { url: '/shortTerm', selection: 'pdo' },
  { url: '/report', selection: 'pdo' },
  { url: '/imbalancePrices', selection: 'pdo' },
  { url: '/upRegistry', selection: 'odm', isUvam: false },
  { url: '/availability', selection: 'odm' },
  { url: '/qa', selection: 'odm', isUvam: false },
  { url: '/costCurve', selection: 'odm', isUvam: false },
  { url: '/upBidding', selection: 'odm', isUvam: false },
  { url: '/uvamBidding', selection: 'odm', isUvam: true },
  { url: '/profitAndLoss', selection: 'odm', isUvam: false },
  { url: '/dispatching', selection: 'odm', isUvam: false },
  { url: '/uvamReserve', selection: 'odm' },
  { url: '/uvamRegistry', selection: 'odm', isUvam: true },
  { url: '/ternaAuctions', selection: 'odm', isUvam: true },
  { url: '/monthlyCheck', selection: 'odm', isUvam: true },
  { url: '/baseline', selection: 'odm', isUvam: true },
  { url: '/bindingProgram', selection: 'odm', isUvam: true },
  { url: '/bde', selection: 'odm', isUvam: true },
  { url: '/varComunication', selection: 'odm', isUvam: true },
  { url: '/uvamVDT', selection: 'odm', isUvam: true },
  { url: '/uvamVDT/dailyplan', selection: 'odm', isUvam: true },
  { url: '/uvamVDT/handle', selection: 'odm', isUvam: true },
];

export const getHiddenItemsBySelection = (selectedOdm, selectedPdo) => {
  if (!itemSelection && !selectedOdm && !selectedPdo) {
    return [];
  }
  const hiddenItems = itemSelection.reduce((hidden, requirement) => {
    const { url, selection, isUvam } = requirement;
    if (selection === 'odm' && !selectedOdm && !selectedPdo) {
      return hidden.concat(url);
    }
    if (selection === 'pdo' && selectedOdm && selectedPdo) {
      return hidden.concat(url);
    }
    if (selection === 'odm' && selectedOdm && !selectedPdo) {
      return hidden.concat(url);
    }
    if (selection === 'odm' && selectedOdm && selectedPdo && (isUvam !== undefined && isUvam === true && !selectedPdo.isUvam)) {
      return hidden.concat(url);
    }
    if (selection === 'odm' && selectedOdm && selectedPdo && (isUvam !== undefined && isUvam === false && selectedPdo.isUvam)) {
      return hidden.concat(url);
    }

    return hidden;
  }, []);
  return hiddenItems;
};
