import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getUrl } from 'app/utils/navigation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { api, customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardBody } from 'reactstrap';
import { Button, DataTable, IconButton } from 'app/common';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { copyRUP, getRUP } from 'ducks/actions/trading';
import DynamicForm from '../../../common/DynamicForm';
import { optionHour, optionVDT } from './constant';

const StyledButtonNew = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;


const EditVdt = ({ selectedPdo, selectedOdm, language, startLoader, stopLoader, user, type, id, rupdata, getRUP, patchRUP, history, location }) => {
  const [data, setData] = useState([]);
  const [errorButton, setErrorButton] = useState(false);
  const [isDeleted, setisDeleted] = useState(null);
  const [dateTerna, setDateTerna] = useState(null);
  const [selectedrup, setSelectedrup] = useState([]);
  const [isCompiled, setisCompiled] = useState(true);
  let editvalue = [];

  const init = async () => {
    const data = await api.get(`TernaSendVs/${location.state.selected}`);
    editvalue = { ...data.data };
    editvalue.startHour = editvalue.startDate.substr(11, 5);
    editvalue.endHour = editvalue.endDate.substr(11, 5);
    console.log(editvalue);
  };
  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
      init();
    }
  }, [selectedPdo]);
  const rupUrl = getUrl('/uvamVDT/datiRUP', type, id);
  const editUrl = getUrl('/uvamVDT/editRUP', type, id);
  const getMax = () => {
    const values = [];
    rupdata.forEach(f => values.push(parseFloat(f.pmax)));
    return Math.max(...values);
  };
  const initialValue = {
    startDate: moment(location.state.selected[0].startDate),
    endDate: moment(location.state.selected[0].endDate),
    startHour: location.state.selected[0].startDate.substr(11, 5),
    endHour: location.state.selected[0].endDate.substr(11, 5),
    reason: location.state.selected[0].reason,
    note: location.state.selected[0].note,
  };

  const hideCompiled = () => {
    setisCompiled(true);
  };

  const deleteRUP = (id) => {
    const obj = rupdata.find(el => (el.id === id));
    obj.isDeleted = true;
    patchRUP(obj);
    setisDeleted(null);
  };

  const hideDelete = () => {
    setisDeleted(null);
  };
  const handleSend = async (data) => {
    startLoader();
    const params = {
      vdt: data,
      rupdata,
      odmCompanyIdentifier: selectedOdm.odmCompanyIdentifier,
      action: 'MODIFICA',
      ID: location.state.selected.callid,
      oldata: initialValue,
    };
    try {
      const res = await customApi.post('/optz/sendTernaVdt', params);
      if (res.data) {
        stopLoader();
        history.goBack();
      }
    } catch (err) {
      toast.error(err, { autoClose: 5000 });
    }
  };

  const handleSubmit = (data) => {
    const hours = parseInt(data.startHour.substr(0, 2), 10);
    const minutes = parseInt(data.startHour.substr(3, 2), 10);
    const houre = parseInt(data.endHour.substr(0, 2), 10);
    const minutee = parseInt(data.endHour.substr(3, 2), 10);
    data.startDate = data.startDate.set({
      h: hours,
      m: minutes,
      s: 0,
    })
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    data.endDate = data.endDate.set({
      h: houre,
      m: minutee,
      s: 0,
    })
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    data.agent = user.name;
    data.status = 200;
    data.pdoId = selectedPdo.pdoID;
    data.userId = user.id;
    data.sendDate = moment()
      .toString();
    data.power = getMax();
    handleSend(data);
  };


  const hideSendTerna = () => {
    setDateTerna(null);
  };
  const selectedRUP = (el) => {
    setSelectedrup([el]);
    setisDeleted(true);
  };
  const checkHour = (value, values) => {
    const start = parseFloat(values.startHour.replace(':', ''));
    const end = parseFloat(value.replace(':', ''));
    if (end < start || values.endDate.isBefore(values.startDate)) {
      return new Error('Selezionare un orario successivo a quello di inizio');
    } return true;
  };
  const duplicateRUP = (el) => {
    const copied = rupdata.find(ele => ele.id === el);
    copied.fascia = rupdata.length + 1;
    copied.id = null;
    setData(prevState => ({ ...prevState, copied, pdo: selectedPdo.code, cretaed: moment().format(), createby: user.name, isDeleted: false }));
    copyRUP(data);
  };

  const header = [
    { index: 1, title: <Trans>Fascia</Trans>, property: 'fascia', dataType: 'string', canSearch: true },
    { index: 2, title: <Trans>P.min</Trans>, property: 'pmin', dataType: 'string', canSearch: true },
    { index: 3, title: <Trans>P.max</Trans>, property: 'pmax', dataType: 'string', canSearch: true },
    { index: 4, title: <Trans>Assetto</Trans>, property: 'assetto', dataType: 'string', canSearch: true },
    { index: 5, title: <Trans>P.tel.min</Trans>, property: 'ptelmin', dataType: 'string', canSearch: true },
    { index: 6, title: <Trans>P.tel.max</Trans>, property: 'ptelmax', dataType: 'string', canSearch: true },
    { index: 7, title: <Trans>T.ram</Trans>, property: 'tram', dataType: 'string', canSearch: true },
    { index: 8, title: <Trans>T.der</Trans>, property: 'tder', dataType: 'string', canSearch: true },
    { index: 9, title: <Trans>T.avv</Trans>, property: 'tavv', dataType: 'string', canSearch: true },
    { index: 10, title: <Trans>T.arr</Trans>, property: 'tarr', dataType: 'string', canSearch: true },
    { index: 11, title: <Trans>T.ris</Trans>, property: 'tris', dataType: 'string', canSearch: true },
    { index: 12, title: <Trans>Grad +</Trans>, property: 'gradp', dataType: 'string', canSearch: true },
    { index: 13, title: <Trans>Grad -</Trans>, property: 'gradm', dataType: 'string', canSearch: true },
    { index: 14, title: <Trans>BRS</Trans>, property: 'brs', dataType: 'string', canSearch: true }];

  const fields = [
    {
      name: 'startDate',
      type: 'date',
      label: <Trans>Data inizio</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: false,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
      },
    },
    {
      name: 'startHour',
      type: 'select',
      label: <Trans>Orario inizio</Trans>,
      validation: { required: true },
      md: 3,
      options: optionHour,
    },
    {
      name: 'endDate',
      type: 'date',
      label: <Trans>Data fine</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: true,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
      },
    },
    {
      name: 'endHour',
      type: 'select',
      label: <Trans>Orario fine</Trans>,
      validation: { required: true, func: (value, values) => checkHour(value, values) },
      md: 3,
      options: optionHour,
    },
    {
      name: 'reason',
      type: 'select',
      label: <Trans>Motivazione</Trans>,
      validation: { required: true },
      md: 6,
      options: optionVDT,
    },
    {
      name: 'Note',
      type: 'text',
      label: <Trans>Note</Trans>,
      validation: { required: false },
      md: 6,
    },
    {
      name: 'Table',
      type: 'custom',
      md: 12,
      render: () => {
        return (
          <>
            <DataTable
              keyField="id"
              sortby="fascia"
              width="100%"
              headers={header}
              data={rupdata || []}
              noData={<Trans>Nessuna fascia inserita</Trans>}
              rowButtons={el => (
                <>
                  <Link to={{ pathname: editUrl, state: { selected: el } }}><IconButton icon="pencil-alt" color="#c00" /></Link>
                  <IconButton icon="copy" color="#c00" onClick={() => duplicateRUP(el.id)} />
                  <IconButton icon="trash" color="#c00" onClick={() => selectedRUP(el.id)} />
                </>
              )}
            />
            <div>
              <Link to={{ pathname: rupUrl }}><StyledButtonNew color="secondary">Aggiungi fascia</StyledButtonNew>
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Card>
        <CardHeader>
          <Trans>Modifica VDT</Trans>
        </CardHeader>
        <CardBody>
          <DynamicForm
            title={<Trans>Inserisci nuova fascia</Trans>}
            fields={fields}
            hideButtons={false}
            initialValues={initialValue}
            submitButtonText="Salva e invia a Terna"
            onSubmit={handleSend}
            onCancel={history.goBack}
          />
        </CardBody>
        <Modal isOpen={isDeleted != null} toggle={hideDelete}>
          <ModalHeader toggle={hideDelete}><Trans>Conferma rimozione fascia</Trans></ModalHeader>
          <ModalBody>
            <Trans>Desideri confermare la rimozione della fascia selezionata?</Trans><br /><br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => deleteRUP(selectedrup)}><Trans>Elimina</Trans></Button>
            <Button color="secondary" onClick={hideDelete}>Annulla</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={!isCompiled} toggle={hideCompiled}>
          <ModalHeader toggle={hideCompiled}><Trans>Compilazione fascie</Trans></ModalHeader>
          <ModalBody>
            <Trans>Compilare almeno una fascia!</Trans><br />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={hideCompiled}>Chiudi</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={dateTerna != null} toggle={hideSendTerna}>
          <ModalHeader toggle={hideSendTerna}><Trans>Conferma invio baseline</Trans></ModalHeader>
          <ModalBody>
            <Trans>Desideri confermare l'invio a Terna della baseline selezionata?</Trans><br /><br />
            UVAM: <strong>{selectedPdo && selectedPdo.code}</strong><br />
            <Trans>Giorno di dispacciamento</Trans>: <strong>{dateTerna && dateTerna.format('dddd DD/MM/YYYY')}</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleSend()}><Trans>Invia</Trans></Button>
            <Button color="secondary" onClick={hideSendTerna}>Annulla</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorButton} toggle={() => setErrorButton(false)}>
          <ModalHeader toggle={() => setErrorButton(false)}><Trans>Errore compilazione VDT</Trans></ModalHeader>
          <ModalBody>
            <Trans><strong>Orario di fine comunicazione antecedente all'inzio </strong></Trans>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setErrorButton(false)}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </Card>
      <br />
    </div>
  );
};

EditVdt.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getRUP: PropTypes.func.isRequired,
  patchRUP: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  rupdata: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return { user, language, path, type, id, selectedPdo, selectedOdm, rupdata };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: obj => dispatch(getRUP(obj)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditVdt);
