import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Switch, Select } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import TreeView from './TreeView';


const Container = styled.div`
  background-color: #fff;
  min-height: 30rem;
  position: relative;
`;

const FilterContainer = styled.div`
  background-color: ${props => props.theme.common.background.secondaryColor};
  padding: 1rem;
  border-radius: 5px;
  width: 20rem; 

  position: absolute;
  right: 0;
  top: 0;
  opacity: .8;
  transition: all .2s;

  &:hover {
    opacity: 1;
  }
`;

const Field = styled.div`
  margin: 1rem 0;
`;


const EmptyLabel = styled.p`
  padding: .2rem;
  margin-left: 1.5rem;
  color: ${props => props.theme.common.text.secondaryColor};
`;

const options = {
  keyProperty: 'name',
};

class OpSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      showAssets: false,
      groupedLeaves: {},
      structure: [],
      checkedNodes: props.checkedNodes || [],
      openNodes: props.openNodes || [],
    };
  }


  componentDidMount() {
    this.createTreeStructure();
    this.filter('');
  }

  componentDidUpdate(prevProps) {
    const { observedProperties, selectedAsset, selectedSite, selectedCompany, checkedNodes, openNodes } = this.props;
    if (selectedSite !== prevProps.selectedSite || selectedCompany !== prevProps.selectedCompany || selectedAsset !== prevProps.selectedAsset) {
      this.createTreeStructure();
    }
    if (checkedNodes !== prevProps.checkedNodes || openNodes !== prevProps.openNodes) {
      this.setState({ checkedNodes, openNodes });
    }
    if (prevProps.observedProperties !== observedProperties) {
      this.filter('');
    }
  }


  getCheckedNodes = () => this.state.checkedNodes;

  getOpenNodes = () => this.state.openNodes;

  createAssetNode = (asset) => {
    const { id, name } = asset;
    const node = { id, name, type: 'asset', item: asset };
    return node;
  }

  createSiteNode = (site) => {
    const { id, name } = site;
    this.sites[id] = { siteName: name.toLowerCase(), companyId: site.companyId };
    const node = { id, name, type: 'site', item: site };
    return node;
  };

  createCompanyNode = (company) => {
    const { id, name, sites } = company;
    this.companies[id] = { companyName: name.toLowerCase() };
    const node = { id, name, type: 'company', item: company };
    const children = [];
    if (sites) {
      sites.forEach(site => children.push(this.createSiteNode(site)));
      node.idList = sites.map(x => x.id);
    }
    node.children = children;
    return node;
  };

  createTreeStructure = () => {
    this.companies = {};
    this.sites = {};
    const { companies, selectedAsset, selectedSite, selectedCompany } = this.props;
    const data = [];
    if (selectedSite) {
      const s = this.createSiteNode(selectedSite);
      data.push(s);
      if (selectedAsset) {
        this.setState({ showAssets: true });
        this.open(s, true);
        this.open({ type: 'asset', id: selectedAsset.id }, true);
      }
    } else if (selectedCompany) {
      const c = this.createCompanyNode(selectedCompany);
      data.push(...c.children);
    } else {
      companies.forEach(company => data.push(this.createCompanyNode(company)));
    }
    this.setState({ structure: data });
  }

  toggle = (node, checked) => {
    const { maxChecked, onMaxChecked, onCheck } = this.props;
    const { checkedNodes } = this.state;
    const { type, id, item } = node;

    if (maxChecked === 1) {
      this.setState({ checkedNodes: [{ type, id }] });
      if (onCheck) {
        onCheck({ type, id, item }, true);
      }
      return true;
    }

    if (checked) {
      if (maxChecked && checkedNodes.length >= maxChecked) {
        if (onMaxChecked) {
          onMaxChecked();
        }
        return null;
      }
      if (!checkedNodes.some(x => x.type === type && x.id === id)) {
        this.setState({ checkedNodes: checkedNodes.concat({ type, id }) });
      }
    } else {
      this.setState({ checkedNodes: checkedNodes.filter(x => x.type !== type || x.id !== id) });
    }

    if (onCheck) {
      onCheck({ type, id, item }, checked);
    }
    return checked;
  };

  open = (node, open) => {
    const { type, id, item } = node;
    const { openNodes } = this.state;
    if (open) {
      if (!openNodes.some(x => x.type === type && x.id === id)) {
        this.setState(prevState => ({ openNodes: prevState.openNodes.concat({ type, id }) }));
      }
    } else {
      this.setState(prevState => ({ openNodes: prevState.openNodes.filter(x => x.type !== type || x.id !== id) }));
    }

    const { onOpen } = this.props;
    if (onOpen) {
      onOpen({ type, id, item }, open);
    }
  };


  filter = (filterText, physicalQuantity) => {
    const { observedProperties, virtualMeters } = this.props;
    const lowerCaseFilterText = filterText.toLowerCase();
    let nLeaves = 0;
    const groupedLeaves = observedProperties.concat(virtualMeters).reduce((groups, leaf) => {
      let parentVisible = false;
      if (leaf.siteId) {
        const { siteName, companyId } = this.sites[leaf.siteId] || {};
        let companyName;
        if (companyId) {
          ({ companyName } = this.companies[companyId] || {});
        }
        parentVisible = siteName !== undefined && siteName.includes(lowerCaseFilterText) || companyName !== undefined && companyName.includes(lowerCaseFilterText);
      }
      if (!leaf.hidden && (!physicalQuantity || physicalQuantity === leaf.physicalQuantity) && (leaf.name.toLowerCase().includes(lowerCaseFilterText) || parentVisible)) {
        nLeaves += 1;
        if (!groups[leaf.siteId]) {
          groups[leaf.siteId] = [];
        }
        groups[leaf.siteId].push({ id: leaf.id, item: leaf, type: leaf.formula ? 'vm' : 'op' });
      }
      return groups;
    }, {});
    this.setState({ filterText, physicalQuantity, groupedLeaves, nLeaves });
  }

  render() {
    const { physicalQuantities, selectedAsset } = this.props;
    const { filterText, showAssets, structure, groupedLeaves, physicalQuantity, nLeaves, openNodes, checkedNodes } = this.state;

    return (
      <Container>
        {nLeaves > 0
          ? <TreeView options={options} structure={structure} leaves={groupedLeaves} showAssets={showAssets} nLeaves={nLeaves} toggle={this.toggle} openNodes={openNodes} open={this.open} checkedNodes={checkedNodes} selectedAsset={selectedAsset} filterText={filterText} />
          : <EmptyLabel>Nessun risultato trovato</EmptyLabel>}
        <FilterContainer>
          <h6>Filtri</h6>
          <Field>
            <Input value={filterText} onChange={e => this.filter(e.target.value, physicalQuantity)} placeholder={i18n._(t`Cerca per nome`)} />
          </Field>
          <Field>
            <Select nullable canSearch placeholder={i18n._(t`Grandezza`)} value={physicalQuantity} onChange={e => this.filter(filterText, e.target.value)} options={physicalQuantities} valueProperty="naturalKey" labelProperty="_label" />
          </Field>
          <Field>
            <Switch checked={showAssets} disabled={selectedAsset !== null} onChange={e => this.setState({ showAssets: e.target.checked })} label={<Trans>Mostra gli asset</Trans>} />
          </Field>
        </FilterContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { companies, selectedCompany, selectedSite, selectedAsset } = state.navigation;
  const { observableproperties: observedProperties, virtualmeters: virtualMeters } = state.domain;
  const { physicalQuantities } = state.catalogs;
  return { companies, selectedCompany, selectedSite, selectedAsset, observedProperties, physicalQuantities, virtualMeters };
};

export default connect(mapStateToProps)(OpSelector);
