import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getUrl } from 'app/utils/navigation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardBody } from 'reactstrap';
import { Button, DataTable, IconButton } from 'app/common';
import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { copyRUP, getRUP, patchRUP } from 'ducks/actions/trading';
import DynamicForm from '../../../common/DynamicForm';
import { optionHour, optionVDT } from './constant';
import i18n from '../../../i18n';


const StyledButtonNew = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;


const ViewVDT = ({ selectedPdo, selectedOdm, language, startLoader, stopLoader, user, type, id, rupdata, patchRUP, history, location, norupdata }) => {
  const [data, setData] = useState(location.state.selected);
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const init = () => {
    const options = optionHour;
    options.forEach((i, index) => {
      if (i.value == location.state.selected[0].startDate.substr(11,5)) {
        setStartIndex(index - 1);
      }
      if (i.value == location.state.selected[0].endDate.substr(11,5)) {
        setEndIndex(index - 1);
      }
    });
  };
  useEffect( () => init(), [data]);


  const fields = [
    {
      name: 'startDate',
      type: 'date',
      label: <Trans>Data inizio</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: false,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
        disabled: true,
      },
    },
    {
      name: 'startHour',
      type: 'select',
      label: <Trans>Orario inizio</Trans>,
      validation: { required: true },
      md: 3,
      options: optionHour,
      disabled: true,
    },
    {
      name: 'endDate',
      type: 'date',
      label: <Trans>Data fine</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: true,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
        disabled: true,
      },
    },
    {
      name: 'endHour',
      type: 'select',
      label: <Trans>Orario fine</Trans>,
      validation: { required: true, },
      md: 3,
      options: optionHour,
      disabled: true,
    },
    {
      name: 'reason',
      type: 'select',
      label: <Trans>Motivazione</Trans>,
      validation: { required: true },
      md: 6,
      options: optionVDT,
      disabled: true,
    },
    {
      name: 'Note',
      type: 'text',
      label: <Trans>Note</Trans>,
      validation: { required: false },
      md: 6,
      disabled: true,
    },
  ];

  // const getQuarter = () => {
  //   const todayhour = moment().hour() * 4;
  //   const todayminutes = moment().minutes();
  //   const quarter = Math.floor(todayminutes / 15) + 3;
  //   const index = quarter + todayhour;
  //   return index;
  // };
  // Cancellato momentaneamente perchè sostituito da 14-18 come intervallo di tempo di default
  return (
    <div>
      <Card>
        <CardHeader>
          <Trans>Visualizza VDT</Trans>
        </CardHeader>
        <CardBody>
          <DynamicForm
            title={<Trans>Inserisci nuova fascia</Trans>}
            fields={fields}
            hideButtons={true}
            initialValues={{ startDate: moment(data[0].startDate, 'DD-MM-YYYY'), endDate: moment(data[0].endDate, 'DD-MM-YYYY'), reason: data[0].reason, startHour: optionHour[startIndex].value, endHour: optionHour[endIndex].value, note: data[0].note }}
            submitButtonText="Chiudi"
            onSubmit={history.goBack}
            onCancel={history.goBack}
          />
          <StyledButtonNew color="primary" onClick={history.goBack}>Chiudi</StyledButtonNew>
        </CardBody>
      </Card>
      <br />
    </div>
  );
};

ViewVDT.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  patchRUP: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  rupdata: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  norupdata: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata, norupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return { user, language, path, type, id, selectedPdo, selectedOdm, rupdata, norupdata };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: obj => dispatch(getRUP(obj)),
  patchRUP: obj => dispatch(patchRUP(obj)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVDT);
