import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getUrl } from 'app/utils/navigation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardBody } from 'reactstrap';
import { Button, DataTable, IconButton } from 'app/common';
import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { copyRUP, getRUP, patchRUP } from 'ducks/actions/trading';
import DynamicForm from '../../../common/DynamicForm';
import { optionHour, optionVDT } from './constant';
import EditRUPZoneModal from './EditRUPZoneModal';
import i18n from '../../../i18n';

const StyledButtonNew = styled(Button)`
  && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const NewVDT = ({
  selectedPdo,
  selectedOdm,
  language,
  startLoader,
  stopLoader,
  user,
  type,
  id,
  staticRupData,
  patchRUP,
  history,
  location,
  norupdata,
  edit,
  readOnly,
}) => {
  console.log('readOnly ', readOnly);
  console.log('edit ', edit);
  const [data, setData] = useState([]);
  const [errorButton, setErrorButton] = useState(false);
  const [isDeleted, setisDeleted] = useState(null);
  const [dateTerna, setDateTerna] = useState({});
  const [selectedrup, setSelectedrup] = useState([]);
  const [isCompiled, setisCompiled] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [rupdata, setRupData] = useState(
    staticRupData && staticRupData.filter((d) => !d.isDeleted)
  );
  const [showEditZone, setShowEditZone] = useState(false);
  const [showSendTernaConfirm, setShowSendTernaConfirm] = useState(false);
  const [selectedZoneId, setSelectedZoneId] = useState();
  const [startDate] = useState(
    location.state && location.state.time && moment(location.state.time, 'DD-MM-YYYY')
  );
  const [endDate] = useState(
    location &&
      location.state &&
      location.state &&
      location.state.time &&
      moment(location.state.time, 'DD-MM-YYYY')
  );
  const [initialValues] = useState(
    location && location.state && location.state.selected
      ? {
          startDate: moment(location.state.selected[0].startDate),
          endDate: moment(location.state.selected[0].endDate),
          startHour: location.state.selected[0].startDate.substr(11, 5),
          endHour: location.state.selected[0].endDate.substr(11, 5),
          reason: location.state.selected[0].reason,
          note: location.state.selected[0].note,
        }
      : {}
  );

  const rupCompiled = rupdata && rupdata.length > 0;

  console.log('NewVDT location.state ', location.state, staticRupData, selectedPdo);

  const getMax = () => {
    const values = rupdata.map((f) => parseFloat(f.pmax));
    return Math.max(...values);
  };

  const hideCompiled = () => {
    console.log('hideCompiled ');
    setShowWarning(false);
  };

  useEffect(() => {
    const isCompiled = rupdata && rupdata.length > 0;
    setisCompiled(isCompiled);
    console.log('useEffect rupData ', rupdata);
  }, [rupdata]);

  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
    }
  }, [selectedPdo]);

  const hideDelete = () => {
    setisDeleted(null);
  };

  const handleSend = async (data) => {
    startLoader();
    const params = {
      vdt: data,
      rupdata,
      odmCompanyIdentifier: selectedOdm.odmCompanyIdentifier,
      action: edit ? 'MODIFICA' : 'INSERISCI',
      ID: edit ? location.state.selected.callid : null,
    };
    if (location.state.selected && location.state.selected.callid) {
      params.oldata = initialValues;
      params.ID = location.state.selected.callid;
    }
    try {
      const res = await customApi.post('/optz/sendTernaVdt', params);
      if (res.data) {
        stopLoader();
        history.goBack();
        toast.success(i18n._(t`Invio avvenuto con successo`), {
          autoClose: 5000,
        });
      }
    } catch (err) {
      stopLoader();
      history.goBack();
      toast.error('Errore di comunicazione con Terna', { autoClose: 5000 });
    }
  };

  const handleSubmit = (data) => {
    const hours = parseInt(data.startHour.substr(0, 2), 10);
    const minutes = parseInt(data.startHour.substr(3, 2), 10);
    const houre = parseInt(data.endHour.substr(0, 2), 10);
    const minutee = parseInt(data.endHour.substr(3, 2), 10);
    data.startDate = data.startDate
      .set({
        h: hours,
        m: minutes,
        s: 0,
      })
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    data.endDate = data.endDate
      .set({
        h: houre,
        m: minutee,
        s: 0,
      })
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    data.agent = user.name;
    data.status = 200;
    data.pdoId = selectedPdo.pdoID;
    data.userId = user.id;
    data.sendDate = moment().toString();
    data.power = getMax();
    setDateTerna(data);
    if (rupCompiled) {
      setShowSendTernaConfirm(true);
    } else {
      setShowWarning(true);
    }
  };

  const hideSendTerna = () => {
    setShowSendTernaConfirm(false);
  };
  const deleteZone = (id) => {
    console.log('delete ', id);
    const newRupData = rupdata
      .filter((d) => d.fascia !== id)
      .sort((a, b) => a.fascia - b.fascia)
      .map((d, index) => ({ ...d, fascia: index + 1 }));
    setRupData(newRupData);
  };
  const checkHour = (value, values) => {
    const start = parseFloat(values.startHour.replace(':', ''));
    const end = parseFloat(value.replace(':', ''));
    if (end < start || values.endDate.isBefore(values.startDate)) {
      return new Error('Selezionare un orario successivo a quello di inizio');
    }
    return true;
  };
  const deleteRUP = (id) => {
    // eslint-disable-next-line eqeqeq
    const obj = rupdata.find((el) => el.id == id);
    obj.isDeleted = true;
    patchRUP(obj);
    setisDeleted(null);
  };

  const fieldCompiled = [
    {
      name: 'reason',
      type: 'select',
      label: <Trans>Motivazione</Trans>,
      validation: { required: true },
      md: 12,
      options: optionVDT,
    },
    {
      name: 'Note',
      type: 'text',
      label: <Trans>Note</Trans>,
      disabled: true,
      validation: { required: false },
      md: 12,
    },
  ];

  const fields = [
    {
      name: 'startDate',
      type: 'date',
      label: <Trans>Data inizio</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: false,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
      },
    },
    {
      name: 'startHour',
      type: 'select',
      label: <Trans>Orario inizio</Trans>,
      validation: { required: true },
      md: 3,
      options: optionHour,
    },
    {
      name: 'endDate',
      type: 'date',
      label: <Trans>Data fine</Trans>,
      validation: { required: true },
      md: 3,
      props: {
        lang: language,
        showTime: true,
        showDateInput: true,
        positiveDate: true,
        defaultCalendarValue: moment().format('DD-MM-YYYY'),
      },
    },
    {
      name: 'endHour',
      type: 'select',
      label: <Trans>Orario fine</Trans>,
      validation: {
        required: true,
        func: (value, values) => checkHour(value, values),
      },
      md: 3,
      options: optionHour,
    },
    {
      name: 'reason',
      type: 'select',
      label: <Trans>Motivazione</Trans>,
      validation: { required: true },
      md: 6,
      options: optionVDT,
    },
    {
      name: 'Note',
      type: 'text',
      label: <Trans>Note</Trans>,
      validation: { required: false },
      md: 6,
    },
    {
      name: 'Table',
      type: 'custom',
      md: 12,
      render: () => (
        <>
          <CardBody>
            <DataTable
              keyField="fascia"
              sortby="fascia"
              width="100%"
              headers={[
                {
                  index: 1,
                  title: <Trans>Fascia</Trans>,
                  property: 'fascia',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 2,
                  title: <Trans>P.min</Trans>,
                  property: 'pmin',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 3,
                  title: <Trans>P.max</Trans>,
                  property: 'pmax',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 4,
                  title: <Trans>Assetto</Trans>,
                  property: 'assetto',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 5,
                  title: <Trans>P.tel.min</Trans>,
                  property: 'ptelmin',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 6,
                  title: <Trans>P.tel.max</Trans>,
                  property: 'ptelmax',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 7,
                  title: <Trans>T.ram</Trans>,
                  property: 'tram',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 8,
                  title: <Trans>T.der</Trans>,
                  property: 'tder',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 9,
                  title: <Trans>T.avv</Trans>,
                  property: 'tavv',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 10,
                  title: <Trans>T.arr</Trans>,
                  property: 'tarr',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 11,
                  title: <Trans>T.ris</Trans>,
                  property: 'tris',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 12,
                  title: <Trans>Grad +</Trans>,
                  property: 'gradp',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 13,
                  title: <Trans>Grad -</Trans>,
                  property: 'gradm',
                  dataType: 'string',
                  canSearch: true,
                },
                {
                  index: 14,
                  title: <Trans>BRS</Trans>,
                  property: 'brs',
                  dataType: 'string',
                  canSearch: true,
                },
              ]}
              data={rupdata}
              noData={<Trans>Nessuna fascia inserita</Trans>}
              rowButtons={(el) => {
                return !readOnly ? (
                  <>
                    <IconButton
                      icon="pencil-alt"
                      color="#c00"
                      onClick={() => {
                        setShowEditZone(true);
                        setSelectedZoneId(el.fascia);
                      }}
                    />
                    <IconButton icon="trash" color="#c00" onClick={() => deleteZone(el.fascia)} />
                  </>
                ) : null;
              }}
            />
          </CardBody>
          <Modal isOpen={isDeleted != null} toggle={hideDelete}>
            <ModalHeader toggle={hideDelete}>
              <Trans>Conferma rimozione fascia</Trans>
            </ModalHeader>
            <ModalBody>
              <Trans>Desideri confermare la rimozione della fascia selezionata?</Trans>
              <br />
              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => deleteRUP(selectedrup)}>
                <Trans>Elimina</Trans>
              </Button>
              <Button color="secondary" onClick={hideDelete}>
                Annulla
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showWarning} toggle={hideCompiled}>
            <ModalHeader toggle={hideCompiled}>
              <Trans>Compilazione fasce</Trans>
            </ModalHeader>
            <ModalBody>
              <Trans>Compilare almeno una fascia!</Trans>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={hideCompiled}>
                Chiudi
              </Button>
            </ModalFooter>
          </Modal>
          <div>
            {!readOnly && (
              <StyledButtonNew
                color="secondary"
                onClick={() => {
                  setShowEditZone(true);
                  setSelectedZoneId(0);
                }}
              >
                Aggiungi fascia
              </StyledButtonNew>
            )}
          </div>
        </>
      ),
    },
  ];

  // const getQuarter = () => {
  //   const todayhour = moment().hour() * 4;
  //   const todayminutes = moment().minutes();
  //   const quarter = Math.floor(todayminutes / 15) + 3;
  //   const index = quarter + todayhour;
  //   return index;
  // };
  // Cancellato momentaneamente perchè sostituito da 14-18 come intervallo di tempo di default
  return (
    <div>
      <Card>
        <CardHeader>
          {readOnly ? (
            <Trans>VDT</Trans>
          ) : edit ? (
            <Trans>Modifica VDT</Trans>
          ) : (
            <Trans>Nuova VDT</Trans>
          )}
        </CardHeader>
        <CardBody>
          <DynamicForm
            title={<Trans>Inserisci nuova fascia</Trans>}
            readOnly={readOnly}
            fields={fields}
            hideButtons={false}
            initialValues={
              edit
                ? initialValues
                : {
                    startDate,
                    endDate,
                    reason: optionVDT[10].value,
                    startHour: optionHour[56].value,
                    endHour: optionHour[72].value,
                  }
            }
            submitButtonText="Salva e invia a Terna"
            onSubmit={handleSubmit}
            onCancel={history.goBack}
          />
        </CardBody>
        <EditRUPZoneModal
          pdo={selectedPdo && selectedPdo.code}
          onSubmit={setRupData}
          rupdata={rupdata}
          isOpen={showEditZone}
          zoneId={selectedZoneId}
          toggle={() => setShowEditZone(false)}
        />
        <Modal isOpen={isDeleted != null} toggle={hideDelete}>
          <ModalHeader toggle={hideDelete}>
            <Trans>Conferma rimozione fascia</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>Desideri confermare la rimozione della fascia selezionata?</Trans>
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => deleteRUP(selectedrup)}>
              <Trans>Elimina</Trans>
            </Button>
            <Button color="secondary" onClick={hideDelete}>
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showWarning} toggle={hideCompiled}>
          <ModalHeader toggle={hideCompiled}>
            <Trans>Compilazione fasce</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>Compilare almeno una fascia!</Trans>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={hideCompiled}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showSendTernaConfirm} toggle={hideSendTerna}>
          <ModalHeader toggle={hideSendTerna}>
            <Trans>Conferma invio VDT</Trans>
          </ModalHeader>
          <ModalBody>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Trans>Desideri confermare l'invio a Terna della VDT selezionata?</Trans>
            <br />
            <br />
            UVAM: <strong>{selectedPdo && selectedPdo.code}</strong>
            <br />
            <Trans>Intervallo di validità</Trans>:{' '}
            <strong>
              {dateTerna && dateTerna.startDate
                ? moment(dateTerna.startDate).format('DD-MM-YYYY HH:mm') +
                  ' - ' +
                  moment(dateTerna.endDate).format('DD-MM-YYYY HH:mm')
                : ''}
            </strong>
            <DynamicForm
              title={<Trans>Inserisci nuova fascia</Trans>}
              fields={fieldCompiled}
              hideButtons
              initialValues={{
                reason: dateTerna && dateTerna.reason ? dateTerna.reason : '',
                Note: dateTerna && dateTerna.Note ? dateTerna.Note : '',
              }}
              submitButtonText="Invia"
              onSubmit={handleSubmit}
              onCancel={hideSendTerna}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleSend(dateTerna)}>
              <Trans>Invia</Trans>
            </Button>
            <Button color="secondary" onClick={hideSendTerna}>
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorButton} toggle={() => setErrorButton(false)}>
          <ModalHeader toggle={() => setErrorButton(false)}>
            <Trans>Errore compilazione VDT</Trans>
          </ModalHeader>
          <ModalBody>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Trans>
              <strong>Orario di fine comunicazione antecedente all'inzio </strong>
            </Trans>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setErrorButton(false)}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
      <br />
    </div>
  );
};

NewVDT.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  selectedOdm: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  patchRUP: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  rupdata: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  norupdata: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { rupdata: staticRupData, norupdata } = state.trading;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  return {
    user,
    language,
    path,
    type,
    id,
    selectedPdo,
    selectedOdm,
    staticRupData,
    norupdata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: (obj) => dispatch(getRUP(obj)),
  patchRUP: (obj) => dispatch(patchRUP(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewVDT);
