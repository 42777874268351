
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { DynamicForm } from 'app/common';

const Container = styled.div`
  width: 100%;
`;

const DayMonthSelect = (props) => {

  const yearReference = 2019; // anno non bisestile

  const onGetDayMonth = props.onGetDayMonth;

  const getDaysArray = (month, year) => {
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, 1);
    const result = [];
    let index = 1;
    while (date.getMonth() == monthIndex) {
      result.push( { value: (index<10) ? '0'+index : ''+index, label: ''+date.getDate() } );
      date.setDate(date.getDate() + 1);
      index++;
    }
    return result;
  }

  const getDefaultDay = () => {
    const valueSplit = (typeof props.value === 'string') ? props.value.split('-') : [];
    return (valueSplit && valueSplit.length > 1) ? valueSplit[0] : '01';
  }

  const getDefaultMonth = () => {
    const valueSplit = (typeof props.value === 'string') ? props.value.split('-') : [];
    return (valueSplit && valueSplit.length > 1) ? valueSplit[1] : '01';
  }

  // The two values
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  // Options lists
  const [daysOptions, setDaysOptions] = useState();
  const [monthOptions, setMonthOptions] = useState();
  // Default values
  const [defaultDay, setDefaultDay] = useState( getDefaultDay() );
  const [defaultMonth, setDefaultMonth] = useState( getDefaultMonth() );

  const [error, setError] = useState();

  const getDaysOptions = (currentMonth) => {
    const daysList = getDaysArray(currentMonth, yearReference);
    return daysList.map((d) => {
      return { label: d.label, value: d.value }
    });
  }

  const getMonthsOptions = () => {
    return moment.months().map((m, index) => {
      const monthLabel = m.charAt(0).toUpperCase() + m.slice(1);
      const incrementedIndex = index + 1;
      const monthValue = (incrementedIndex < 10) ? '0'+incrementedIndex : ''+incrementedIndex;
      return { label: monthLabel, value: monthValue };
    });
  }

  const sendValues = (hiddenInputValue) => {
    if (onGetDayMonth) {
      onGetDayMonth(hiddenInputValue);
    }
  }

  useEffect(() => {

    // Split input value and set day - month
    const valueSplit = (typeof props.value === 'string') ? props.value.split('-') : [];
    if (valueSplit && valueSplit.length > 1) {
      setDay(valueSplit[0]);
      setMonth(valueSplit[1]);

      // Set option lists
      setDaysOptions( getDaysOptions(valueSplit[1]) );
      setMonthOptions( getMonthsOptions() );

    } else {
      // Not valid or no default value, set 1 January
      setDay('01');
      setMonth('01');

      // Set option lists
      setDaysOptions( getDaysOptions('01') );
      setMonthOptions( getMonthsOptions() );
    }

  }, []);

  return (
    <Container>
      <DynamicForm
        hideButtons
        hideErrors
        fields={[
          {
            name: 'day',
            type: 'select',
            label: <Trans>Giorno</Trans>,
            hideLabel: true,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            validation: { required: true },
            nullable: false,
            canSearch: false,
            disabled: props.disabled,
            onChange: (value, formValues) => {
              setDefaultDay(value);

              sendValues(value+'-'+month);
            },
            options: (daysOptions) ? daysOptions : [],
          },
          {
            name: 'month',
            type: 'select',
            label: <Trans>Mese</Trans>,
            hideLabel: true,
            xs: 10,
            sm: 10,
            md: 10,
            lg: 10,
            validation: { required: true },
            nullable: false,
            canSearch: false,
            disabled: props.disabled,
            onChange: (value, formValues) => {
              const currentDay = formValues.day;
              setMonth(value);

              // Update days options list
              setDaysOptions( getDaysOptions(value) );

              // Change select month value
              setDefaultMonth(value);

              // If current value is not a valid date, set day to 1
              const currentDate = moment(currentDay+'/'+value+'/'+yearReference, 'DD/MM/YYYY');
              if ( currentDate.isValid() ) {
                // Send updated month
                setDay(currentDay);
                sendValues(currentDay+'-'+value);

                // Override form day value
                // formValues.day = currentDay;
              } else {
                setDay('01');
                sendValues('01-'+value);

                // Override form day value
                formValues.day = '01';
              }

            },
            options: (monthOptions) ? monthOptions : [],
          },
        ]}
        initialValues={{
          day: (day) ? day : defaultDay,
          month: (month) ? month : defaultMonth,
        }}
        error={error}
        readOnly={false}
        hideRequiredLabel={true}
      />
    </Container>
  );
}

DayMonthSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.string,
  onGetDayMonth: PropTypes.func,
};

export default DayMonthSelect;
