/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable brace-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { api, customApi } from 'api';
import styled from 'styled-components';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Button, Switch, HappyAlert } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faHandPointRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getErrorMessages } from 'app/utils/validation';
import { getUrl } from 'app/utils/navigation';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getBiddingQA, saveUserBidding, setBiddingSettings, saveOptimizedQp } from 'ducks/actions/trading';
import { getActiveHours, getNextSession } from '../tradingCommon/tradingUtils';
import getErrorMsg from '../tradingCommon/tradingErrorUtil';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const SwitchContainer = styled.div`
 && {
   float: right;
  }

  && .custom-switch {
    display: inline;
    padding-left: 60px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const StyledButtonSpread = styled(Button)`
 && {
    margin-left: 25px;
    margin-bottom: 5px;
    position: sticky;
    text-transform: unset;
  }

  && span {
     margin-left: 7px;
   }
`;

const StyledButtonOptimizedQP = styled(Button)`
 && {
    left: 170px;
    top: -2px;
    height: 23px;
    padding: 0px 8px 0px 8px;
    position: sticky;
  }

  && span {
     margin-left: 7px;
   }
`;

const StyledButtonSendBid = styled(Button)`
 && {
    left: 360px;
    height: 23px;
    padding: 3px 8px 0px 8px;
    position: sticky;
  }

  && span {
     margin-left: 7px;
   }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        data: action.payload.data,
        oldData: action.payload.oldData,
        pricesData: action.payload.pricesData,
        bidDetailData: action.payload.bidDetailData,
        lastRequest: action.payload.lastRequest,
        spread: action.payload.spread,
        editSpread: action.payload.editSpread,
      };
    case 'data':
      return { ...state, data: action.payload };
    case 'oldData':
      return { ...state, oldData: action.payload };
    case 'pricesData':
      return { ...state, pricesData: action.payload };
    case 'pricesDataAndShowPrice': {
      sessionStorage.setItem('showPrice', action.payload.showPrice);
      return { ...state, pricesData: action.payload.pricesData, showPrice: action.payload.showPrice };
    }
    case 'bidDetailData':
      return { ...state, bidDetailData: action.payload };
    case 'bidDetailDataAndShowBidDetail': {
      sessionStorage.setItem('showBidDetail', action.payload.showBidDetail);
      return { ...state, bidDetailData: action.payload.bidDetailData, showBidDetail: action.payload.showBidDetail };
    }
    case 'lastRequest':
      return { ...state, lastRequest: action.payload };
    case 'spread':
      return { ...state, spread: action.payload };
    case 'editSpread':
      return { ...state, editSpread: action.payload };
    default:
      throw new Error();
  }
};

const Bidding = (props) => {
  const { startLoader, stopLoader, receiveBiddingQaSuccess, biddingFeSetting, error, language, type, id, selectedPdo, trading, getBiddingQA, saveUserBidding, setBiddingSettings, saveOptimizedQp } = props;

  const initialState = {
    data: null,
    oldData: null,
    pricesData: [],
    bidDetailData: [],
    lastRequest: [],

    showPrice: sessionStorage.getItem('showPrice') ? sessionStorage.getItem('showPrice') == 'true' : false,
    showBidDetail: sessionStorage.getItem('showBidDetail') ? sessionStorage.getItem('showBidDetail') == 'true' : false,

    spread: null,
    editSpread: null,
  };

  const { startDate, endDate } = biddingFeSetting;

  const [state, dispatch] = useReducer(reducer, initialState);

  const [alertMessage, setAlertMessage] = useState(null);
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [showSpreadDialog, setShowSpreadDialog] = useState(false);

  const toggleSpreadDialog = () => {
    dispatch({ type: 'editSpread', payload: state.spread });
    setShowSpreadDialog(!showSpreadDialog);
  };

  const saveSpread = () => {
    if (state.editSpread && (state.editSpread.value < 0 || state.editSpread.value > 100)) {
      toast.error(i18n._(t`Il valore dello Spread deve essere compreso tra 0 e 100.`), { autoClose: 5000 });
      return;
    }
    api.patch(`/Spreads/${state.editSpread.id}`, state.editSpread).then((res) => {
      dispatch({ type: 'spread', payload: state.editSpread });
      toggleSpreadDialog();
    }).catch((err) => {
      const { errorMessage } = getErrorMessages(err);
      toast.error(errorMessage, { autoClose: 5000 });
    });
  };

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlertMsg(true);
  };

  const previousDay = () => {
    setBiddingSettings({ ...biddingFeSetting,
      startDate: moment(startDate.toObject()).subtract(1, 'day'),
      endDate: moment(endDate.toObject()).subtract(1, 'day') });
  };

  const nextDay = () => {
    setBiddingSettings({ ...biddingFeSetting,
      startDate: moment(startDate.toObject()).add(1, 'day'),
      endDate: moment(endDate.toObject()).add(1, 'day') });
  };

  useEffect(() => {
    if (error) {
      toast.error(getErrorMsg(error), { autoClose: 5000 });
    }
  }, [error]);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  const initSpread = async () => {
    const res = await api.get('/Spreads');
    return res.data && res.data[0] ? res.data[0] : null;
  };

  const init = async () => {
    if (selectedPdo) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      let params = {
        startDate: start,
        endDate: end,
        pdoID: selectedPdo.pdoID,
        pdoCode: selectedPdo.code,
      };

      const initStatePayload = {};

      if (!_.isEqual(state.lastRequest, params)) {
        initStatePayload.oldData = null;
        initStatePayload.lastRequest = params;

        const promises = [
          initSpread(), // Inizializzazione valore dello Spread
          getBiddingQA(start, end, selectedPdo.pdoID, selectedPdo.code), // Inizializzazione tabella trading
        ];

        params = {
          startDate: start,
          endDate: end,
          pdoID: selectedPdo.pdoID,
          pdoType: selectedPdo.pdoType,
          zone: selectedPdo.zone,
        };

        // Inizializzazione tabella prezzi MGP/MI
        promises.push(doShowPrice(params));

        // Inizializzazione tabella Dettaglio BID
        if (state.showBidDetail) {
          promises.push(doShowBidDetail());
        }

        startLoader();
        const response = await Promise.all(promises);

        if (response) {
          const spread = response[0];
          initStatePayload.spread = spread;
          initStatePayload.editSpread = spread;

          const status = response[1];

          const pricesData = response[2];
          if (pricesData) {
            initStatePayload.pricesData = pricesData;
          }

          const bidDetailData = response[3];
          if (bidDetailData) {
            initStatePayload.bidDetailData = bidDetailData;
          }
        }

        dispatch({ type: 'init', payload: initStatePayload });

        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  /* logiche ad aggiornamento dello stato REDUX di trading */
  useEffect(() => {
    if (!selectedPdo || !trading.biddingTable) return;

    const nowDate = moment();
    const newData = [];

    dates.forEach((date, index) => {
      const nextSession = getNextSession(date);

      // Prezzi consuntivo ricavati da ERGO
      const prices = state.pricesData && state.pricesData[1] && state.pricesData[1][date.format('YYYY-MM-DD')] ? state.pricesData[1][date.format('YYYY-MM-DD')] : undefined;

      // Forecast ricavati da Wattsight
      const forecastMGP = state.pricesData && state.pricesData[0] && state.pricesData[0].curveMGPForOptz[date.format('YYYY-MM-DD')] ? state.pricesData[0].curveMGPForOptz[date.format('YYYY-MM-DD')] : [];
      const forecastPUN = state.pricesData && state.pricesData[0] && state.pricesData[0].curvePUN[date.format('YYYY-MM-DD')] ? state.pricesData[0].curvePUN[date.format('YYYY-MM-DD')] : [];

      let dataCur;
      let userBidding;
      let optimizedQPDataCur;
      if (trading.biddingTable[date.format('DD/MM/YYYY')] && trading.biddingTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID]) {
        dataCur = trading.biddingTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].be;
        userBidding = trading.biddingTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].userBidding;
        optimizedQPDataCur = trading.biddingTable[date.format('DD/MM/YYYY')][selectedPdo.pdoID].optimizedQP;
      }

      const combileDeltaValue = (i, pos, source) => {
        if (userBidding && userBidding[i] && userBidding[i][pos]) {
          return Number(userBidding[i][pos]).toFixed(2);
        } if (prices && prices[source] && prices[source][i] !== undefined && prices[source][i] !== null) {
          return Number(prices[source][i]).toFixed(2);
        }
        return null;
      };

      /* compongo grid */
      const dataGrid = [];
      for (let i = 0; i < 24; i += 1) {
        const compareDate = moment(date).set('hour', i);
        const rowData = [];
        if (dataCur) {
          rowData.push(dataCur.qa ? Number(dataCur.qa[i]).toFixed(3) : null); // Colonna Qa* (con perdita di rete)
          rowData.push(dataCur.qt ? Number(dataCur.qt[i]).toFixed(3) : null); // Colonna Quantità trading
          rowData.push(dataCur.positions ? Number(dataCur.positions[i]).toFixed(3) : null); // Colonna Posizione
        } else {
          rowData.push(null);
          rowData.push(null);
          rowData.push(null);
        }

        let q1 = 0;
        if (userBidding && userBidding[i] && userBidding[i][3] !== undefined) {
          q1 = (!isNaN(userBidding[i][3]) && userBidding[i][3] !== '') ? Number(userBidding[i][3]).toFixed(3) : userBidding[i][3];
        } else if (dataCur) {
          q1 = dataCur.positions ? Number(dataCur.positions[i]).toFixed(3) : null;
        }

        rowData.push(q1); // Colonna Q1

        let q2 = null;
        if (userBidding && userBidding[i] && userBidding[i][4] !== undefined) {
          q2 = (!isNaN(userBidding[i][4]) && userBidding[i][4] !== '') ? Number(userBidding[i][4]).toFixed(3) : userBidding[i][4];
        } else if (optimizedQPDataCur && optimizedQPDataCur.qa2) {
          q2 = Number(optimizedQPDataCur.qa2[i]).toFixed(3);
        }

        rowData.push(q2); // Colonna Q2

        let q3 = null;
        if (userBidding && userBidding[i] && userBidding[i][5] !== undefined) {
          q3 = (!isNaN(userBidding[i][5]) && userBidding[i][5] !== '') ? Number(userBidding[i][5]).toFixed(3) : userBidding[i][5];
        } else if (optimizedQPDataCur && optimizedQPDataCur.qa3) {
          q3 = Number(optimizedQPDataCur.qa3[i]).toFixed(3);
        }

        rowData.push(q3); // Colonna Q3

        let q4 = null;
        if (userBidding && userBidding[i] && userBidding[i][6] !== undefined) {
          q4 = (!isNaN(userBidding[i][6]) && userBidding[i][6] !== '') ? Number(userBidding[i][6]).toFixed(3) : userBidding[i][6];
        } else if (optimizedQPDataCur && optimizedQPDataCur.qa4) {
          q4 = Number(optimizedQPDataCur.qa4[i]).toFixed(3);
        }

        rowData.push(q4); // Colonna Q4

        let p1 = 0;
        if (userBidding && userBidding[i] && userBidding[i][7] !== undefined) {
          p1 = (!isNaN(userBidding[i][7]) && userBidding[i][7] !== '') ? Number(userBidding[i][7]).toFixed(2) : userBidding[i][7];
        } else if (nextSession === 'MGP') {
          p1 = Number(0).toFixed(2);
        } else if (nextSession) { // MI1, MI2, MI3, MI4, MI5, MI6, MI7
          // Negli altri casi dove nextSession è diverso da null,
          // si somma o si sottrae al prezzo derivante dal consuntivo o dal forecast lo spread
          let priceFromForecast = 0;

          if (prices && prices.mgpPrices[i]) {
            priceFromForecast = prices.mgpPrices[i];
          } else if (forecastMGP[i]) {
            priceFromForecast = forecastMGP[i];
          }
          if (q1 && Number(q1) !== 0) {
            if (q1 && q1 > 0) { // Q1 > 0
              p1 = priceFromForecast + (state.spread ? Number(state.spread.value) : 0);
            } else { // Q1 < 0
              p1 = priceFromForecast - (state.spread ? Number(state.spread.value) : 0);
            }
          } else {
            p1 = priceFromForecast;
          }
          p1 = Number(p1).toFixed(2);
        }

        rowData.push(p1); // Colonna P1

        let p2 = null;
        if (userBidding && userBidding[i] && userBidding[i][8] !== undefined) {
          p2 = (!isNaN(userBidding[i][8]) && userBidding[i][8] !== '') ? Number(userBidding[i][8]).toFixed(2) : userBidding[i][8];
        }
        else if (optimizedQPDataCur && optimizedQPDataCur.p2) {
          p2 = Number(optimizedQPDataCur.p2[i]).toFixed(2);
        }

        rowData.push(p2); // Colonna P2

        let p3 = null;
        if (userBidding && userBidding[i] && userBidding[i][9] !== undefined) {
          p3 = (!isNaN(userBidding[i][9]) && userBidding[i][9] !== '') ? Number(userBidding[i][9]).toFixed(2) : userBidding[i][9];
        }
        else if (optimizedQPDataCur && optimizedQPDataCur.p3) {
          p3 = Number(optimizedQPDataCur.p3[i]).toFixed(2);
        }

        rowData.push(p3); // Colonna P3

        let p4 = null;
        if (userBidding && userBidding[i] && userBidding[i][10] !== undefined) {
          p4 = (!isNaN(userBidding[i][10]) && userBidding[i][10] !== '') ? Number(userBidding[i][10]).toFixed(2) : userBidding[i][10];
        }
        else if (optimizedQPDataCur && optimizedQPDataCur.p4) {
          p4 = Number(optimizedQPDataCur.p4[i]).toFixed(2);
        }

        rowData.push(p4); // Colonna P4

        if (state.showPrice) {
          // I consuntivi recuperati da ERGO hanno priorità sui forecast recuperati da Wattsight
          if (prices && prices.punPrices[i]) {
            rowData.push(Number(prices.punPrices[i]).toFixed(2)); // Colonna PUN
          } else {
            rowData.push(forecastPUN[i] ? Number(forecastPUN[i]).toFixed(2) : null); // Colonna PUN
          }
          if (prices && prices.mgpPrices[i]) {
            rowData.push(Number(prices.mgpPrices[i]).toFixed(2)); // Colonna MGP
          } else {
            rowData.push(forecastMGP[i] ? Number(forecastMGP[i]).toFixed(2) : null); // Colonna MGP
          }

          rowData.push(prices && prices.mi1Prices[i] ? Number(prices.mi1Prices[i]).toFixed(2) : null); // Colonna MI1
          rowData.push(combileDeltaValue(i, 14, 'deltaMI1Prices')); // Colonna ΔMI1
          rowData.push(prices && prices.mi2Prices[i] ? Number(prices.mi2Prices[i]).toFixed(2) : null); // Colonna MI2
          rowData.push(combileDeltaValue(i, 16, 'deltaMI2Prices')); // Colonna ΔMI2
          rowData.push(prices && prices.mi3Prices[i] ? Number(prices.mi3Prices[i]).toFixed(2) : null); // Colonna MI3
          rowData.push(combileDeltaValue(i, 18, 'deltaMI3Prices')); // Colonna ΔMI3
          rowData.push(prices && prices.mi4Prices[i] ? Number(prices.mi4Prices[i]).toFixed(2) : null); // Colonna MI4
          rowData.push(combileDeltaValue(i, 20, 'deltaMI4Prices')); // Colonna ΔMI4
          rowData.push(prices && prices.mi5Prices[i] ? Number(prices.mi5Prices[i]).toFixed(2) : null); // Colonna MI5
          rowData.push(combileDeltaValue(i, 22, 'deltaMI5Prices')); // Colonna ΔMI5
          rowData.push(prices && prices.mi6Prices[i] ? Number(prices.mi6Prices[i]).toFixed(2) : null); // Colonna MI6
          rowData.push(combileDeltaValue(i, 24, 'deltaMI6Prices')); // Colonna ΔMI6
          rowData.push(prices && prices.mi7Prices[i] ? Number(prices.mi7Prices[i]).toFixed(2) : null); // Colonna MI7
          rowData.push(combileDeltaValue(i, 26, 'deltaMI7Prices')); // Colonna ΔMI7
        }

        if (state.showBidDetail && state.bidDetailData) {
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mgpBid[i] ? Number(state.bidDetailData[index].mgpBid[i]).toFixed(3) : null); // Colonna Quantità trading MGP
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi1Bid[i] ? Number(state.bidDetailData[index].mi1Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI1
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi2Bid[i] ? Number(state.bidDetailData[index].mi2Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI2
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi3Bid[i] ? Number(state.bidDetailData[index].mi3Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI3
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi4Bid[i] ? Number(state.bidDetailData[index].mi4Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI4
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi5Bid[i] ? Number(state.bidDetailData[index].mi5Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI5
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi6Bid[i] ? Number(state.bidDetailData[index].mi6Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI6
          rowData.push(state.bidDetailData[index] && state.bidDetailData[index].mi7Bid[i] ? Number(state.bidDetailData[index].mi7Bid[i]).toFixed(3) : null); // Colonna Quantità trading MI7
        }

        dataGrid.push(rowData);
      }
      newData.push(dataGrid);
    });

    dispatch({ type: 'data', payload: newData });

    if (state.oldData == null && receiveBiddingQaSuccess[selectedPdo.pdoID]) {
      dispatch({ type: 'oldData', payload: JSON.parse(JSON.stringify(newData)) });
    }
  }, [selectedPdo, trading, state.pricesData, state.bidDetailData]);

  const getOptimizedQP = async (date) => {
    if (selectedPdo) {
      // StartDate e EndDate sono quelle relative al singolo giorno richiesto
      const start = moment(date).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(date).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      const params = {
        startDate: start,
        endDate: end,
        pdoID: selectedPdo.pdoID,
      };

      startLoader();

      await customApi.post('/optz/getOptimizedQP', params).then((res) => {
        if (res.data) {
          saveOptimizedQp(selectedPdo.pdoID, res.data);
        }
      }).catch((err) => {
        const { errorMessage } = getErrorMessages(err);
        toast.error(errorMessage, { autoClose: 5000 });
      });

      stopLoader();
    }
  };

  const disabledStartDate = (startDate) => {
    if (!startDate || !endDate) return false;
    // Viene lasciata la possibilità di muoversi senza limite sulla data di start,
    // ed in automatica viene aggioranta la data di end
    return endDate.diff(startDate, 'days') <= 0;
    // return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      let newEndDate = endDate;
      if (endDate.diff(value, 'days') >= 9) {
        newEndDate = moment(value).add(endDate.diff(startDate, 'days'), 'days');
      }
      setBiddingSettings({ ...biddingFeSetting, startDate: value, endDate: newEndDate });
    } else if (field === 'endDate') {
      setBiddingSettings({ ...biddingFeSetting, endDate: value });
    }
  };

  const handleChangeShowPrice = async () => {
    if (!state.showPrice) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      const params = {
        startDate: start,
        endDate: end,
        pdoID: selectedPdo.pdoID,
        pdoType: selectedPdo.pdoType,
        zone: selectedPdo.zone,
      };

      startLoader();

      const pricesData = await doShowPrice(params);
      if (pricesData) {
        dispatch({ type: 'pricesDataAndShowPrice', payload: { pricesData, showPrice: !state.showPrice } });
      } else {
        dispatch({ type: 'pricesDataAndShowPrice', payload: { pricesData: [], showPrice: !state.showPrice } });
      }

      stopLoader();
    } else {
      dispatch({ type: 'pricesDataAndShowPrice', payload: { pricesData: [], showPrice: !state.showPrice } });
    }
  };

  const doShowPrice = async (params) => {
    try {
      const res = await customApi.post('/optz/getPrices', params);
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      const { errorMessage } = getErrorMessages(err);
      toast.error(errorMessage, { autoClose: 5000 });
    }
    return null;
  };

  const handleChangeShowBidDetail = async () => {
    if (!state.showBidDetail) {
      startLoader();

      const bidDetailData = await doShowBidDetail();
      if (bidDetailData) {
        dispatch({ type: 'bidDetailDataAndShowBidDetail', payload: { bidDetailData, showBidDetail: !state.showBidDetail } });
      } else {
        dispatch({ type: 'bidDetailDataAndShowBidDetail', payload: { bidDetailData: [], showBidDetail: !state.showBidDetail } });
      }

      stopLoader();
    } else {
      dispatch({ type: 'bidDetailDataAndShowBidDetail', payload: { bidDetailData: [], showBidDetail: !state.showBidDetail } });
    }
  };

  const doShowBidDetail = async () => {
    try {
      const res = await getBidDetail();
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      const { errorMessage } = getErrorMessages(err);
      toast.error(errorMessage, { autoClose: 5000 });
    }
    return null;
  };

  const getBidDetail = async () => {
    const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
    const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

    const params = {
      startDate: start,
      endDate: end,
      pdoCode: selectedPdo.code,
    };

    return customApi.post('/optz/getBidDetail', params);
  };

  const handleCellsChanged = async (date, arrayOfChanges) => {
    let changes = arrayOfChanges;

    // eslint-disable-next-line no-restricted-syntax
    for (const { cell, row, col, value } of arrayOfChanges) {

      const coulumnNames = {
        3: 'Q1',
        4: 'Q2',
        5: 'Q3',
        6: 'Q4',
        7: 'P1',
        8: 'P2',
        9: 'P3',
        10: 'P4',
      };

      // Check sui prezzi
      if ([7, 8, 9, 10].indexOf(col) != -1) {
        if (!isNaN(value) && (Number(value) < 0 || Number(value) > 3000)) {
          const detail = { colonna: coulumnNames[col], ora: row };
          toast.error(i18n._(t`Errore nella colonna ${detail.colonna} (blocco orario n° ${detail.ora}): Prezzo unitario non valido (non compreso tra 0 e 3000 €/MWh).`), { autoClose: 5000 });
          changes = [];
        }
      }

      // Errori di validazione applicabili nel caso di una UP (PdO MGP/MI in “Immissione”)
      if (selectedPdo.pdoType === 'UP') {
        if ([3, 4, 5, 6].indexOf(col) != -1) {
          // Check Q_sell_max = Σj W_gridj / 1000 * Δt
          let W_gridj = 0;
          selectedPdo.pdoSites.forEach((pdoSite) => {
            W_gridj += pdoSite.maxWinj;
          });
          const deltaT = 1; // TODO Δt=1 ora (trattandosi di valori orari). ?????
          const Q_sell_max = W_gridj / 1000 * deltaT;
          if (!isNaN(value) && Number(value) < -(Q_sell_max)) {
            const detail = { colonna: coulumnNames[col], ora: row, Wmax: Q_sell_max };
            toast.error(i18n._(t`Errore nella colonna ${detail.colonna} (blocco orario n° ${detail.ora}): La quantità inserita in vendita è superiore alla quantità massima di energia cedibile in rete da parte del PdO: ${detail.Wmax} MWh.`), { autoClose: 5000 });
            changes = [];
          }

          /* - La quantità inserita in acquisto è superiore alla quantità già accettata in vendita nelle sedute MGP/MI precedenti
               (relativamente alla medesima ora di dispacciamento).
               In altre parole, la UP può acquistare energia, ma non più di quanta non ne abbia già venduta (per la stessa ora) nelle sessioni precedenti.
          */
          if (!isNaN(value) && Number(value) > 0) {
            try {
              const res = await getBidDetail();
              if (res.data && res.data instanceof Array) {
                /* filtra tutte le quantità accettate per la data/ora attuale */
                let quantitySell = 0;
                const dateRef = date.format('YYYY-MM-DD');
                const dateFinded = res.data.find(f => f.date === dateRef);
                if (dateFinded) {
                  const hour = row - 1;

                  const mgpBid = dateFinded.mgpBid[hour];
                  if (mgpBid) quantitySell += mgpBid;

                  const mi1Bid = dateFinded.mi1Bid[hour];
                  if (mi1Bid) quantitySell += mi1Bid;

                  const mi2Bid = dateFinded.mi2Bid[hour];
                  if (mi2Bid) quantitySell += mi2Bid;

                  const mi3Bid = dateFinded.mi3Bid[hour];
                  if (mi3Bid) quantitySell += mi3Bid;

                  const mi4Bid = dateFinded.mi4Bid[hour];
                  if (mi4Bid) quantitySell += mi4Bid;

                  const mi5Bid = dateFinded.mi5Bid[hour];
                  if (mi5Bid) quantitySell += mi5Bid;

                  const mi6Bid = dateFinded.mi6Bid[hour];
                  if (mi6Bid) quantitySell += mi6Bid;

                  const mi7Bid = dateFinded.mi7Bid[hour];
                  if (mi7Bid) quantitySell += mi7Bid;

                  if (-(Number(value)) < quantitySell)
                  {
                    const detail = { colonna: coulumnNames[col], ora: hour, qsell: quantitySell };
                    toast.error(i18n._(t`Errore nella colonna ${detail.colonna} (blocco orario n° ${detail.ora}): La quantità inserita in acquisto è superiore alla quantità già accettata in vendita nelle sedute MGP/MI precedenti (per la medesima ora di dispacciamento).`), { autoClose: 5000 });
                    changes = [];
                  }
                }
              } else {
                const { errorMessage } = getErrorMessages(res);
                toast.error(errorMessage, { autoClose: 5000 });
              }
            }
            catch (err)
            {
              toast.error(err, { autoClose: 5000 });
            }
          }
        }
      }
    }
    return changes;
  };

  /* Compongo header */
  const COLUMNS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI'];

  let i = 0;
  const columns = [];
  columns.push({ label: i18n._(t`Qa* (con perdite di rete)`), readOnly: true, rounding: 3, col: COLUMNS[i++] });
  columns.push({ label: i18n._(t`Quantità trading`), readOnly: true, rounding: 3, col: COLUMNS[i++] });
  columns.push({ label: i18n._(t`Posizione`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  columns.push({ label: i18n._(t`Q1`), readOnly: false, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  columns.push({ label: i18n._(t`Q2`), readOnly: false, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  columns.push({ label: i18n._(t`Q3`), readOnly: false, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  columns.push({ label: i18n._(t`Q4`), readOnly: false, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  columns.push({ label: i18n._(t`P1`), readOnly: false, rounding: 2, col: COLUMNS[i++] });
  columns.push({ label: i18n._(t`P2`), readOnly: false, rounding: 2, col: COLUMNS[i++] });
  columns.push({ label: i18n._(t`P3`), readOnly: false, rounding: 2, col: COLUMNS[i++] });
  columns.push({ label: i18n._(t`P4`), readOnly: false, rounding: 2, col: COLUMNS[i++] });

  if (state.showPrice) {
    columns.push({ label: i18n._(t`PUN`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`MGP`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`MI1`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI1`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI2`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI2`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI3`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI3`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI4`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI4`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI5`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI5`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI6`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI6`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`MI7`), readOnly: true, rounding: 2, col: COLUMNS[i++] });
    columns.push({ label: i18n._(t`ΔMI7`), readOnly: true, rounding: 2, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  }

  if (state.showBidDetail) {
    columns.push({ label: i18n._(t`Quantità trading MGP`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI1`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI2`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI3`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI4`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI5`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI6`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
    columns.push({ label: i18n._(t`Quantità trading MI7`), readOnly: true, rounding: 3, col: COLUMNS[i++], positiveClass: 'positive-value', negativeClass: 'negative-value' });
  }

  const header = columns.map(column => column.label);

  return (
    <>
      <HappyAlert
        type="warning"
        show={showAlertMsg}
        onCancel={() => setShowAlertMsg(false)}
        message={alertMessage}
        style={{ width: '800px', marginLeft: '-400px' }}
      />
      <Modal isOpen={showSpreadDialog} toggle={toggleSpreadDialog}>
        <ModalHeader toggle={toggleSpreadDialog}><Trans>Impostazione Spread</Trans></ModalHeader>
        <ModalBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">€/MWh</InputGroupAddon>
            <Input
              name="spread"
              placeholder="Spread"
              value={state.editSpread && state.editSpread.value ? Number(state.editSpread.value).toFixed(2) : Number(0).toFixed(2)}
              type="number"
              min="0"
              max="100"
              onChange={e => dispatch({ type: 'editSpread', payload: { id: state.editSpread.id, value: e.target.value } })}
            />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveSpread}><Trans>Ok</Trans></Button>
          <Button color="secondary" onClick={toggleSpreadDialog}>Annulla</Button>
        </ModalFooter>
      </Modal>
      <CardStyled>
        <div>
          <StyledButtonLeft color="primary" onClick={() => previousDay()}>
            <FontAwesomeIcon icon={faCaretLeft} />
          </StyledButtonLeft>
          <PickerContainer>
            <Picker
              disabledDate={disabledStartDate}
              value={startDate}
              onChange={value => onChange('startDate', value)}
            />
          </PickerContainer>
          <PickerContainer>
            <Picker
              disabledDate={disabledEndDate}
              value={endDate}
              onChange={value => onChange('endDate', value)}
            />
          </PickerContainer>
          <StyledButtonRight color="primary" onClick={() => nextDay()}>
            <FontAwesomeIcon icon={faCaretRight} />
          </StyledButtonRight>
          <StyledButtonSpread color="secondary" onClick={() => toggleSpreadDialog()}>
            <FontAwesomeIcon icon={faPencilAlt} />
            <span>SPREAD ({state.spread && state.spread.value ? Number(state.spread.value).toFixed(2) : Number(0).toFixed(2)} €/MWh)</span>
          </StyledButtonSpread>
          <SwitchContainer>
            <Switch id="price" className="left-switch" checked={state.showPrice} label={<Trans>Prezzi</Trans>} onChange={() => handleChangeShowPrice()} />
            <Switch id="detail" className="right-switch" checked={state.showBidDetail} label={<Trans>Dettaglio bid</Trans>} onChange={() => handleChangeShowBidDetail()} />
          </SwitchContainer>
        </div>
        <MainContainerStyled>
          <TableContainerDiv>
            {
                dates && dates.map((date, index) => {
                  let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                  sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                  const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                  const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');
                  const activeHours = getActiveHours(date);
                  const isDisabled = (activeHours.find(el => el === true)) === true || false;
                  const dataCur = state.data ? state.data[index] : null;
                  const dataOrigin = state.oldData ? state.oldData[index] : null;
                  const sendBidUrl = getUrl('/bidding/sendBid', type, id);

                  const getBidData = () => {
                    if (dataCur) {
                      return dataCur.map((record, hour) => record.filter((col, index) => {
                        if (activeHours[hour]) {
                          // Coppia Quantità 2/Prezzo 2
                          if (!record[4] || (record[4] && Number(record[4]) === 0)) {
                            record[4] = null;
                            record[8] = null;
                          }
                          // Coppia Quantità 3/Prezzo 3
                          if (!record[5] || (record[5] && Number(record[5]) === 0)) {
                            record[5] = null;
                            record[9] = null;
                          }
                          // Coppia Quantità 4/Prezzo 4
                          if (!record[6] || (record[6] && Number(record[6]) === 0)) {
                            record[6] = null;
                            record[10] = null;
                          }
                        }
                        return [3, 4, 5, 6, 7, 8, 9, 10].indexOf(index) >= 0;
                      }));
                    }
                    return null;
                  };

                  const checkQP = (e) => {
                    if (dataCur) {
                      const isEmptyPrice = value => (!value && value != 0) || value === '';
                      // const isEmptyQuantity = value => !value || (value && value === '');
                      const isEmptyQuantity = value => !value || (value && Number(value) === 0) || (value && value === '');
                      const errors = [];
                      for (let h = 0; h < dataCur.length; h += 1) {
                        if (activeHours[h]) {
                          const record = dataCur[h];
                          if ((isEmptyQuantity(record[3]) && !isEmptyPrice(record[7])) || (!isEmptyQuantity(record[3]) && isEmptyPrice(record[7]))) { // Check Q1/P1
                            errors.push(i18n._(t`Coppia Quantità 1/Prezzo 1 (Ora ${h + 1}) non valorizzata correttamente.`));
                          }
                          if (isEmptyQuantity(record[3]) && isEmptyPrice(record[7]) && ((!isEmptyQuantity(record[4]) && !isEmptyPrice(record[8])) || (!isEmptyQuantity(record[5]) && !isEmptyPrice(record[9])) || (!isEmptyQuantity(record[6]) && !isEmptyPrice(record[10])))) { // Check Q1/P1
                            errors.push(i18n._(t`Coppia Quantità 1/Prezzo 1 (Ora ${h + 1}) non valorizzata correttamente.`));
                          }
                          if (!isEmptyQuantity(record[4]) && isEmptyPrice(record[8])) { // Check Q2/P2
                            errors.push(i18n._(t`Coppia Quantità 2/Prezzo 2 (Ora ${h + 1}) non valorizzata correttamente.`));
                          }
                          if (!isEmptyQuantity(record[5]) && isEmptyPrice(record[9])) { // Check Q3/P3
                            errors.push(i18n._(t`Coppia Quantità 3/Prezzo 3 (Ora ${h + 1}) non valorizzata correttamente.`));
                          }
                          if (!isEmptyQuantity(record[6]) && isEmptyPrice(record[10])) { // Check Q4/P4
                            errors.push(i18n._(t`Coppia Quantità 4/Prezzo 4 (Ora ${h + 1}) non valorizzata correttamente.`));
                          }
                        }
                      }

                      if (errors.length > 0) {
                        showAlertMessage(errors);
                        e.preventDefault();
                      }
                    }
                  };

                  return (
                    <RowContainerDiv>
                      { index !== 0 && index < dates.length && <Spacer /> }
                      <HaderComponent>
                        <HaderDateComponent>
                          {sDate}
                        </HaderDateComponent>
                        <StyledButtonOptimizedQP color="secondary" onClick={() => getOptimizedQP(date)}>
                          <FontAwesomeIcon icon={faHandPointRight} />
                          <span><Trans>Carica Q/P ottimizzati</Trans></span>
                        </StyledButtonOptimizedQP>
                        <StyledButtonSendBid color="primary" tag={Link} to={{ pathname: sendBidUrl, date, bidData: () => getBidData() }} onClick={e => checkQP(e)} disabled={!isDisabled}>
                          <FontAwesomeIcon icon={faHandPointRight} />
                          <span><Trans>Invia bid</Trans></span>
                        </StyledButtonSendBid>
                        <HaderDayComponent>
                          {isToday ? <Trans>OGGI</Trans> : ''}
                          {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                        </HaderDayComponent>
                      </HaderComponent>
                      <Datasheet
                        data={dataCur}
                        enableCheckBox={false}
                        date={date}
                        cellWidth={1}
                        handleCellsChanged={changes => handleCellsChanged(date, changes)}
                        saveCell={(cell, newValue) => {
                          let value = newValue;
                          if (cell.x === 0 && value === '') value = undefined;
                          saveUserBidding(date, selectedPdo.pdoID, cell.x, cell.y, value);
                        }}
                        header={header}
                        readyOnly={(col, row, value) => {
                          const checkActiveHours = () => {
                            if (row !== 0) {
                              if (!activeHours[row - 1]) {
                                return true;
                              }
                              return false;
                            }
                          };

                          return (checkActiveHours() || columns.find(column => column.col === col).readOnly);
                        }} // passo le colonne read only
                        classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                          let className = '';

                          const refDate = moment(dates[index].toObject()).hour(row - 1).startOf('hours');

                          if (row !== 0) {
                            const column = columns.find(column => column.col === col);

                            if (!activeHours[row - 1]) {
                              className = className.concat(' cell-passed-hours');
                            }

                            if (column.positiveClass && value > 0) {
                              className = className.concat(` ${column.positiveClass}`);
                            }
                            if (column.negativeClass && value < 0) {
                              className = className.concat(` ${column.negativeClass}`);
                            }
                          }

                          const currentbiddingTable = trading.biddingTable[refDate.format('DD/MM/YYYY')];
                          if (currentbiddingTable && currentbiddingTable[selectedPdo.pdoID] && currentbiddingTable[selectedPdo.pdoID].userBidding) {
                            try {
                              const userBidding = currentbiddingTable[selectedPdo.pdoID].userBidding;
                              const pos = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'].indexOf(col);
                              const { rounding } = columns.find(column => column.col === col);

                              if (pos !== -1 && userBidding[row - 1] && userBidding[row - 1][pos] !== undefined) {
                                const valueToCompare = dataOrigin[row - 1][pos] ? Number(dataOrigin[row - 1][pos]).toFixed(rounding) : Number(0);
                                if (Number(userBidding[row - 1][pos]) !== valueToCompare || (userBidding[row - 1][pos] === '' && valueToCompare)) {
                                  className = className.concat(' edituser');
                                }
                              }
                            } catch (warn) {
                              // console.warn(warn);
                            }
                          }
                          return className;
                        }}
                      />
                    </RowContainerDiv>
                  );
                })
              }
          </TableContainerDiv>
        </MainContainerStyled>
      </CardStyled>
    </>
  );
};

Bidding.propTypes = {
  language: PropTypes.string.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  trading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { biddingFeSetting, error, receiveBiddingQaSuccess } = state.trading;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  const { trading } = state;
  return { receiveBiddingQaSuccess, biddingFeSetting, error, language, path, type, id, selectedPdo, trading };
};

const mapDispatchToProps = dispatch => ({
  getBiddingQA: (startDate, endDate, pdoID, pdoCode) => dispatch(getBiddingQA(startDate, endDate, pdoID, pdoCode)),
  saveUserBidding: (date, pdoID, x, y, value) => dispatch(saveUserBidding(date, pdoID, x, y, value)),
  saveOptimizedQp: (pdoID, data) => dispatch(saveOptimizedQp(pdoID, data)),
  setBiddingSettings: settings => dispatch(setBiddingSettings(settings)),
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bidding);
