import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { getRUP, updateRUP, postRUP } from 'ducks/actions/trading';
import { toast } from 'react-toastify';
import { api } from '../../../../api';
import { startLoader, stopLoader } from '../../../../ducks/actions/navigation';
import DynamicForm from '../../../common/DynamicForm';

const EditRUP = ({ startLoader, stopLoader, selectedPdo, rupdata, history, location, user }) => {
  const { state }=location;
  

  const confirmSubmit = (data) => {
    startLoader();
    if (location.state.new) {
      api.post('/TernaRUPs', data).then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.status == '200') {
          getRUP(selectedPdo.code);
          stopLoader();
          history.goBack();
        }
      }).catch((err) => {
        stopLoader();
        console.log(err);
        toast.error('Errore nel salvataggio', { autoClose: 2000 });
        setTimeout(() => history.goBack(), 1500);
      });
    } else {
      api.patch(`/TernaRUPs/${location.state.selected.id}`, data)
        .then((res) => {
          if (res.status == '200') {
            stopLoader();
            history.goBack();
          }
        })
        .catch((err) => {
          stopLoader();
          toast.error('Errore nel salvataggio:' + err, { autoClose: 2000 });
          setTimeout(() => history.goBack(), 1500);
        });
    }
  };
  const handleSubmit = (data) => {
    data.pdo = selectedPdo.code;
    data.createby = user.name ;
    data.fascia = "";
    data.isDeleted = false;
    confirmSubmit(data);
  };

  const submitPmin = (value, values) => {
    let lastpmax = '';
    if (location.state.new) {
      lastpmax = location.state.selected.pmax;
    } else {
      lastpmax = rupdata.length > 0 ? rupdata[rupdata.length - 1].pmax : 0;
    };
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    } else if (value < lastpmax && location.state.new) {
      return new Error('Potenza inferiore alla Potenza max della fascia precedente');
    } else if (value > values.pmax) {
      return new Error('Potenza superiore alla Potenza max');
    } else if (value > values.ptelmax) {
      return new Error('Potenza superiore alla Potenza Tel. max');
    } return true;
  };
  // eslint-disable-next-line consistent-return
  const submitPmax = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (value < values.pmax) {
      return new Error('Valore inferirore alla pmax');
    }
  };
  const submitAssetto = (value) => {
    if (value.length > 40) {
      return new Error('Numero caratteri superiore a 40');
    } return true;
  };
  const submitPtelmin = (value, values) => {
    let lastptelmax = '';
    if (location.state.new) {
      lastptelmax = location.state.selected.ptelmax;
    } else {
      lastptelmax = rupdata.length > 0 ? rupdata[rupdata.length - 1].ptelmax : 0;
    };
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (parseFloat(value) < parseFloat(lastptelmax) && location.state.new) {
      return new Error('Potenza inferiore rispetto la fascia precedente');
    } else if (parseFloat(value) > parseFloat(values.ptelmax)) {
      return new Error('La potenza deve essere inferiore a P.tel.max.');
    }
    return true;
  };
  // eslint-disable-next-line consistent-return
  const submitPtelmax = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitTram = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitDera = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradp = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradm = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitBRS = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere non negativo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  const submitTavv = (value, values) => {
    let lastassetto = '';
    if (location.state.new) {
      lastassetto = location.state.selected.assetto;
    } else {
      lastassetto = rupdata.length > 0 ? rupdata[rupdata.length - 1].assetto : 0;
    };
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    if (lastassetto === values.assetto) {
      if (value != 0 && location.state.new) {
        return new Error('T avviamento deve essere nulla');
      } return true;
    } else if (parseFloat(value) < parseFloat(values.tram)) return new Error('T avviamento minore di T Rampa');

    return true;
  };
  const submitTarr = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    let lastassetto = '';
    if (location.state.new) {
      lastassetto = location.state.selected.assetto;
    } else {
      lastassetto = rupdata.length > 0 ? rupdata[rupdata.length - 1].assetto : 0;
    };
    if (lastassetto === values.assetto) {
      if (value == 0) {
        return true;
      } return new Error('T arresto deve essere nulla');
    } return true;
  };
  const submitTris = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    return true;
  };

  const fields = [
    {
      name: 'pmin',
      type: 'text',
      label: <Trans>P.min</Trans>,
      validation: {
        required: true,
        number: true,
        func: (value, values) => submitPmin(value, values),
      },
      md: 3,
    },
    {
      name: 'pmax',
      type: 'text',
      label: <Trans>P.max</Trans>,
      validation: { required: true, number: true, minStrictVal: 0, func: (value, values) => submitPmax(value, values) },
      md: 3,
    },
    {
      name: 'assetto',
      type: 'text',
      label: <Trans>Assetto</Trans>,
      validation: { required: true, func: (value, values) => submitAssetto(value, values) },
      md: 3,
    },
    {
      name: 'ptelmin',
      type: 'text',
      label: <Trans>P.tel.min.</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitPtelmin(value, values) },
      md: 3,
    },
    {
      name: 'ptelmax',
      type: 'text',
      label: <Trans>P.tel.max</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitPtelmax(value, values) },
      md: 3,
    },
    {
      name: 'tram',
      type: 'text',
      label: <Trans>T.ram</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTram(value, values) },
      md: 3,
    },
    {
      name: 'tder',
      type: 'text',
      label: <Trans>T.der</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitDera(value, values) },
      md: 3,
    },
    {
      name: 'tavv',
      type: 'text',
      label: <Trans>T.avv</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTavv(value, values) },
      md: 3,
    },
    {
      name: 'tarr',
      type: 'text',
      label: <Trans>T.arr</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTarr(value, values) },
      md: 3,
    },
    {
      name: 'tris',
      type: 'text',
      label: <Trans>T.ris</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTris(value, values) },
      md: 3,
    },
    {
      name: 'gradp',
      type: 'text',
      label: <Trans>Grad +</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitGradp(value, values) },
      md: 3,
    },
    {
      name: 'gradm',
      type: 'text',
      label: <Trans>Grad -</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitGradm(value, values) },
      md: 3,
    },
    {
      name: 'brs',
      type: 'text',
      label: <Trans>BRS</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitBRS(value, values) },
      md: 3,
    },
  ];

  return (
    <DynamicForm
      card
      title={<Trans>Inserisci nuova fascia</Trans>}
      fields={fields}
      onSubmit={handleSubmit}
      initialValues={location.state.selected}
      onCancel={() => history.goBack()}
    />
  );
};


EditRUP.propTypes = {
  id: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  rupdata: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  const { rupdata } = state.trading;
  return { user, language, path, type, id, selectedPdo, selectedOdm, rupdata };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: obj => dispatch(getRUP(obj)),
  postRUP: obj => dispatch(postRUP(obj)),
  updateRUP: obj => dispatch(updateRUP(obj)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditRUP);
