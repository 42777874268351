import React from 'react';
import styles from '../HappyAlertStyle';

export default () => (
  <div style={Object.assign({}, styles.icon, styles.iconError)}>
    <span style={styles.iconErrorX}>
      <span style={Object.assign({}, styles.iconErrorLine, styles.iconErrorLineLeft)} />
      <span style={Object.assign({}, styles.iconErrorLine, styles.iconErrorLineRight)} />
    </span>
  </div>
);
