import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import AssetSelector from './AssetSelector';

const Wrapper = styled(Dropdown)`
  height: 100%;
`;

const DropdownToggleButton = styled(DropdownToggle)`
  &&&& {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 0;
    background-color: transparent !important;
    border: none;
    color: ${props => props.theme.navbar.color};
    transition: all .2s;


    &:focus {
      outline: 0;
      box-shadow: none !important;
    }

    &:active {
      background-color: transparent;
      color: ${props => props.theme.navbar.hoverColor};
    }

    ${props => (props.isOpen || props.active) && `background-image: linear-gradient(to bottom, transparent, ${transparentize(props.active ? 0.5 : 0.7, props.theme.navbar.hoverColor)})`};

    &:hover {
      background-image: ${props => `linear-gradient(to bottom, transparent, ${transparentize(props.active ? 0.5 : 0.7, props.theme.navbar.hoverColor)})`};
    }

    & svg {
      transition: all .2s;
      margin-left: .5rem;
      ${props => props.isOpen && 'transform: rotate(180deg)'};
    }
  }
`;


const StyledButton = styled(Link)`
  &&&& {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 0;
    background-color: transparent !important;
    border: none;
    color: ${props => props.theme.navbar.color};
    text-decoration: none;
    padding: 0.375rem 0.75rem;
    transition: all .2s;


    &:focus {
      outline: 0;
      box-shadow: none !important;
    }

    &:active {
      background-color: transparent;
      color: ${props => props.theme.navbar.hoverColor};
    }

    ${props => props.active && `background-image: linear-gradient(to bottom, transparent, ${transparentize(0.5, props.theme.navbar.hoverColor)})`};

    &:hover {
      background-image: ${props => `linear-gradient(to bottom, transparent, ${transparentize(props.active ? 0.5 : 0.7, props.theme.navbar.hoverColor)})`};

      /* underline del link su mouseover del button */
      a {
        text-decoration: underline;
      }
    }
  }
`;


const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;

  & > div {
    width: 10rem;
    text-align: left;
  }

  & > a {
    max-width: 10rem;
  }

  @media only screen and (max-width: 992px) {
    & > div {
      width: 7rem;
    }

    & > a {
      max-width: 7rem;
    }
  }

  @media only screen and (max-width: 576px) {
    & > div {
      width: 5rem;
    }

    & > a {
      max-width: 5rem;
    }
  }
`;

const Label = styled.div`
  font-size: .8rem;
  text-transform: uppercase;
  color: #fffa;
  margin-bottom: 1px;
`;

const SelectedItemLink = styled(Link)`
  color: ${props => props.theme.navbar.color};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: ${props => props.theme.navbar.color};
  }
`;

const PlaceholderItem = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: italic;
  color: #fff6;
`;

const Selector = (props) => {
  const { active, label, selectedItem, selectedItemLink, placeholder, path, showDropdown, nameProp, children } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = (toggle) => {
    setDropdownOpen(toggle);
  };


  const clickSelected = (e) => {
    e.stopPropagation();
  };

  if (!showDropdown) {
    return (
      <Wrapper>
        <StyledButton to={selectedItemLink} active={active}>
          <Item>
            <Label>{label}</Label>
            {selectedItem ? <SelectedItemLink to={selectedItemLink}>{selectedItem[nameProp]}</SelectedItemLink> : <PlaceholderItem>{placeholder}</PlaceholderItem>}
          </Item>
        </StyledButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper isOpen={dropdownOpen} toggle={() => toggleDropdown(!dropdownOpen)}>
      <DropdownToggleButton active={active} isOpen={dropdownOpen}>
        <Item>
          <Label>{label}</Label>
          {selectedItem ? <SelectedItemLink to={selectedItemLink} onClick={clickSelected}>{selectedItem[nameProp]}</SelectedItemLink> : <PlaceholderItem>{placeholder}</PlaceholderItem>}
        </Item>
        <FontAwesomeIcon icon="angle-down" />
      </DropdownToggleButton>
      <DropdownMenu>
        {children && (
          <AssetSelector path={path} onSelect={() => setDropdownOpen(false)}>
            {children}
          </AssetSelector>
        )}
      </DropdownMenu>
    </Wrapper>
  );
};

Selector.propTypes = {
  label: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  active: PropTypes.bool,
  selectedItemLink: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  path: PropTypes.string.isRequired,
  showDropdown: PropTypes.bool,
  nameProp: PropTypes.string,
  children: PropTypes.any.isRequired,
};

Selector.defaultProps = {
  selectedItem: null,
  active: false,
  placeholder: 'Select',
  showDropdown: false,
  nameProp: 'name',
};

export default Selector;
