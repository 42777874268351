import axios from 'axios';
import keycloak from '../keycloak';

// Api che utilizzano il connettore rest di Loopback
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://f2m.test.exacto.energy/api',
});

api.interceptors.request.use(config => (
  keycloak.updateToken(5)
    .then((refreshed) => {
      if (refreshed) {
        keycloak.saveTokensInLocalStorage();
      }
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(config);
    })
    .catch((e) => {
      console.log(e);
      keycloak.login();
    })
));

// Custom api che non utilizzano il connettore rest di loopback
export const customApi = axios.create({
  baseURL: process.env.REACT_APP_CUSTOM_API_BASE_URL || 'https://f2m.test.exacto.energy/customApi',
});

customApi.interceptors.request.use(config => (
  keycloak.updateToken(5)
    .then((refreshed) => {
      if (refreshed) {
        keycloak.saveTokensInLocalStorage();
      }
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(config);
    })
    .catch((e) => {
      console.log(e);
      keycloak.login();
    })
));

export const optzApi = axios.create({
  baseURL: process.env.REACT_APP_CUSTOM_API_BASE_URL || 'https://f2m.test.exacto.energy/customApi',
});

