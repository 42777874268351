/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import { Card } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import _ from 'lodash';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';
import HappyAlert from '../../../common/HappyAlert';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
  overflow-x: auto;
  height: calc(100vh - 165px);
  margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;


const Availability = (props) => {
  const { startLoader, stopLoader, selectedPdo, language } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);
  const [error, setError] = useState(null);
  const [showAlertMsg, setShowAlertMsg] = useState(false);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  useEffect(() => {
    setShowAlertMsg(true);
  }, [error]);

  const init = () => {
    if (selectedPdo && startDate && endDate) {
      const siteIds = [];
      const newHeader = [];
      selectedPdo.pdoSites.forEach((pdoSite) => {
        siteIds.push(pdoSite.siteID);
        newHeader.push(<span>{pdoSite.siteName}{' '}<Bold>{pdoSite.registryCode}</Bold></span>);
      });

      const params = {
        // siteIds: [3],
        odmID: selectedPdo.odmID,
        siteIds,
        begin: startDate.startOf('day').toISOString(),
        end: endDate.endOf('day').toISOString(),
      };
      // newHeader.push('Sede di Roma');
      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        customApi.get('/exacto/getEnergyAssetsAvailability', { params }).then((res) => {
          if (res.data && res.data instanceof Array) {
            const newData = [];
            const errors = [];

            res.data.forEach((site) => {
              if (site.missingWmax.length > 0) {
                site.missingWmax.forEach((e) => {
                  errors.push(`${i18n._(t`Potenza elettrica nominale massima non presente in anagrafia: `)} ${e.calendarName}`);
                });
              }
            });

            dates.forEach((date) => {
              const newDataHour = [];
              for (let i = 0; i < 24; i++) {
                const newDataSite = [];
                selectedPdo.pdoSites.forEach((pdoSite) => {
                  let energyAssetsAvailable = 0;
                  let totalPotenzaNominale = 0;


                  const { siteID } = pdoSite;

                  const { assetAvailabilities, missingWmax } = res.data.find(site => site.siteId == siteID);

                  pdoSite.pdoUnits.forEach((pdoUnit) => {
                    const energyAsset = assetAvailabilities.find(asset => asset.siteId == siteID && asset.assetId == pdoUnit.nodeAssetID);
                    if (energyAsset) {
                      const findDate = moment(date.toObject()).hour(i).startOf('hour').toISOString();
                      energyAssetsAvailable += energyAsset.availabilityWmax[findDate];
                      totalPotenzaNominale += energyAsset.wMaxold;
                    }
                  });

                  const averagePercentage = (energyAssetsAvailable / totalPotenzaNominale) * 100;
                  if (isNaN(averagePercentage) || missingWmax.length > 0) newDataSite.push('n.d');
                  else newDataSite.push(`${(energyAssetsAvailable !== 0) ? energyAssetsAvailable.toFixed(3) : 0} (${Math.round(averagePercentage)}%)`);
                });
                newDataHour.push(newDataSite);
              }
              newData.push(newDataHour);
            });

            if (errors.length > 0) { setError(errors); }

            // console.log(newData);
            setData(newData);
            setHeader(newHeader);
          } else {
            setError(res.data);
          }
        }).catch((err) => {
          if (err.response && err.response.status === 501) {
            setError(err.response.data.map(m => `${i18n._(t`Manca potenza elettrica nominale massima: `)}Sito: ${m.siteName} Asset: ${m.nodeName}`));
          } else {
            console.log(err);
            setError(err);
          }
        });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  return (
    <CardStyled>
      <div>
        { error && <HappyAlert message={error} type="error" show={showAlertMsg} onCancel={() => setShowAlertMsg(false)} /> }
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const dataCur = data[index];

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataCur}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      readyOnly={(col, row, value) => true} // passo le colonne read only
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        let className = '';

                        if (value.includes("100%")) {
                          className = className.concat(' green-value');
                        } else if (value.endsWith("(0%)")) {
                          className = className.concat(' red-value');
                        } else {
                          className = className.concat(' orange-value');
                        }

                        return className;
                      }}
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
    </CardStyled>
  );
};

Availability.propTypes = {
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired, // from redx
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  return { language, path, type, id, selectedPdo };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
