/* eslint-disable no-loop-func */
/* eslint-disable no-cond-assign */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react';
import * as clipboard from 'clipboard-polyfill';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mathjs from 'mathjs';
import ReactDataSheet from 'react-datasheet';
import moment from 'moment-timezone';
import DefaultDataEditor from './DefaultDataEditor';
import 'react-datasheet/lib/react-datasheet.css';
import './override-datasheet.css';

const DatasheetContainer = styled.div`
 && {
   display: flex;
   width:100%;
  }
`;

const DatasheetHoursContainer = styled.div`
 && {
   position: sticky;
   left: 0px;
  }
`;

const Datasheet = ({ timezone, date, timeRate, data, cellWidth, header, readyOnly, classMap, showHours, showRows, showTotalRow, selectedCheckbox, saveMatrix, saveCell, onChecked, minGridSize, enableCheckBox, normalizeValue, handleCellsChanged, dataEditor }) => {
  const [currentData, setCurrentData] = useState(null); // simulo lo state derivate from props
  const [selections, setSelections] = useState(selectedCheckbox); // selezione di checkbox
  const ref = useRef(null);
  const range = (start, end) => {
    const array = [];
    const inc = end - start > 0;
    for (let i = start; inc ? i <= end : i >= end; inc ? i++ : i--) {
      inc ? array.push(i) : array.unshift(i);
    }
    return array;
  };

  useEffect(() => {
    setSelections(selectedCheckbox);
  }, [selectedCheckbox]);

  /* notifica la selezione */
  useEffect(() => {
    if (data && !_.isEqual(currentData, data)) {
      setCurrentData(data);
    }
  }, [data]);

  const gridSize = header.length > minGridSize ? header.length : minGridSize;
  const gridLenght = currentData ? currentData.length : 24;

  const letterToNumbers = (string) => {
    string = string.toUpperCase();
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; let sum = 0; let
      i;
    for (i = 0; i < string.length; i++) {
      sum += Math.pow(letters.length, i) * (letters.indexOf(string.substr(((i + 1) * -1), 1)) + 1);
    }
    return sum;
  };

  const toColumnName = (num) => {
    let ret = '';
    let parseNum = num;
    for (let a = 1, b = 26; (parseNum -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode(parseInt((parseNum % b) / a, 0) + 65) + ret;
    }
    return ret;
  };

  const validateExp = (trailKeys, expr) => {
    let valid = true;
    const matches = expr.match(/[A-Z]{1,2}[1-9]+/g) || [];
    matches.map((match) => {
      if (trailKeys.indexOf(match) > -1) {
        valid = false;
      } else {
        valid = validateExp([...trailKeys, match], currentData[match].expr);
      }
    });
    return valid;
  };

  const computeExpr = (key, expr, scope) => {
    expr = expr.replace(/,/g, '.');
    let value = null;
    if (expr.charAt(0) !== '=') {
      return { className: '', value: expr, expr };
    }

    try {
      value = mathjs.eval(expr.substring(1), scope);
    } catch (e) {
      value = null;
    }

    if (value !== null && validateExp([key], expr)) {
      return { className: 'equation', value, expr };
    }
    return { className: 'error', value: 'error', expr: '' };
  };

  const cellUpdate = (data, changeCell, expr) => {
    const scope = _.mapValues(data, val => (isNaN(val.value) ? 0 : parseFloat(val.value)));
    const updatedCell = _.assign({}, changeCell, computeExpr(changeCell.key, expr, scope));
    data[changeCell.y][changeCell.x] = normalizeValue(toColumnName(changeCell.x + 1), changeCell.y, updatedCell.value);

    _.each(data, (cell, key) => {
      if (cell.expr && cell.expr.charAt(0) === '=' && cell.expr.indexOf(changeCell.key) > -1 && key !== changeCell.key) {
        data = cellUpdate(data, cell, cell.expr);
      }
    });
    return data;
  };

  const onCellsChanged = async (arrayOfChanges) => {
    let changes = arrayOfChanges;
    if (handleCellsChanged) {
      changes = await handleCellsChanged(arrayOfChanges);
    }
    if (changes && changes[0] && changes[0].value === '-') {
      return;
    }
    const data = _.assign([], currentData);
    changes.forEach(({ cell, value }) => {
      const newVal = value.replace(',', '.');
      cellUpdate(data, cell, newVal);
      if (saveCell) saveCell(cell, newVal);
    });
    saveMatrix(data);
  };

  const generateGrid = (hours) => {
    if (!currentData) return [];
    const listRow = [];

    /* inserisci l'header */
    const listColHeader = [];
    for (let x = 1; x <= gridSize; x += 1) {
      const col = toColumnName(x);
      listColHeader.push({ key: `${col}0`, heigth: 150, width: cellWidth, value: header[x - 1], className: 'header', readOnly: true });
    }
    listRow.push(listColHeader);

    const totals = Array(gridSize).fill(null);

    /* definizione dati */
    const totalsHours = showTotalRow ? hours.length - 1 : hours.length;
    for (let row = 1; row < totalsHours; row += 1) {
      const listCol = [];
      for (let colIndex = 1; colIndex <= gridSize; colIndex += 1) {
        const column = toColumnName(colIndex);
        if (currentData[row - 1] && currentData[row - 1][colIndex - 1] !== undefined) {
          const cellData = currentData[row - 1][colIndex - 1];
          const value = cellData !== undefined ? cellData : '';
          const className = classMap(column, row, value);
          const readOnly = readyOnly(column, row, value);
          listCol.push({ key: `${column + row}`, x: colIndex - 1, y: row - 1, width: cellWidth, value: normalizeValue(toColumnName(colIndex), row - 1, value), className, readOnly });

          if (value) {
            totals[colIndex - 1] = totals[colIndex - 1] + parseFloat(value);
          }
        } else {
          const className = classMap(column, row);
          const readOnly = readyOnly(column, row);
          listCol.push({ key: `${column + row}`, x: colIndex - 1, y: row - 1, width: cellWidth, value: null, className, readOnly });
        }
      }

      listRow.push(listCol);
    }

    if (showTotalRow) {
      // Costruzione riga dei totali
      const totalColumns = totals.map((value) => {
        let className = '';
        if (value > 0) {
          className = className.concat(' positive-value');
        }
        if (value < 0) {
          className = className.concat(' negative-value');
        }
        return { key: `${gridSize + 25}`, x: gridSize, y: 25 - 1, width: cellWidth, value: value ? Number(value).toFixed(2) : null, className, readOnly: true };
      });
      listRow.push(totalColumns);
    }

    return listRow;
  };

  const RowRenderer = (options) => {
    const { as: Tag, cellAs: Cell, className, row, selected, disabled, onSelectChanged, children } = options;
    return (
      <Tag className={className}>
        {enableCheckBox && (
        <Cell className="action-cell cell">
          <input
            type="checkbox"
            value={options.cells[0].value}
            checked={selected}
            disabled={disabled}
            onChange={e => onSelectChanged(row, e.target.checked, e.target.value)}
          />
        </Cell>
        )}
        {children}
      </Tag>
    );
  };

  const checkBoxRender = options => (
    <RowRenderer
      as="tr"
      cellAs="td"
      selected={showRows ? selections.filter((f, idx) => showRows.indexOf(idx) >= 0)[options.row].checked : selections[options.row] ? selections[options.row].checked : false}
      disabled={showRows ? selections.filter((f, idx) => showRows.indexOf(idx) >= 0)[options.row].disabled : selections[options.row] ? selections[options.row].disabled : false}
      onSelectChanged={(index, selected, value) => {
        const newSelections = [];

        if (index === 0) {
          for (let i = 0; i <= gridLenght; i += 1) {
            if (selections[i].disabled || showRows.indexOf(i) === -1) {
              newSelections.push({ ...selections[i] });
            } else {
              newSelections.push({ ...selections[i], checked: selected });
            }
          }
          setSelections(newSelections);
        } else {
          newSelections.push(...selections);
          newSelections[parseInt(value, 0)] = { ...selections[parseInt(value, 0)], checked: selected };
          setSelections(newSelections);
        }

        onChecked(newSelections);
      }}
      className="data-row"
      {...options}
    />
  );

  /* costruisco indice colonne nel caso cui non sia stato passata la definizione di un header */
  const headers = [];
  if (!header) {
    for (let i = 1; i <= gridSize; i += 1) {
      headers.push({ readOnly: true, value: toColumnName(i), width: cellWidth });
    }
  }

  /* definizione colonna ore */
  let hours;
  if (timeRate === 'hour') {
    hours = [
      [{ readOnly: true, value: 'Ora', width: 30 }],
      [{ readOnly: true, value: 1, width: 30 }],
      [{ readOnly: true, value: 2, width: 30 }],
      [{ readOnly: true, value: 3, width: 30 }],
      [{ readOnly: true, value: 4, width: 30 }],
      [{ readOnly: true, value: 5, width: 30 }],
      [{ readOnly: true, value: 6, width: 30 }],
      [{ readOnly: true, value: 7, width: 30 }],
      [{ readOnly: true, value: 8, width: 30 }],
      [{ readOnly: true, value: 9, width: 30 }],
      [{ readOnly: true, value: 10, width: 30 }],
      [{ readOnly: true, value: 11, width: 30 }],
      [{ readOnly: true, value: 12, width: 30 }],
      [{ readOnly: true, value: 13, width: 30 }],
      [{ readOnly: true, value: 14, width: 30 }],
      [{ readOnly: true, value: 15, width: 30 }],
      [{ readOnly: true, value: 16, width: 30 }],
      [{ readOnly: true, value: 17, width: 30 }],
      [{ readOnly: true, value: 18, width: 30 }],
      [{ readOnly: true, value: 19, width: 30 }],
      [{ readOnly: true, value: 20, width: 30 }],
      [{ readOnly: true, value: 21, width: 30 }],
      [{ readOnly: true, value: 22, width: 30 }],
      [{ readOnly: true, value: 23, width: 30 }],
      [{ readOnly: true, value: 24, width: 30 }],
    ];
  } else if (timeRate === 'quart') {
    hours = [
      [{ readOnly: true, value: 'Ora', width: 100 }],
    ];

    const dateStart = moment(date, 'YYYY-MM-DD', timezone).startOf('day');
    const dateEnd = moment(dateStart.toObject()).endOf('day');

    while (dateStart.isBefore(dateEnd, 'minute')) {
      const start = dateStart.format('HH:mm');
      dateStart.add(15, 'minutes');
      const end = dateStart.format('HH:mm');
      hours.push([{ readOnly: true, value: `${start} - ${end}` }]);
    }
  }

  if (showTotalRow) {
    hours.push([{ readOnly: true, value: 'TOT' }]);
  }

  const DECIMAL_SEPARATOR = 0.1.toLocaleString().replace(/\d/g, '');
  const mainData = showRows ? generateGrid(hours).filter((f, idx) => showRows.indexOf(idx) >= 0) : generateGrid(hours);

  /* Serve ad inibire il COPIA delle celle originario della libreria, la copia e la trasformazione dei dicimali viene fatta sull'onslect */
  useEffect(() => {
    if (ref.current) {
      ref.current.handleCopy = (e) => {
        e.preventDefault();
        try {
          const { dataRenderer, valueRenderer, data } = ref.current.props;
          const { start, end } = ref.current.state;

          const text = range(start.i, end.i).map(i => range(start.j, end.j).map((j) => {
            const cell = mainData[i][j];
            if (!isNaN(cell.value) && cell.value != null) return cell.value.replace('.', DECIMAL_SEPARATOR);
            return cell.value;
          }).join('\t')).join('\n');

          e.clipboardData.setData('text/plain', text);
        } catch (err) {
          console.warn(err);
        }
      };
    }
  });

  return (
    <DatasheetContainer>
      { showHours && (
      <DatasheetHoursContainer>
        <ReactDataSheet
          className="datasheet-y-axis"
          data={showRows ? hours.filter((f, idx) => showRows.indexOf(idx) >= 0) : hours}
          valueRenderer={cell => cell.value}
          dataRenderer={cell => cell.expr}
          rowRenderer={checkBoxRender}
          onCellsChanged={onCellsChanged}
        />
      </DatasheetHoursContainer>
      )
      }
      <ReactDataSheet
        ref={ref}
        className="datasheet"
        sheetRenderer={item => (
          <table className={`${item.className}`}>
            <thead>
              <tr>
                {headers.map(col => (<th className="action-cell">{col.value}</th>))}
              </tr>
            </thead>
            <tbody>
              {item.children}
            </tbody>
          </table>
        )}
        data={mainData}
        valueRenderer={cell => cell.value}
        dataRenderer={cell => cell.expr}
        onCellsChanged={onCellsChanged}
        dataEditor={dataEditor || DefaultDataEditor}
      />
    </DatasheetContainer>
  );
};

Datasheet.propTypes = {
  data: PropTypes.array.isRequired,
  cellWidth: PropTypes.number,
  header: PropTypes.array,
  readyOnly: PropTypes.func, // serve a definire un array di colonne in sola lettera ['A','B']
  classMap: PropTypes.func, // callback per la definizione della classe CSS per ogni singola cella
  saveMatrix: PropTypes.func, // ogni cella modificata notifica una callback dell'intera matrice
  saveCell: PropTypes.func, // notifica celle modificate
  onChecked: PropTypes.func, // invoca una callback con i valori selezionati tra le checkbox
  showHours: PropTypes.bool, // visibilità colonne ora
  showRows: PropTypes.any, // function che permette di filtrare la colonna delle Ore
  showTotalRow: PropTypes.bool, // visibilità riga dei totali
  minGridSize: PropTypes.number, // numero minimo di colonne visualizzabili
  normalizeValue: PropTypes.func, // formatta il valore di una specifica cella
  enableCheckBox: PropTypes.number,
  selectedCheckbox: PropTypes.any,
  handleCellsChanged: PropTypes.func,
  dataEditor: PropTypes.object,
  timeRate: PropTypes.string,
  date: PropTypes.string,
  timezone: PropTypes.string,
};

Datasheet.defaultProps = {
  cellWidth: undefined,
  showHours: true,
  showRows: undefined,
  showTotalRow: false,
  header: [],
  selectedCheckbox: Array(97).fill(false),
  readyOnly: () => false,
  classMap: () => '',
  saveMatrix: () => {},
  saveCell: () => [],
  onChecked: () => {},
  normalizeValue: (y, x, value) => value,
  minGridSize: 0,
  enableCheckBox: false,
  handleCellsChanged: undefined,
  dataEditor: undefined,
  timeRate: 'hour',
  date: moment().format('YYYY-MM-DD'),
  timezone: 'Europe/Rome',
};

export default (Datasheet);
