/* eslint-disable brace-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import styled from 'styled-components';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import _ from 'lodash';
import { Card } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { getActiveHours } from '../tradingCommon/tradingUtils';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';
import HappyAlert from '../../../common/HappyAlert';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;


const ProfitAndLoss = (props) => {
  const { startLoader, stopLoader, selectedPdo, language } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);
  const [error, setError] = useState(null);
  const [showAlertMsg, setShowAlertMsg] = useState(false);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  /* Compongo header */
  const header = [];
  header.push(i18n._(t`P&L sbil.`));
  header.push(i18n._(t`P&L MI1`));
  header.push(i18n._(t`P&L MI2`));
  header.push(i18n._(t`P&L MI3`));
  header.push(i18n._(t`P&L MI4`));
  header.push(i18n._(t`P&L MI5`));
  header.push(i18n._(t`P&L MI6`));
  header.push(i18n._(t`P&L MI7`));
  header.push(i18n._(t`P&L TOT`));

  useEffect(() => {
    setShowAlertMsg(true);
  }, [error]);

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      const params = {
        startDate: start,
        endDate: end,
        pdoCode: selectedPdo.code,
        odmID: selectedPdo.odmID,
        pdoID: selectedPdo.pdoID,
        zone: selectedPdo.zone,
      };

      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);

        await customApi.post('/optz/getProfitAndLoss', params).then((res) => {
          if (res.data) {
            const newData = [];
            dates.forEach((date) => {
              const dataGrid = [];
              const dataCur = res.data[date.format('YYYY-MM-DD')];
              for (let i = 0; i < 24; i += 1) {

                const rowData = [];
                rowData.push(dataCur.PLSBIL && dataCur.PLSBIL[i] ? Number(dataCur.PLSBIL[i]).toFixed(2) : null); // Colonna P&L Sbilanciamento
                rowData.push(dataCur.PLMI1 && dataCur.PLMI1[i] ? Number(dataCur.PLMI1[i]).toFixed(2) : null); // Colonna P&L MI1
                rowData.push(dataCur.PLMI2 && dataCur.PLMI2[i] ? Number(dataCur.PLMI2[i]).toFixed(2) : null); // Colonna P&L MI2
                rowData.push(dataCur.PLMI3 && dataCur.PLMI3[i] ? Number(dataCur.PLMI3[i]).toFixed(2) : null); // Colonna P&L MI3
                rowData.push(dataCur.PLMI4 && dataCur.PLMI4[i] ? Number(dataCur.PLMI4[i]).toFixed(2) : null); // Colonna P&L MI4
                rowData.push(dataCur.PLMI5 && dataCur.PLMI5[i] ? Number(dataCur.PLMI5[i]).toFixed(2) : null); // Colonna P&L MI5
                rowData.push(dataCur.PLMI6 && dataCur.PLMI6[i] ? Number(dataCur.PLMI6[i]).toFixed(2) : null); // Colonna P&L MI6
                rowData.push(dataCur.PLMI7 && dataCur.PLMI7[i] ? Number(dataCur.PLMI7[i]).toFixed(2) : null); // Colonna P&L MI7
                rowData.push(dataCur.PLTOT && dataCur.PLTOT[i] ? Number(dataCur.PLTOT[i]).toFixed(2) : null); // Colonna P&L Totale

                dataGrid.push(rowData);
              }
              newData.push(dataGrid);
            });
            setData(newData);
          }
        }).catch((err) => {
          console.log(err);
          setError(err);
        });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  return (
    <CardStyled>
      <div>
        { error && <HappyAlert message={error} type="error" show={showAlertMsg} onCancel={() => setShowAlertMsg(false)} /> }
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const dataCur = data[index];

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataCur}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      showTotalRow
                      readyOnly={(col, row, value) => true} // passo le colonne read only
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        let className = '';

                        if (row !== 0) {
                          if (value > 0) {
                            className = className.concat(' positive-value');
                          }
                          if (value < 0) {
                            className = className.concat(' negative-value');
                          }
                        }

                        return className;
                      }}
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
    </CardStyled>
  );
};

ProfitAndLoss.propTypes = {
  language: PropTypes.string.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  selectedPdo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  return { language, path, type, id, selectedPdo };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndLoss);
