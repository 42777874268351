import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import TreeContext from './TreeContext';
import LeafNode from './LeafNode';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const Li = styled.li`
  margin-left: 1.5rem;
`;

const EmptyLabel = styled.li`
  padding: .2rem;
  margin-left: 1.5rem;
  color: ${props => props.theme.common.text.secondaryColor};
`;

const EmptyItem = styled.div`
  padding: .2rem;
  cursor: default;
`;

const Item = styled.div`
  display: inline-block;
  padding: .2rem;
  cursor: pointer;

  &:hover {
    span.label {
      color: #666;
    }
    svg {
      color: #aaa;
    }
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  min-width: 1rem;
  color: #999;
  transform: rotate(-90deg);
  transition: all .1s;

  ${props => props.open && 'transform: rotate(0deg)'};
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  min-width: 1rem;
  margin-left: 1.5rem;
  cursor: default;
  color: #666;
`;


const Label = styled.span`
  margin-left: .5rem;
`;

const LoadingLabel = styled.span`
  margin-left: .5rem;
  cursor: default;
`;

const StyledBadge = styled(Badge)`
  && {
    margin-left: .5rem;
    color: ${props => props.theme.common.buttons.primary.color};
    background-color: ${props => props.theme.common.buttons.primary.background};
    border-radius: 1rem;
  }
`;

const LoadingItem = () => {
  return (
    <EmptyItem>
      <SpinnerIcon icon="spinner" spin />
      <LoadingLabel>Caricamento...</LoadingLabel>
    </EmptyItem>
  );
};


const TreeNode = ({ node }) => {
  const { leaves, nLeaves, openNode, openNodes, checkedNodes, showAssets, selectedAsset } = useContext(TreeContext);

  const { type, id, name, idList, children, item } = node;

  const open = openNodes.some(x => x.type === type && x.id === id);

  const parentsOpen = nLeaves < 100;

  useEffect(() => {
    if (parentsOpen) {
      openNode(node, true);
    }
  }, [parentsOpen]);


  const compareItemName = (a, b) => {
    if (a.item.name.toLowerCase() < b.item.name.toLowerCase()) {
      return -1;
    }
    if (a.item.name.toLowerCase() > b.item.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const renderLeafNodes = (nodes, createAssetNodes) => {
    if (nodes.length === 0) {
      return null;
    }

    if (createAssetNodes) {
      if (selectedAsset) {
        const assetNode = { id: selectedAsset.id, name: selectedAsset.name, type: 'asset', item: selectedAsset };
        const opsId = selectedAsset.measures.map(x => x.observedPropertyId);
        assetNode.children = nodes.filter(x => opsId.includes(x.id));
        return <TreeNode key={assetNode.id} node={assetNode} />;
      }

      const assignedOpsId = [];
      const assetGraph = item.assetGraph || { assets: [], assetFlows: [] };
      const { assets, assetFlows } = assetGraph;

      const assetNodes = assets.filter(x => x.measures.length > 0).map((asset) => {
        const assetNode = { id: asset.id, name: asset.name, type: 'asset', item: asset };
        const opsId = asset.measures.map(x => x.observedPropertyId);
        assignedOpsId.push(...opsId);
        assetNode.children = nodes.filter(x => opsId.includes(x.id));
        return assetNode;
      });

      const treeNodesFlowsIn = assetFlows.filter(x => x.sourceMeasures.length > 0).map((flow) => {
        const asset = assets.find(x => x.id === flow.sourceAssetId);
        const opsId = flow.sourceMeasures.map(x => x.observedPropertyId);
        assignedOpsId.push(...opsId);

        const assetNode = assetNodes.find(x => x.id === asset.id);
        if (assetNode) {
          assetNode.children.push(...nodes.filter(x => opsId.includes(x.id)));
          return null;
        }

        return { id: asset.id, name: asset.name, type: 'asset', item: asset, children: nodes.filter(x => opsId.includes(x.id)) };
      });
      assetNodes.push(...treeNodesFlowsIn);

      const treeNodesFlowsOut = assetFlows.filter(x => x.destinationMeasures.length > 0).map((flow) => {
        const asset = assets.find(x => x.id === flow.destinationAssetId);
        const opsId = flow.destinationMeasures.map(x => x.observedPropertyId);
        assignedOpsId.push(...opsId);

        const assetNode = assetNodes.find(x => x.id === asset.id);
        if (assetNode) {
          assetNode.children.push(...nodes.filter(x => opsId.includes(x.id)));
          return null;
        }

        return { id: asset.id, name: asset.name, type: 'asset', item: asset, children: nodes.filter(x => opsId.includes(x.id)) };
      });
      assetNodes.push(...treeNodesFlowsOut);

      const notAssignedAsset = { id: undefined, name: 'Non associati ad alcun asset', type: 'asset', item: null };
      notAssignedAsset.children = nodes.filter(x => !assignedOpsId.includes(x.id));
      assetNodes.push(notAssignedAsset);

      return assetNodes.filter(x => x !== null).map(x => <TreeNode key={x.id} node={x} />);
    }

    return nodes.sort(compareItemName).map((child, index) => <LeafNode key={child.key || index} node={child} />);
  };

  const renderChildren = (children) => {
    // if (isLoading) return <LoadingItem />;
    if (type === 'asset') {
      return (
        <Ul>
          {children && renderLeafNodes(children, false)}
          {children.length === 0 && <EmptyLabel>Nessun risultato trovato</EmptyLabel>}
        </Ul>
      );
    }

    let leafChildren = [];

    if (!children) {
      leafChildren = leaves[node.item.id];
    } else if (!Array.isArray(children)) {
      children = children ? [children] : [];
    }
    return (
      <Ul>
        {children && children.map((child, index) => <TreeNode key={child.key || index} node={child} />)}
        {leafChildren && renderLeafNodes(leafChildren, showAssets)}
      </Ul>
    );
  };

  const handleClick = () => {
    openNode(node, !open);
  };


  let visible = false;
  let nCheckedChildren;
  if (type === 'asset') {
    visible = selectedAsset || children.length > 0;
  } else if (!children) {
    visible = leaves[id] && leaves[id].length > 0;
    if (visible) {
      nCheckedChildren = leaves[id].reduce((count, l) => count + checkedNodes.filter(n => n.type === l.type && n.id === l.id).length, 0);
    }
  } else {
    visible = idList ? idList.some(descendantId => leaves[descendantId]) : true;
  }

  if (!visible) {
    return null;
  }


  return (
    <Li>
      <Item onClick={handleClick}>
        <ArrowIcon open={open} icon="chevron-circle-down" />
        <Label className="label">{name}</Label>
        {nCheckedChildren > 0 && <StyledBadge>{nCheckedChildren}</StyledBadge>}
      </Item>
      {open && renderChildren(children)}
    </Li>
  );
};

export default TreeNode;
