import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WizardStep extends Component {
  constructor(props) {
    super(props);
    this.activeComponent = React.createRef();
  }

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  getValues = () => {
    const { getValues } = this.props;
    if (getValues) {
      return getValues();
    }
    if (this.activeComponent.current.getValues) {
      return this.activeComponent.current.getValues();
    }
    return {};
  }

  validate = async () => {
    const { validate } = this.props;
    if (validate) {
      return validate();
    }
    if (this.activeComponent.current.validate) {
      return this.activeComponent.current.validate();
    }
    return true;
  }

  setFieldValidation = (fieldName, isValid, errorMessages) => {
    this.form.current.setFieldValidation(fieldName, isValid, errorMessages);
  }

  submit = async (data) => {
    const { onSubmit, onStepSubmit } = this.props;
    // se ho definito una funzione di submit per questo step la chiamo, altrimenti chiamo quella definita nel Wizard
    if (onSubmit) {
      await onSubmit(data);
    } else {
      await onStepSubmit(data);
    }
  }

  render() {
    const { children } = this.props;
    return Object.assign({}, children, { ref: this.activeComponent });
  }
}

WizardStep.propTypes = {
  onSubmit: PropTypes.func, // la funzione eventualmente definita dall'utente da eseguire al submit del form
  onMount: PropTypes.func, // la funzione eventualmente definita dall'utente da eseguire al cancel del form,
  onStepSubmit: PropTypes.func, // la funzione definita su Wizard eseguita al submit di ogni step del form (solo se onSubmit non è stata definita in questo step)
};

WizardStep.defaultProps = {
  onSubmit: null,
  onMount: () => {},
  onStepSubmit: () => {},
};

export default WizardStep;
