import React,  { useState, useEffect, useRef } from 'react';
import { Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import moment from 'moment';

import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import './MonthRangePicker.css';


const MonthRangePicker = (props) => {
  const { from, to, min, max, handleChange, disabled } = props;

  const [mrange, setMrange] = useState({ from: props.from, to: props.to });

  const pickRange = useRef(null);

  useEffect(() => {
    setMrange({ from, to });
  }, [from, to]);

  // componentWillReceiveProps(nextProps) {
  //     this.setState({
  //         value: nextProps.value || 'N/A',
  //     })
  // }

  // const handleClickMonthBox = (e) => {
  //   this.refs.pickAMonth.show();
  // };

  // const handleAMonthChange = (value, text) => {
  //     //
  // };

  // const handleAMonthDissmis = (value) => {
  //   console.log(value);
  //   this.setState( {mvalue: value} );
  // };

  const handleClickRangeBox = (e) => {
      pickRange.current.show();
  };

  const handleRangeChange = (value, text, listIndex) => {
    //
  };

  const handleRangeDissmis = (value) => {
    const mrange = value;
    const from = mrange.from.month + '/' + mrange.from.year;
    const to = mrange.to.month + '/' + mrange.to.year;
    const values = {from, to};
    if (handleChange) handleChange(values);

    setMrange(mrange);
  };

  const pickerLang = {
    // months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    months: moment.monthsShort(),
    from: i18n._(t`Da`), to: i18n._(t`A`),
  };

  // const mvalue = this.state.mvalue;

  const makeText = m => {
    if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
    return '?'
  };

  return (
    <div className="edit">
      <Picker
          ref={pickRange}
          years={{ min, max}}
          range={mrange}
          lang={pickerLang}
          onChange={handleRangeChange}
          onDismiss={handleRangeDissmis}
        >
          <Input value={makeText(mrange.from) + ' - ' + makeText(mrange.to)} onClick={handleClickRangeBox} disabled={disabled} />
      </Picker>
      {/*
      <Picker
          ref="pickAMonth"
          years={[2008, 2010, 2011, 2012, 2014, 2015, 2016, 2017]}
          value={mvalue}
          lang={pickerLang.months}
          onChange={this.handleAMonthChange}
          onDismiss={this.handleAMonthDissmis}
          >
          <MonthRangePicker value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
      </Picker>
      */}
    </div>
  );

};

MonthRangePicker.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  min: PropTypes.object,
  max: PropTypes.object,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

MonthRangePicker.defaultProps = {
  from: { year: 2014, month: 8 },
  to: { year: 2015, month: 5 },
  min: { year: 2014, month: 8 },
  max: { year: 2030, month: 12 },
  disabled: false,
  handleChange: null,
};

export default MonthRangePicker;
