import React from 'react';

// set the defaults
const TernaAuctionsContext = React.createContext({
  ternaAuctions: [],
  onEditTernaAuction: () => {},
  onAddTernaAuction: () => {},
});

export default TernaAuctionsContext;
