import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeContext from './TreeContext';

const Li = styled.li`
  margin-left: 1.5rem;
`;

const Item = styled.div`
  display: inline-block;
  padding: .2rem;
  ${props => props.checked && 'color: #1F71B7'};
  cursor: pointer;

  &:hover {
    span, svg {
      color: #03c3cc;
    }
  }
`;

const CheckBox = styled(FontAwesomeIcon)`
  min-width: 1rem;
  color: ${props => (props.checked ? '#1F71B7' : '#aaa')};
`;

const Label = styled.span`
  margin-left: .5rem;
`;


const TreeNode = ({ node }) => {
  const { toggle, checkedNodes } = useContext(TreeContext);

  const { type, id, item } = node;
  const icon = type === 'op' ? 'ruler' : 'pencil-ruler';
  const checked = checkedNodes.some(x => x.type === type && x.id === id);

  const toggleItem = () => {
    toggle(node, !checked);
  };

  let checkBoxIcon = null;
  if (icon) {
    checkBoxIcon = icon;
  } else {
    checkBoxIcon = checked ? 'check-square' : ['far', 'square'];
  }


  return (
    <Li>
      <Item onClick={toggleItem} checked={checked}>
        {checkBoxIcon && <CheckBox icon={checkBoxIcon} checked={checked} />}
        <Label>{item.name}</Label>
      </Item>
    </Li>
  );
};

export default TreeNode;
