/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { getRUP } from 'ducks/actions/trading';
import { toast } from 'react-toastify';
import moment from 'moment';
import { api } from '../../../../api';
import { startLoader, stopLoader } from '../../../../ducks/actions/navigation';
import DynamicForm from '../../../common/DynamicForm';
// eslint-disable-next-line import/no-cycle
import { getUrl } from '../../../utils/navigation';


const DatiRUP = ({ startLoader, stopLoader, selectedPdo, user, rupdata, type, id, getRUP, history }) => {
  const datiUrl = getUrl('/uvamVDT/compileRUP', type, id);
  useEffect(() => {
    if (selectedPdo) {
      getRUP(selectedPdo.code);
    }
  }, [selectedPdo]);
  const confirmSubmit = (data) => {
    startLoader();
    api.post('/TernaRUPs', data).then((res) => {
      // eslint-disable-next-line eqeqeq
      if (res.status == '200') {
        stopLoader();
        if (rupdata.length < 1) {
          history.push(datiUrl);
        } else history.goBack();
      }
    }).catch((err) => {
      stopLoader();
      console.log(err);
      toast.error('Errore nel salvataggio', { autoClose: 2000 });
      setTimeout(() => history.goBack(), 1500);
    });
  };
  const handleSubmit = (data) => {
    data.pdo = selectedPdo.code;
    data.created = moment().format();
    data.createby = user.name;
    data.fascia = 11;
    data.isDeleted = false;
    confirmSubmit(data);
  };

  const submitPmin = (value, values) => {
    const lastpmax = rupdata.length > 0 ? rupdata[rupdata.length - 1].pmax : 0;
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    } else if (value > lastpmax && rupdata.length > 0) {
      return new Error('Potenza inferiore alla Potenza max della fascia precedente');
    } else if (value > values.pmax) {
      return new Error('Potenza superiore alla Potenza max');
    } else if (value > values.ptelmax) {
      return new Error('Potenza superiore alla Potenza Tel. max');
    } return true;
  };
  // eslint-disable-next-line consistent-return
  const submitPmax = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (value < values.pmax) {
      return new Error('Valore inferirore alla pmax');
    }
  };
  const submitAssetto = (value) => {
    if (value.length > 40) {
      return new Error('Numero caratteri superiore a 40');
    } return true;
  };
  const submitPtelmin = (value, values) => {
    const lastptelmax = rupdata.length > 0 ? rupdata[rupdata.length - 1].ptelmax : 0;
    let error = '';
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
    if (value < lastptelmax) {
      error = error.concat('Potenza inferiore rispetto la fascia precedente');
    } else error = error.concat('La potenza deve essere inferiore a P.tel.max.');
    return (value >= lastptelmax && value < values.ptelmax) || new Error(error);
  };
  // eslint-disable-next-line consistent-return
  const submitPtelmax = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitTram = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitDera = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradp = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitGradm = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  // eslint-disable-next-line consistent-return
  const submitBRS = (value) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere non negativo');
    }
    const substring = value.includes('.') ? value.substring(value.indexOf('.') + 1) : 0;
    if (substring.length > 3) {
      return new Error('Max 3 cifre decimali');
    }
  };
  const submitTavv = (value, values) => {
    const lastassetto = rupdata.length > 0 ? rupdata[rupdata.length - 1].assetto : 0;
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    if (value < values.tram) return new Error('T avviamento minore di T Rampa');
    if (lastassetto === values.assetto) {
      if (value == 0) {
        return true;
      } return new Error('T avviamento deve essere nulla');
    } return true;
  };
  const submitTarr = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    const lastassetto = rupdata.length > 0 ? rupdata[rupdata.length - 1].assetto : 0;
    if (lastassetto === values.assetto) {
      if (value == 0) {
        return true;
      } return new Error('T arresto deve essere nulla');
    } return true;
  };
  const submitTris = (value, values) => {
    if (parseFloat(value) < 0) {
      return new Error('Il valore deve essere positivo');
    }
    if (value.includes('.')) {
      return new Error('Il valore deve essere minutario');
    }
    return true;
  };

  const fields = [
    {
      name: 'pmin',
      type: 'text',
      label: <Trans>P.min</Trans>,
      validation: {
        required: true,
        number: true,
        func: (value, values) => submitPmin(value, values),
      },
      md: 3,
    },
    {
      name: 'pmax',
      type: 'text',
      label: <Trans>P.max</Trans>,
      validation: { required: true, number: true, minStrictVal: 0, func: (value, values) => submitPmax(value, values) },
      md: 3,
    },
    {
      name: 'assetto',
      type: 'text',
      label: <Trans>Assetto</Trans>,
      validation: { required: true, func: (value, values) => submitAssetto(value, values) },
      md: 3,
    },
    {
      name: 'ptelmin',
      type: 'text',
      label: <Trans>P.tel.min.</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitPtelmin(value, values) },
      md: 3,
    },
    {
      name: 'ptelmax',
      type: 'text',
      label: <Trans>P.tel.max</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitPtelmax(value, values) },
      md: 3,
    },
    {
      name: 'tram',
      type: 'text',
      label: <Trans>T.ram</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTram(value, values) },
      md: 3,
    },
    {
      name: 'tder',
      type: 'text',
      label: <Trans>T.der</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitDera(value, values) },
      md: 3,
    },
    {
      name: 'tavv',
      type: 'text',
      label: <Trans>T.avv</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTavv(value, values) },
      md: 3,
    },
    {
      name: 'tarr',
      type: 'text',
      label: <Trans>T.arr</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTarr(value, values) },
      md: 3,
    },
    {
      name: 'tris',
      type: 'text',
      label: <Trans>T.ris</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitTris(value, values) },
      md: 3,
    },
    {
      name: 'gradp',
      type: 'text',
      label: <Trans>Grad +</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitGradp(value, values) },
      md: 3,
    },
    {
      name: 'gradm',
      type: 'text',
      label: <Trans>Grad -</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitGradm(value, values) },
      md: 3,
    },
    {
      name: 'brs',
      type: 'text',
      label: <Trans>BRS</Trans>,
      validation: { required: true, number: true, func: (value, values) => submitBRS(value, values) },
      md: 3,
    },
  ];

  return (
    <DynamicForm
      card
      title={<Trans>Inserisci nuova fascia</Trans>}
      fields={fields}
      onSubmit={handleSubmit}
      onCancel={() => history.goBack()}
    />
  );
};


DatiRUP.propTypes = {
  selectedPdo: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  rupdata: PropTypes.object.isRequired,
  getRUP: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { language } = state.preferences;
  const { path, type, id, selectedPdo, selectedOdm } = state.navigation;
  const { rupdata } = state.trading;
  return { user, language, path, type, id, selectedPdo, selectedOdm, rupdata };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
  getRUP: obj => dispatch(getRUP(obj)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DatiRUP);
