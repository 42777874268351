/* eslint-disable brace-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { api, customApi } from 'api';
import styled from 'styled-components';
import { faCheck, faCheckDouble, faBan, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import _ from 'lodash';
import { Card, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import { formatNumber } from 'app/utils/commonUtils';
import { getErrorMessages } from 'app/utils/validation';
import { Roles } from 'app/utils/auth';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';
import HappyAlert from '../../../common/HappyAlert';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width:100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const DateRangeContainer = styled.div`
  && {
    display: inline;
  }
`;

const SwitchContainer = styled.div`
  && {
    display: inline;
    float: right;
    margin-right: 20px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ButtonValidationContainer = styled.div`
  && {
    width: 350px;
    display: inline;
  }
`;

const StyledButtonVal = styled(Button)`
 && {
    left: 190px;
    height: 24px;
    padding: 0px 10px 0px 10px;
    position: sticky;
  }

  && span {
     margin-left: 7px;
   }
`;

const Views = Object.freeze({
  SITES: Symbol('sites'),
  GENERATORS: Symbol('generator'),
});

const WorkflowStatusCatalog = Object.freeze({
  TO_BE_VALIDATED: '1',
  PARTIALLY_VALIDATED_UT: '2A',
  PARTIALLY_VALIDATED_TRD: '2B',
  NOT_VALIDATED_UT: '3A',
  NOT_VALIDATED_TRD: '3B',
  VALIDATED: '3C',
  NOT_VALIDATED_OPTZ: '3D',
});

const nodeSupportedAssetIDList = [23, 10, 11, 24, 41];

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        data: action.payload.data,
        dispatchingWorkflowStatus: action.payload.dispatchingWorkflowStatus,
        workflowStatusCatalog: action.payload.workflowStatusCatalog,
        lastRequest: action.payload.lastRequest,
        showDispatchingButtons: true,
      };
    case 'showDispatchingButtons':
      return {
        ...state,
        showDispatchingButtons: action.payload.showDispatchingButtons,
      };
    case 'dispatchingWorkflowStatus':
      return {
        ...state,
        dispatchingWorkflowStatus: action.payload.dispatchingWorkflowStatus,
      };
    case 'openWorkflowDialog':
      return {
        ...state,
        selectedDispatchingDate: action.payload.selectedDispatchingDate,
        openWorkflowDialog: action.payload.openWorkflowDialog,
      };
      case 'disabledDialogButtons':
        return {
          ...state,
          disabledDialogButtons: action.payload.disabledDialogButtons,
        };
    default:
      throw new Error();
  }
};

const Dispatching = (props) => {
  const { startLoader, stopLoader, selectedPdo, language, userSystemRoles } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));

  const [view, setView] = useState(Views.SITES);

  const [error, setError] = useState(null);
  const [showAlertMsg, setShowAlertMsg] = useState(false);

  const userIsTechOffice = userSystemRoles.includes(Roles.TechOffice);
  const userIsTrader = userSystemRoles.includes(Roles.Trader);

  const initialState = {
    data: [],
    dispatchingWorkflowStatus: [],
    workflowStatusCatalog: [],
    lastRequest: [],
    showDispatchingButtons: false,
    openWorkflowDialog: false,
    selectedDispatchingDate: null,
    disabledDialogButtons: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  /* Compongo header */
  const header = [];
  header.push(i18n._(t`Qa* in dispacciamento`));
  header.push(i18n._(t`Qa simulazione`));

  if (view === Views.SITES && selectedPdo) {
    selectedPdo.pdoSites.forEach((site) => {
      header.push(<span>Prod* {site.siteName} <Bold>{site.registryCode}</Bold></span>);
      header.push(<span>Prod simul {site.siteName} <Bold>{site.registryCode}</Bold></span>);
    });
  }

  if (view === Views.GENERATORS) {
    selectedPdo.pdoSites.forEach((site) => {
      site.pdoUnits.filter(f => (nodeSupportedAssetIDList.indexOf(f.nodeSupportedAssetID) !== -1)).forEach((asset) => {
        header.push(<span>Prod* {site.siteName} <Bold>{asset.nodeName}</Bold></span>);
        header.push(<span>Prod simul {site.siteName} <Bold>{asset.nodeName}</Bold></span>);
      });
    });
  }

  useEffect(() => {
    setShowAlertMsg(true);
  }, [error]);

  const toggleWorkflowDialog = (date) => {
    if (date) {
      dispatch({ type: 'openWorkflowDialog', payload: { selectedDispatchingDate: date, openWorkflowDialog: !state.openWorkflowDialog} });
    } else {
      dispatch({ type: 'openWorkflowDialog', payload: { selectedDispatchingDate: null, openWorkflowDialog: !state.openWorkflowDialog} });
    }
  };

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');

      const params = {
        startDate: start,
        endDate: end,
        odmID: selectedPdo.odmID,
        pdoID: selectedPdo.pdoID,
      };

      if (!_.isEqual(state.lastRequest, params)) {
        startLoader();

        const initStatePayload = {};

        initStatePayload.lastRequest = params;

        try {
          const arrayPromise = [customApi.post('/optz/getDispatching', params), api.get('/WorkflowStatusCatalogs')];

          if (selectedPdo.pdoID) {
            arrayPromise.push(api.get(`/DispatchingWorkflowStatuses?filter={ "where": { "pdoId": ${selectedPdo.pdoID}, "date": { "between": ["${moment(startDate).startOf('day').toISOString()}", "${moment(endDate).endOf('day').toISOString()}"] } }}`));
          }

          const res = await Promise.all(arrayPromise);

          if (res && res[0]) {
            if (res[0].data) {
              initStatePayload.data = res[0].data;
            } else {
              const { errorMessage } = getErrorMessages(res);
              toast.error(errorMessage, { autoClose: 5000 });
            }
          }

          if (res && res[1]) {
            if (res[1].data) {
              initStatePayload.workflowStatusCatalog = res[1].data;
            } else {
              const { errorMessage } = getErrorMessages(res);
              toast.error(errorMessage, { autoClose: 5000 });
            }
          }

          if (res && res[2]) {
            if (res[2].data) {
              const dispatchingWorkflowStatus = res[2].data;
              initStatePayload.dispatchingWorkflowStatus = dispatchingWorkflowStatus;
            } else {
              const { errorMessage } = getErrorMessages(res);
              toast.error(errorMessage, { autoClose: 5000 });
            }
          }

        } catch (err) {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        }

        dispatch({ type: 'init', payload: initStatePayload });

        stopLoader();
      }
    }
  };

  useEffect(() => {
    dispatch({ type: 'showDispatchingButtons', payload: { showDispatchingButtons: false } });
    init();
  }, [selectedPdo, startDate, endDate]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const updateWorkFlowStatus = async (workflowStatusCode) => {
    if (state.selectedDispatchingDate) {
      startLoader();

      dispatch({ type: 'disabledDialogButtons', payload: { disabledDialogButtons: true } });

      const statusArray = state.dispatchingWorkflowStatus && state.dispatchingWorkflowStatus.filter(wf => moment(wf.date).isSame(state.selectedDispatchingDate, 'days')) || [];
      const lastStatus = (statusArray && statusArray[statusArray.length-1]) || null;

      const newWorkflowStatus = {
        pdoId: selectedPdo.pdoID,
        pdoCode: selectedPdo.code,
        date: moment(state.selectedDispatchingDate).startOf('day').toISOString(),
        workflowStatusId: workflowStatusCode.id,
        optzRunId: lastStatus.optzRunId,
      };

      await api.post('/DispatchingWorkflowStatuses', newWorkflowStatus).then(async (res) => {
        toggleWorkflowDialog();

        if (res.status === 200) {
          const dispatchingWorkflowStatus = await api.get(`/DispatchingWorkflowStatuses?filter={ "where": { "pdoId": ${selectedPdo.pdoID}, "date": { "between": ["${moment(startDate).startOf('day').toISOString()}", "${moment(endDate).endOf('day').toISOString()}"] } }}`);
          dispatch({ type: 'dispatchingWorkflowStatus', payload: { dispatchingWorkflowStatus: dispatchingWorkflowStatus.data } });

          toast.success(i18n._(t`Aggiornamento eseguito con successo.`), { autoClose: 5000 });
        } else {
          const { errorMessage } = getErrorMessages(res);
          toast.error(errorMessage, { autoClose: 5000 });
        }
      }).catch((err) => {
        const { errorMessage } = getErrorMessages(err);
        toast.error(errorMessage, { autoClose: 5000 });
      });

      dispatch({ type: 'disabledDialogButtons', payload: { disabledDialogButtons: false } });

      stopLoader();
    }
  };

  const toBeValidated = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.TO_BE_VALIDATED);
  const partiallyValidatedUT = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.PARTIALLY_VALIDATED_UT);
  const partiallyValidatedTRD = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.PARTIALLY_VALIDATED_TRD);
  const notValidatedUT = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.NOT_VALIDATED_UT);
  const notValidatedTRD = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.NOT_VALIDATED_TRD);
  const validated = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.VALIDATED);
  const notValidatedOptz = state.workflowStatusCatalog && state.workflowStatusCatalog.find(wfc => wfc.code === WorkflowStatusCatalog.NOT_VALIDATED_OPTZ);

  return (
    <CardStyled>
      <div>
      { error && <HappyAlert message={error} type="error" show={showAlertMsg} onCancel={() => setShowAlertMsg(false)} /> }
        <DateRangeContainer>
          <StyledButtonLeft color="primary" onClick={() => previousDay()}>
            <FontAwesomeIcon icon={faCaretLeft} />
          </StyledButtonLeft>
          <PickerContainer>
            <Picker
              value={startDate}
              onChange={value => onChange('startDate', value)}
            />
          </PickerContainer>
          <PickerContainer>
            <Picker
              disabledDate={disabledEndDate}
              value={endDate}
              onChange={value => onChange('endDate', value)}
            />
          </PickerContainer>
          <StyledButtonRight color="primary" onClick={() => nextDay()}>
            <FontAwesomeIcon icon={faCaretRight} />
          </StyledButtonRight>
        </DateRangeContainer>
        <SwitchContainer>
          <ButtonGroup>
            <Button size="sm" active={view === Views.SITES} onClick={() => setView(Views.SITES)}><Trans>Impianti</Trans></Button>
            <Button size="sm" active={view === Views.GENERATORS} onClick={() => setView(Views.GENERATORS)}><Trans>Generatori</Trans></Button>
          </ButtonGroup>
        </SwitchContainer>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');

                const dataCur = state.data ? state.data[date.format('YYYY-MM-DD')] : null;

                const dataGrid = [];
                let assetQuantity = null;
                let assetQuantitySimulated = null;
                if (dataCur) {
                  assetQuantity = dataCur.assetQuantity;
                  assetQuantitySimulated = dataCur.assetQuantitySimulated;

                  for (let hour = 0; hour < 24; hour += 1) {
                    const rowData = [];
                    rowData.push(assetQuantity.assetQuantity ? assetQuantity.assetQuantity[hour] : null); // Colonna Quantità asset* (in dispacciamento)
                    rowData.push(assetQuantitySimulated.assetQuantity ? assetQuantitySimulated.assetQuantity[hour] : null); // Colonna Quantità asset simulazione

                    if (view === Views.SITES) {
                      selectedPdo.pdoSites.forEach((site) => {
                        let finded = assetQuantity.sites[site.siteID];
                        rowData.push(finded && finded.pSchedQa && finded.pSchedQa[hour] !== undefined ? finded.pSchedQa[hour] : null); // Colonna Prod* J_esimo sito
                        finded = assetQuantitySimulated.sites[site.siteID];
                        rowData.push(finded && finded.pSchedQa && finded.pSchedQa[hour] !== undefined ? finded.pSchedQa[hour] : null); // Colonna Prod simul J_esimo sito
                      });
                    }

                    if (view === Views.GENERATORS) {
                      selectedPdo.pdoSites.forEach((site) => {
                        site.pdoUnits.filter(f => (nodeSupportedAssetIDList.indexOf(f.nodeSupportedAssetID) !== -1)).forEach((asset) => {
                          let finded = assetQuantity.sites[site.siteID] ? assetQuantity.sites[site.siteID].engines[asset.nodeAssetID] : null;
                          rowData.push(finded && finded[hour] !== undefined ? finded[hour] : null); // Colonna Prod* J_esimo generatore
                          finded = assetQuantitySimulated.sites[site.siteID] ? assetQuantitySimulated.sites[site.siteID].engines[asset.nodeAssetID] : null;
                          rowData.push(finded && finded[hour] !== undefined ? finded[hour] : null); // Colonna Prod simul J_esimo generatore
                        });
                      });
                    }

                    dataGrid.push(rowData);
                  }
                }

                const statusArray = state.dispatchingWorkflowStatus && state.dispatchingWorkflowStatus.filter(wf => moment(wf.date).isSame(date, 'days')) || [];
                const lastStatus = (statusArray && statusArray[statusArray.length-1]) || null;

                const disableButtonValidatedUT = (lastStatus && (lastStatus.workflowStatusId === validated.id || lastStatus.workflowStatusId === notValidatedOptz.id || lastStatus.workflowStatusId === notValidatedUT.id || lastStatus.workflowStatusId === notValidatedTRD.id || lastStatus.workflowStatusId === partiallyValidatedUT.id)) || !userIsTechOffice;
                const disableButtonValidatedTRD = (lastStatus && (lastStatus.workflowStatusId === validated.id || lastStatus.workflowStatusId === notValidatedOptz.id || lastStatus.workflowStatusId === notValidatedUT.id || lastStatus.workflowStatusId === notValidatedTRD.id || lastStatus.workflowStatusId === partiallyValidatedTRD.id)) || !userIsTrader;

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                      </HaderDateComponent>
                      {state.showDispatchingButtons && lastStatus && (
                        <ButtonValidationContainer>
                          {(lastStatus.workflowStatusId === toBeValidated.id || lastStatus.workflowStatusId === partiallyValidatedTRD.id) && (
                            <StyledButtonVal color="secondary" disabled={disableButtonValidatedUT} size="sm" style={{ marginRight: '65px' }} onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <span><Trans>Validazione (UT)</Trans></span>
                            </StyledButtonVal>
                          )}
                          {(lastStatus.workflowStatusId === partiallyValidatedUT.id) && (
                            <StyledButtonVal color="primary" disabled={disableButtonValidatedUT} size="sm" style={{ marginRight: '65px' }} onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <span><Trans>Validato (UT)</Trans></span>
                            </StyledButtonVal>
                          )}
                          {(lastStatus.workflowStatusId === notValidatedUT.id) && (
                            <StyledButtonVal color="danger" disabled={disableButtonValidatedUT} size="sm" style={{ marginRight: '65px', backgroundColor: '#dc3545' }} onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faBan} />
                              <span><Trans>Non validato (UT)</Trans></span>
                            </StyledButtonVal>
                          )}
                          {(lastStatus.workflowStatusId === toBeValidated.id || lastStatus.workflowStatusId === partiallyValidatedUT.id) && (
                            <StyledButtonVal color="secondary" disabled={disableButtonValidatedTRD} size="sm" onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <span><Trans>Validazione (TRD)</Trans></span>
                            </StyledButtonVal>
                          )}
                          {(lastStatus.workflowStatusId === partiallyValidatedTRD.id) && (
                            <StyledButtonVal color="primary" disabled={disableButtonValidatedTRD} size="sm" onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <span><Trans>Validato (TRD)</Trans></span>
                            </StyledButtonVal>
                          )}
                          {(lastStatus.workflowStatusId === notValidatedTRD.id) && (
                            <StyledButtonVal color="danger" disabled={disableButtonValidatedTRD} style={{ backgroundColor: '#dc3545' }} size="sm" onClick={() => toggleWorkflowDialog(date)}>
                              <FontAwesomeIcon icon={faBan} />
                              <span><Trans>Non validato (TRD)</Trans></span>
                            </StyledButtonVal>
                          )}
                        </ButtonValidationContainer>
                      )}
                      {state.showDispatchingButtons && (!assetQuantitySimulated || (assetQuantitySimulated && !assetQuantitySimulated.assetQuantity) || statusArray.findIndex(s => s.workflowStatusId === validated.id) !== -1) && (statusArray.length !== 0) && (
                        <StyledButtonVal color="primary" size="sm" disabled="true">
                          <FontAwesomeIcon icon={faCheckDouble} />
                          <span><Trans>In dispacciamento</Trans></span>
                        </StyledButtonVal>
                      )}
                      {state.showDispatchingButtons && (!assetQuantitySimulated || (assetQuantitySimulated && !assetQuantitySimulated.assetQuantity) || statusArray.findIndex(s => s.workflowStatusId === notValidatedOptz.id) !== -1) && (statusArray.length !== 0)&& (
                        <StyledButtonVal color="danger" size="sm" disabled="true">
                          <FontAwesomeIcon icon={faCheckDouble} />
                          <span><Trans>Non validato (OPTZ)</Trans></span>
                        </StyledButtonVal>
                      )}
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataGrid}
                      enableCheckBox={false}
                      date={date}
                      header={header}
                      readyOnly={(col, row, value) => true} // passo le colonne read only
                      normalizeValue={(x, y, value) => (value != null ? formatNumber(value, 3) : null)}
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        let className = '';

                        if (row !== 0 && ['A', 'B'].indexOf(col) !== -1) {
                          if (value > 0) {
                            className = className.concat(' positive-value');
                          }
                          if (value < 0) {
                            className = className.concat(' negative-value');
                          }
                        }

                        return className;
                      }}
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
      <Modal isOpen={state.openWorkflowDialog} toggle={toggleWorkflowDialog}>
        <ModalHeader toggle={toggleWorkflowDialog}><Trans>Valida programma dispacciamento</Trans></ModalHeader>
        <ModalBody>
          <div><Trans>Selezionare l'operazione desiderata</Trans></div>
          <br/>
          <div><Trans>L'operazione potrebbe richiedere alcuni minuti.</Trans></div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={state.disabledDialogButtons} onClick={() => updateWorkFlowStatus(userIsTechOffice ? partiallyValidatedUT : partiallyValidatedTRD)}>
            <FontAwesomeIcon icon={faCheck} />
            <span style={{ marginLeft: 5 }}><Trans>Valida</Trans></span>
          </Button>
          <Button color="danger" disabled={state.disabledDialogButtons} onClick={() => updateWorkFlowStatus(userIsTechOffice ? notValidatedUT : notValidatedTRD)}>
            <FontAwesomeIcon icon={faBan} />
            <span style={{ marginLeft: 5 }}><Trans>Non validare</Trans></span>
          </Button>
          <Button color="secondary" disabled={state.disabledDialogButtons} onClick={toggleWorkflowDialog}><Trans>Chiudi</Trans></Button>
        </ModalFooter>
      </Modal>
    </CardStyled>
  );
};

Dispatching.propTypes = {
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  const { userSystemRoles } = state.auth;
  return { language, path, type, id, selectedPdo, userSystemRoles };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dispatching);
