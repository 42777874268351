/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customApi } from 'api';
import Switch from 'app/common/Switch';
import styled from 'styled-components';
import { Card } from 'reactstrap';
import { Button } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';
import { formatNumber } from 'app/utils/commonUtils';
import { getErrorMessages } from 'app/utils/validation';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from 'ducks/actions/navigation';
import Picker from '../tradingCommon/Picker';
import Datasheet from '../tradingCommon/Datasheet';


const CardStyled = styled(Card)`
  padding: 1rem;
`;

const StyledButtonLeft = styled(Button)`
 && {
    margin-bottom: 5px;
  }
`;

const PickerContainer = styled.div`
  && {
    margin-top:5px;
    width: 150px;
    display: inline;
    margin-left: 20px;
  }
`;

const StyledButtonRight = styled(Button)`
 && {
    margin-bottom: 5px;
    margin-left: 20px;
  }
`;

const SwitchContainer = styled.div`
 && {
   float: right;
  }
`;

const MainContainerStyled = styled.div`
 && {
   overflow-x: auto;
   height: calc(100vh - 165px);
   margin-top: 10px;
  }
`;

const TableContainerDiv = styled.div`
 && {
   display: table;
   width: calc(100% + -1px);
  }
`;

const RowContainerDiv = styled.div`
 && {
   display: table-row;
   width: 100%;
  }
`;

const Spacer = styled.div`
&& {
  height: 10px;
 }
`;

const HaderComponent = styled.div`
  && {
    background-color: #CBEFE9;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0px 0px;
    height: 34px;
    width: calc(100% + 1px);
  }
`;

const HaderDateComponent = styled.div`
  && {
    display: inline;
    float: left;
    position: sticky;
    left: 10px;
    margin-top: 2px;
  }
`;

const HaderDayComponent = styled.div`
  && {
    display: inline;
    float: right;
    font-weight: bold;
    position: sticky;
    right: 10px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;


const QA = ({ startLoader, stopLoader, selectedPdo, language }) => {
  const [showQa, setShowQa] = useState(sessionStorage.getItem('showQa') ? sessionStorage.getItem('showQa') == 'true' : true);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'day'));
  const [data, setData] = useState([]);
  const [lastRequest, setLastRequest] = useState([]);

  const previousDay = () => {
    setStartDate(moment(startDate.toObject()).subtract(1, 'day'));
    setEndDate(moment(endDate.toObject()).subtract(1, 'day'));
  };

  const nextDay = () => {
    setStartDate(moment(startDate.toObject()).add(1, 'day'));
    setEndDate(moment(endDate.toObject()).add(1, 'day'));
  };

  const init = async () => {
    if (selectedPdo && startDate && endDate) {
      const start = moment(startDate).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const end = moment(endDate).set({ hour: 23, minute: 0 }).format('YYYY-MM-DD HH:mm');
      const params = {
        pdoID: selectedPdo.pdoID,
        startDate: start,
        endDate: end,
      };

      if (!_.isEqual(lastRequest, params)) {
        startLoader();
        setLastRequest(params);
        await customApi.post('/optz/getQA', params).then((res) => {
          if (res.data && res.data instanceof Array) {
            setData(res.data);
          } else {
            const { errorMessage } = getErrorMessages(res);
            toast.error(errorMessage, { autoClose: 5000 });
          }
        }).catch((err) => {
          const { errorMessage } = getErrorMessages(err);
          toast.error(errorMessage, { autoClose: 5000 });
        });
        stopLoader();
      }
    }
  };

  useEffect(() => {
    init();
  }, [selectedPdo, startDate, endDate]);

  const disabledEndDate = (endDate) => {
    if (!endDate || !startDate) return false;
    return endDate.diff(startDate, 'days') <= 0 || endDate.diff(startDate, 'days') >= 9;
  };

  const onChange = (field, value) => {
    if (field === 'startDate') {
      if (Math.abs(endDate.diff(value, 'days')) >= 9) {
        const newEndDate = moment(value).add(Math.abs(endDate.diff(startDate, 'days')), 'days');
        setEndDate(newEndDate);
      }
      setStartDate(value);
    } else if (field === 'endDate') setEndDate(value);
  };

  const handleChangeShowQa = () => {
    sessionStorage.setItem('showQa', !showQa);
    setShowQa(!showQa);
  };

  const dates = [moment(startDate)];
  let date = moment(startDate);
  while (date.isBefore(endDate, 'day')) {
    date = date.add(1, 'days');
    dates.push(moment(date));
  }

  /* Compongo header */
  const header = [];
  header.push(i18n._(t`Quantità asset (must run)`));

  if (showQa && selectedPdo && selectedPdo.pdoSites) {
    selectedPdo.pdoSites.forEach((site) => {
      header.push(<span>Qa*{' '}{site.siteName}{' '}<Bold>{site.registryCode}</Bold></span>);
    });
  }

  header.push(i18n._(t`Quantità asset* (in dispacc.)`));
  header.push(i18n._(t`Margine a salire`));
  header.push(i18n._(t`Margine a scendere`));
  header.push(i18n._(t`Riserva UVAM disponibile`));

  return (
    <CardStyled>
      <div>
        <StyledButtonLeft color="primary" onClick={() => previousDay()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </StyledButtonLeft>
        <PickerContainer>
          <Picker
            value={startDate}
            onChange={value => onChange('startDate', value)}
          />
        </PickerContainer>
        <PickerContainer>
          <Picker
            disabledDate={disabledEndDate}
            value={endDate}
            onChange={value => onChange('endDate', value)}
          />
        </PickerContainer>
        <StyledButtonRight color="primary" onClick={() => nextDay()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </StyledButtonRight>
        <SwitchContainer><Switch checked={showQa} label="Qa* impianti" onChange={() => handleChangeShowQa()} /></SwitchContainer>
      </div>
      <MainContainerStyled>
        <TableContainerDiv>
          {
              dates && dates.map((date, index) => {
                let sDate = date.locale(language).format('dddd, DD-MM-YYYY');
                sDate = sDate.charAt(0).toUpperCase() + sDate.slice(1);
                const isToday = moment().isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const isTomorrow = moment().add(1, 'day').isSame(moment(date, 'DD-MM-YYYY'), 'day');
                const dataCur = data[index];

                /* compongo grid */
                const dataGrid = [];
                for (let i = 0; i < 24; i += 1) {
                  const rowData = [];
                  if (dataCur) {
                    rowData.push(dataCur.assetQuantityMustRun ? dataCur.assetQuantityMustRun[i] : null);

                    if (showQa) {
                      selectedPdo.pdoSites.forEach((site) => {
                        const finded = dataCur.sites[site.siteID];
                        rowData.push(finded && finded.assetQuantity && finded.assetQuantity[i] !== undefined ? finded.assetQuantity[i] : null);
                      });
                    }

                    rowData.push(dataCur.assetQuantityDispatching ? dataCur.assetQuantityDispatching[i] : null);
                    rowData.push(dataCur.upMargin ? dataCur.upMargin[i] : null);
                    rowData.push(dataCur.downMargin ? dataCur.downMargin[i] : null);
                    rowData.push(dataCur.uvamReserve ? dataCur.uvamReserve[i] : null);
                  }
                  dataGrid.push(rowData);
                }

                return (
                  <RowContainerDiv>
                    { index !== 0 && index < dates.length && <Spacer /> }
                    <HaderComponent>
                      <HaderDateComponent>
                        {sDate}
                      </HaderDateComponent>
                      <HaderDayComponent>
                        {isToday ? <Trans>OGGI</Trans> : ''}
                        {isTomorrow ? <Trans>DOMANI</Trans> : ''}
                      </HaderDayComponent>
                    </HaderComponent>
                    <Datasheet
                      data={dataGrid}
                      enableCheckBox={false}
                      date={date}
                      minGridSize={3}
                      // cellWidth={65}
                      header={header}
                      // onChecked={console.log}
                      normalizeValue={(x, y, value) => (value != null ? formatNumber(value, 3) : null)}
                      saveMatrix={(newData) => {
                        setData(data.splice(index, 1, newData)); // rimuovo il vecchio array e lo sostituisco con il nuovo
                      }}
                      readyOnly={(col, row, value) => true} // passo le colonne read only
                      classMap={(col, row, value) => { // imposto le logiche per l'applicazione di classi CSS
                        const className = ' read-only';
                        return className;
                      }}
                    />
                  </RowContainerDiv>
                );
              })
            }
        </TableContainerDiv>
      </MainContainerStyled>
    </CardStyled>
  );
};

QA.propTypes = {
  language: PropTypes.string.isRequired,
  selectedPdo: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.preferences;
  const { path, type, id, selectedPdo } = state.navigation;
  return { language, path, type, id, selectedPdo };
};

const mapDispatchToProps = dispatch => ({
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QA);
