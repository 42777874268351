import React from 'react';
import { connect } from 'react-redux';

export { Roles } from 'app/utils/auth';


const AuthControl = (props) => {
  const { userSystemRoles, systemRole, domainRole, userCurrentRoles, children } = props;
  let ret;
  if (systemRole) {
    ret = userSystemRoles.includes(systemRole);
  } else if (domainRole) {
    ret = userCurrentRoles.includes(domainRole);
  }

  return ret ? children : null;
};

const mapStateToProps = (state) => {
  const { userSystemRoles, userCurrentRoles } = state.auth;
  const { type, id } = state.navigation;
  return { type, id, userSystemRoles, userCurrentRoles };
};

export default connect(mapStateToProps)(AuthControl);
