import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const getErrorMsg = (error) => {
  const errorCode = error.status;
  const errorMessage = error.data && error.data.message ? error.data.message : error.statusText;
  const params = error.data ? error.data : {};

  const ERROR_CODES = {
    550: i18n._(t`Non è stato possibile ricavare la perdita di rete. Campo tensine non presente nell'anagrafica del sito ${params.siteName}`),
    // 551: i18n._(t`Non è stato possibile ricavare la perdita di rete. Campo tensine non presente nell'anagrafica del sito ${params.siteName}`),
    // 552: i18n._(t`Non è stato possibile ricavare la perdita di rete. Campo tensine non presente nell'anagrafica del sito ${params.siteName}`),
    // 553: i18n._(t`Non è stato possibile ricavare la perdita di rete. Campo tensine non presente nell'anagrafica del sito ${params.siteName}`),
  };
  return ERROR_CODES[errorCode] ? ERROR_CODES[errorCode] : errorMessage;
};

export default getErrorMsg;
